import * as React from 'react';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { ResetPasswordContainer } from '../../../containers/access/reset-password/reset';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';

export type Props = RouteComponentProps;

export const ResetPasswordPage: React.FC<Props> = (p) => {
  const q = qs.parse(p.location.search);
  const token = (q.token as string) ?? '';
  const email = (q.email as string) ?? '';
  return (
    <MainPage>
      {() => <ResetPasswordContainer email={email} token={token} />}
    </MainPage>
  );
};
ResetPasswordPage.displayName = 'ResetPasswordPage';
