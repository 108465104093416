import './styles.scss';

import {
  Btn,
  ButtonTypes,
  HtmlContent,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { PublishingEntityPill } from 'client/shared/components/publishing-entity-pill';

import ideaLogo from 'client/assets/community-avatar.svg';
import { Card } from 'client/shared/components/base/card';
import {
  IdeaActionType,
  IdeaMoreMenu,
  mapIdeaTypeToMenuItem,
  reportIdea,
} from 'client/shared/components/idea/idea-more-menu';
import { timeAgo } from 'client/shared/core/resident-inputs';
import React from 'react';
import { COPY } from './copy';
import { getConfig } from 'client/shared/core/config';
import {
  AuthnVoteSectionProps,
  AuthnVoteSection,
} from 'client/respondent/shared/account/components/authn-vote-section';

export interface IdeaDetailsCardProps {
  readonly id: string;
  readonly author: string;
  readonly publisherName: string;
  readonly createdAt: Date;
  readonly title: string;
  readonly description?: string;
  readonly goBack: () => void;
  readonly goToPublishingEntity: () => void;
  readonly onIdeaShared: (id: string) => void;
  readonly voteButton: AuthnVoteSectionProps;
}

const baseClass = 'pn-idea-details-card';
export const IdeaDetailsCard: React.FC<IdeaDetailsCardProps> = (props) => {
  const config = getConfig();
  const votingEnabled = config.envFeatures.enableIdeaVoting;
  const actionTypes = [IdeaActionType.SHARE, IdeaActionType.REPORT];
  const menuItems = actionTypes.map((t) =>
    mapIdeaTypeToMenuItem({
      actionType: t,
      idea: { id: props.id, title: props.title },
      action: () => {
        t === IdeaActionType.SHARE
          ? props.onIdeaShared(props.id)
          : reportIdea({ id: props.id, title: props.title });
      },
    })
  );
  const author = (
    <div className={`${baseClass}-author font-size-xs text-gray-60`}>
      {props.author}
    </div>
  );
  const logo = (
    <img
      alt={COPY.ideaAvatarImageAlt}
      className={`${baseClass}-logo mx-2`}
      height="30px"
      src={ideaLogo}
      width="30px"
    />
  );
  const postedTime = timeAgo(props.createdAt)
    ? `Posted ${timeAgo(props.createdAt)}`
    : ' ';
  const description = (
    <div className={`${baseClass}-description`}>
      <HtmlContent html={props.description} imageAlt={COPY.descriptionImageAlt} />
    </div>
  );

  return (
    <Card className={`${baseClass}`}>
      <Card.Header>
        <div
          className={`${baseClass}-header d-flex align-items-center justify-content-between`}
        >
          {author} {logo}
          <div className="d-flex align-items-center">
            <IdeaMoreMenu options={menuItems} />
            <Btn
              action={props.goBack}
              ariaLabel={COPY.close}
              className={`${baseClass}-close text-gray-50`}
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon icon={MaterialIconName.CLOSE} />
            </Btn>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className={`${baseClass}-body text-left pb-2`}>
          <PublishingEntityPill
            goToPublishingEntity={props.goToPublishingEntity}
            publisherName={props.publisherName}
          />
          <Card.Title className={`${baseClass}-title mb-3`}>
            {props.title}
          </Card.Title>
          <Card.Subtitle className={`${baseClass}-subtitle text-gray-50 mb-2`}>
            {postedTime}
          </Card.Subtitle>
          {description}
        </div>
        {votingEnabled && (
          <AuthnVoteSection
            className={`${baseClass}-vote-button`}
            props={props.voteButton}
          />
        )}
      </Card.Body>
    </Card>
  );
};

IdeaDetailsCard.displayName = IdeaDetailsCard.name;
