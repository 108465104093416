import * as React from 'react';
import { NotLoggedInActionStatus } from 'client/respondent/core/types';
import { LoginContainer } from '../../../containers/access/login';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { useRedirect } from 'client/shared/hooks';
import { useCurrentRespondent } from 'client/respondent/hooks';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { getRespondentUser } from '../helpers';

export const LoginPage: React.FC = () => {
  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();
  const redirect = useRedirect();

  // Redirects to account page if res is logged in
  const currentRespondent = useCurrentRespondent();
  const respondent = getRespondentUser(currentRespondent)?.respondent;
  if (respondent && !respondent.guest) {
    redirect(ClientUrlUtils.respondent.account.path(), { push: true });
  }

  return (
    <MainPage
      hideLeftSidebarAuthButtons
      hideLeftSidebarWhyCreateAccountSection
      hideRightSidebar
      title="Polco - Login"
    >
      {() => (
        <LoginContainer
          converted={({ conversionType }) => {
            dispatch(RespondentActions.registrationSuccessful(conversionType));
            const redirectLink =
              state.preLoginAction &&
              state.preLoginAction.status !== NotLoggedInActionStatus.ACTION_COMPLETE
                ? state.preLoginAction.redirectLink
                : '/res';
            redirect(redirectLink, {
              push: true,
            });
          }}
          includeAdminRedirection
        />
      )}
    </MainPage>
  );
};
LoginPage.displayName = 'LoginPage';
