import { ClientUrlUtils } from 'client/shared/core/helpers';
import * as React from 'react';

export const copy = {
  title: "Hmmm. We don't recognize this short url.",
  body: 'Can you verify this is what you wanted to type: ',
};

export const BadShortUrlUI: React.FC<{ readonly shortId: string }> = (p) => {
  return (
    <div className="container mt-5 text-center">
      <div className="text-primary font-size-lg font-weight-bold">{copy.title}</div>
      <div className="text-muted mb-5">{copy.body}</div>
      <h2>{ClientUrlUtils.common.shortUrl(p.shortId)}</h2>
    </div>
  );
};
