import * as React from 'react';
import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api';
import { getIntegrationConfigKey } from 'client/shared/integrations';
import {
  CIRCLE_RADIUS_PER_ZOOM,
  DEFAULT_MAP_ZOOM,
  MAP_CIRCLE_OPTIONS,
} from 'client/shared/core/geo';

interface Props {
  readonly className?: string;
  readonly center: google.maps.LatLngLiteral;
}

const MAP_KEY = getIntegrationConfigKey('googleMapsApiKey');

const defaultValues = {
  zoom: DEFAULT_MAP_ZOOM,
  circleRadius: CIRCLE_RADIUS_PER_ZOOM[DEFAULT_MAP_ZOOM],
  circleOptions: MAP_CIRCLE_OPTIONS,
  containerStyle: {
    width: '100%',
    height: '300px',
  },
};

const disabledOptions = {
  draggable: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  draggableCursor: 'default',
  draggingCursor: 'default',
  clickableIcons: false,
};

export const baseClass = 'pn-map-preview';

export const MapPreview: React.FC<Props> = React.memo((p: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_KEY as string,
  });

  return isLoaded ? (
    <GoogleMap
      center={p.center}
      mapContainerClassName={`${baseClass}-container ${p.className ?? ''}`}
      mapContainerStyle={defaultValues.containerStyle}
      options={disabledOptions}
      zoom={defaultValues.zoom}
    >
      <Circle
        center={p.center}
        options={defaultValues.circleOptions}
        radius={defaultValues.circleRadius}
      />
    </GoogleMap>
  ) : null;
});
