import * as React from 'react';
import * as SubscriptionInfos from 'client/shared/graphql-subscriptions/subscription-infos';
import {
  QuestionForWaitingPage,
  QuestionStateForWaitingPage,
} from 'client/respondent/core/types';

import { PolcoLiveWaiting } from '../../components/polco-live-waiting';
import { Redirect, RouteComponentProps } from 'react-router';
import { CurrentUser } from 'client/respondent/hooks/use-respondent';
import { useQueryInfo } from 'client/shared/containers/query';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { LoaderBars } from 'client/shared/components/loader-bars';
import {
  SelectLanguageTextFunction,
  useCurrentTime,
  useLanguageByQueryParam,
  useRedirect,
} from 'client/shared/hooks';
import { ClientQuestionId, QuestionStatus } from 'client/shared/core/question';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { useSubscriptionInfo } from 'client/shared/containers/subscription';
import moment from 'moment';
import { ApiDate, ExtractGql, QuestionSetType } from 'core';
import { RespondentPolcoLiveBase } from 'client/shared/graphql-client/graphql-operations.g';
export interface Props
  extends RouteComponentProps<{
    readonly setSlug: string;
    readonly pubSlug: string;
  }> {
  readonly respondentUser: CurrentUser | null;
}

function questionSetSlugFromParamsProps(props: Props): string {
  return props.match.params.setSlug;
}

function pubSlugFromParamsProps(props: Props): string | undefined {
  return props.match.params.pubSlug;
}

export const PolcoLiveWaitingPage: React.FC<Props> = (p: Props) => {
  const setSlug = questionSetSlugFromParamsProps(p);
  const pubSlug = pubSlugFromParamsProps(p);
  return (
    <PolcoLiveWaitingInner
      pubSlug={pubSlug ?? 'by-id'}
      respondentUser={p.respondentUser}
      setSlug={setSlug}
    />
  );
};

const PolcoLiveWaitingInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
  readonly pubSlug: string;
  readonly setSlug: string;
}> = (p) => {
  const redirect = useRedirect();
  const respId = p.respondentUser?.user?.respondent?.id ?? null;
  const polcoLiveWaitingQuery = useQueryInfo(QueryInfos.respondentPolcoLiveBase, {
    variables: {
      questionSetSlug: p.setSlug,
      publishingEntitySlug: p.pubSlug,
      respondentId: respId,
    },
  });

  const now = useCurrentTime({ frequencyMillis: 1000 });
  const contentSetId = polcoLiveWaitingQuery.data?.openContentSetBySlug?.id;

  const queryQuestionLength =
    polcoLiveWaitingQuery.data?.openContentSetBySlug?.__typename === 'PolcoLive'
      ? polcoLiveWaitingQuery.data?.openContentSetBySlug.setForRespondentVote
          .questions.length
      : 0;
  const [previousQuestionLength, setPreviousQuestionLength] =
    React.useState(queryQuestionLength);
  const subSetInfo = useSubscriptionInfo(SubscriptionInfos.liveContentSet, {
    skip: !contentSetId,
    variables: contentSetId
      ? {
          contentSetId,
          respondentId: respId,
        }
      : undefined,

    // this will be triggered each time the useSubscription hook receives data.
    // compare previousQuestion length and updated question length and if the lengths are different, redirect to recently updated question.
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        polcoLiveWaitingQuery.data?.openContentSetBySlug?.__typename === 'PolcoLive'
      ) {
        const subQuestions =
          subscriptionData.data?.liveContentSet.setForRespondentVote.questions;

        if (
          subQuestions &&
          subQuestions.length !== previousQuestionLength &&
          subQuestions.length !== 0
        ) {
          setPreviousQuestionLength(subQuestions.length);
          redirect(
            ClientUrlUtils.respondent.question.path({
              questionId: subQuestions[subQuestions.length - 1].id,
              setIdOrSlug: p.setSlug,
              pubSlug: p.pubSlug,
              fromOverview: true,
              setType: QuestionSetType.POLCO_LIVE,
            }),
            {
              push: true,
            }
          );
        }
      }
    },
  });
  const { selectLanguageText } = useLanguageByQueryParam();
  const endDate = ApiDate.fromApi(
    subSetInfo.data?.liveContentSet.settings.eventEndDate ?? null
  );
  // An easy way to think of this is by replacing .diff( with a minus operator.(now - endDate)
  const eventEnded = endDate ? moment().diff(endDate) >= 0 : false;

  if (eventEnded) {
    return (
      <Redirect
        push
        to={ClientUrlUtils.respondent.polcoLiveThankYou.path({
          setIdOrSlug: p.setSlug,
          pubSlug: p.pubSlug,
        })}
      />
    );
  }

  if (polcoLiveWaitingQuery.loading) {
    return <LoaderBars />;
  }
  const publishingEntity = polcoLiveWaitingQuery.data?.contextPublishingEntity;
  if (!publishingEntity) {
    return <LoaderBars />;
  }

  const setData =
    polcoLiveWaitingQuery.data?.openContentSetBySlug?.__typename === 'PolcoLive'
      ? polcoLiveWaitingQuery.data.openContentSetBySlug.setForRespondentVote
      : null;
  const questions = setData?.questions ?? [];
  const hasEmbeddedVideo =
    polcoLiveWaitingQuery.data?.openContentSetBySlug?.__typename === 'PolcoLive' &&
    polcoLiveWaitingQuery.data.openContentSetBySlug.settings.liveVideoLink
      ? true
      : false;

  const goToQuestion = (questionId: ClientQuestionId) => {
    redirect(
      ClientUrlUtils.respondent.question.path({
        questionId,
        setIdOrSlug: p.setSlug,
        pubSlug: p.pubSlug,
        fromOverview: true,
        setType: QuestionSetType.POLCO_LIVE,
      }),
      {
        push: true,
      }
    );
  };

  return (
    <PolcoLiveWaiting
      events={{
        goToQuestion,
      }}
      hasEmbeddedVideo={hasEmbeddedVideo}
      questions={questions.map((q) =>
        transformGqlQuestionsToSetDataQuestion(q, now, selectLanguageText)
      )}
    />
  );
};

export function transformGqlQuestionsToSetDataQuestion(
  q: ExtractGql<
    NonNullable<RespondentPolcoLiveBase['openContentSetBySlug']>,
    'PolcoLive'
  >['setForRespondentVote']['questions'][0],
  now: Date,
  selectLanguageText: SelectLanguageTextFunction
): QuestionForWaitingPage {
  const timeRemainingSeconds = q.schedule.closeDate?.raw
    ? moment(ApiDate.fromApi(q.schedule.closeDate)).diff(moment(now))
    : null;

  // Note: case where an admin closes a question while a resp is on the waiting page
  // Since a closed question state is updated by a worker,
  // we should calculate whether a question is closed by comparing current date and closed date.
  // We get a closed date immediately when an admin closes a question.
  const isClosedBytimeRemainingSeconds =
    timeRemainingSeconds !== null && timeRemainingSeconds < 0;

  const state =
    q.schedule.status === QuestionStatus.CLOSED
      ? QuestionStateForWaitingPage.CLOSED
      : q.previousVote?.id
      ? QuestionStateForWaitingPage.OPEN
      : QuestionStateForWaitingPage.ANSWER_NOW;

  return {
    id: q.id,
    title: selectLanguageText(q.title),
    previousVote: q.previousVote?.id ?? null,
    state: isClosedBytimeRemainingSeconds
      ? QuestionStateForWaitingPage.CLOSED
      : state,
  };
}
