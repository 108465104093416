import * as React from 'react';
import { Btn, ButtonTypes } from '../base';
import { wrap } from 'core';

export interface Option {
  readonly id: string;
  readonly label: string;
}
export interface SelectorProps {
  readonly onSelect: (option: readonly Option[]) => void;
  readonly options: ReadonlyArray<Option>;
  readonly selectSingleOptionByDefault?: boolean;
  readonly multiSelect?: boolean;
}

const baseClass = 'pn-selector';
const selectorOptionClass = 'selectorOption';
const selectedOptionClass = 'selectedOption';

export const Selector: React.FC<SelectorProps> = ({
  onSelect,
  options,
  selectSingleOptionByDefault = false,
  multiSelect = false,
}: SelectorProps) => {
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);

  React.useEffect(() => {
    if (selectSingleOptionByDefault && options.length === 1) {
      const [defaultOption] = options;
      setSelectedOptions([defaultOption]);
    }
  }, [selectSingleOptionByDefault, options]);

  React.useEffect(() => {
    onSelect(selectedOptions);
  }, [onSelect, selectedOptions]);

  const handleOptionClick = (option: Option) => {
    const selection = wrap(() => {
      if (selectedOptions.some((opt) => opt.id === option.id)) {
        return selectedOptions.filter((opt) => opt.id !== option.id);
      } else {
        if (multiSelect) {
          return [...selectedOptions, option];
        } else {
          return [option];
        }
      }
    });
    setSelectedOptions(selection);
  };

  return (
    <div className={baseClass}>
      {options.map((option) => {
        const selectedOptionClassState = selectedOptions.find(
          (item: Option) => item.id === option.id
        )
          ? selectedOptionClass
          : '';
        return (
          <Btn
            action={() => handleOptionClick(option)}
            ariaLabel={option.label}
            className={`mx-1 my-1 ${selectorOptionClass} ${selectedOptionClassState}`}
            iconOnly
            key={option.id}
            size="small"
            type={ButtonTypes.OPTION}
          >
            {option.label}
          </Btn>
        );
      })}
    </div>
  );
};

Selector.displayName = Selector.name;
