import * as React from 'react';
import './styles.scss';
import {
  AppLink,
  Btn,
  ButtonTypes,
  Switch,
  Well,
  WellType,
} from 'client/shared/components/base';
import { useForm, RegisterOptions, Controller } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { EDIT_PROFILE_COPY } from './copy';
import { ClientUrlUtils, handlePressEnter } from 'client/shared/core/helpers';
import { RBRef } from 'client/shared/core/types';
import { ZIP_VALIDATION_REGEX } from 'client/shared/core/constants';
import { TextInputProps } from 'client/shared/components/base/text-input';
import { SaveButton } from 'client/shared/components/save-button';
import {
  EMAIL_VALIDATION_REGEX,
  GqlError,
  PolcoGqlError,
  PolcoGqlErrors,
  Result,
} from 'core';
import { useId } from 'react-use-id-hook';
import editLocation from 'client/assets/edit-location.png';
import { MapPreview } from 'client/respondent/location/components/map-preview';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { Modal } from 'client/shared/components/modal';
import { LocationContainer } from 'client/respondent/location/containers/set-your-location';
import { useSnackbarMessage } from 'client/shared/hooks';
import { Helmet } from 'react-helmet';

export interface RespondentAccountProps {
  readonly initialProfile: ProfileFields;
  readonly events: {
    readonly submit: (
      field: any
    ) => Promise<
      Result.Type<
        unknown,
        GqlError<PolcoGqlError<PolcoGqlErrors.ClientUpdateProfileFailureReason>>
      >
    >;
  };
}

// const MAX_TOPIC_SELECTION = 4;

// dropdown options and labels
export enum GenderCategories {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  OTHER = 'OTHER',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}
// const genderLabels: Record<GenderCategories, string> = {
//   MALE: 'Male',
//   FEMALE: 'Female',
//   OTHER: 'Other',
//   PREFER_NOT_TO_SAY: 'Prefer not to say',
// };
// const commentVisibilityLabel: Record<CommentVisibility, string> = {
//   NOTHING: 'Nothing (anonymous)',
//   FIRST_LAST: 'First name and last initial',
//   FULL_NAME_PICTURE: 'Full name and picture',
// };

export enum CommentVisibility {
  NOTHING = 'NOTHING',
  FIRST_LAST = 'FIRST_LAST',
  FULL_NAME_PICTURE = 'FULL_NAME_PICTURE',
}

export enum InterestTopic {
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  SCHOOLS = 'SCHOOLS',
  EVENTS = 'EVENTS',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  ELECTIONS = 'ELECTIONS',
  MINORITY_REPRESENTATION = 'MINORITY_REPRESENTATION',
}
// const interestTopicLabel: Record<InterestTopic, string> = {
//   ENVIRONMENTAL: 'Environmental',
//   SCHOOLS: 'Schools',
//   EVENTS: 'Events',
//   INFRASTRUCTURE: 'Infrastructure',
//   ELECTIONS: 'Elections',
//   MINORITY_REPRESENTATION: 'Minority representation',
// };

export const baseClass = 'pn-account-edit';

type ValidationSet = Record<keyof FormFields, RegisterOptions>;
const validations: ValidationSet = {
  firstName: {},
  lastName: {},
  zipCode: {
    pattern: {
      value: ZIP_VALIDATION_REGEX,
      message: EDIT_PROFILE_COPY.errorMessages.zip,
    },
  },
  email: {
    required: {
      value: true,
      message: EDIT_PROFILE_COPY.errorMessages.required,
    },
    pattern: {
      value: EMAIL_VALIDATION_REGEX,
      message: EDIT_PROFILE_COPY.errorMessages.email,
    },
  },
  weeklyEmailEnabled: {},
  outreachEmailEnabled: {},
  outcomeEmailEnabled: {},
};

interface LocationProps {
  readonly inputtedLat: number | null;
  readonly inputtedLon: number | null;
}

export interface ProfileFields extends LocationProps {
  readonly image: string | null;
  readonly gender: GenderCategories;
  readonly commentVisibility: CommentVisibility;
  readonly interestTopic: readonly InterestTopic[];
  readonly firstName: string;
  readonly lastName: string;
  readonly zipCode: string;
  readonly email: string;
  readonly weeklyEmailEnabled: boolean;
  readonly outreachEmailEnabled: boolean;
  readonly outcomeEmailEnabled: boolean;
}

interface FormFields
  extends Omit<
    ProfileFields,
    | 'image'
    | 'gender'
    | 'commentVisibility'
    | 'interestTopic'
    | 'inputtedLat'
    | 'inputtedLon'
    | 'requestLocationLastShown'
  > {}

export const RespondentAccount: React.FC<RespondentAccountProps> = (props) => {
  const { initialProfile, events } = props;
  const [submitError, setSubmitError] = React.useState('');
  const [saveSucceeded, setSaveSucceeded] = React.useState(true);
  const locationFlowActivated = useFlagEnabled(KnownFlag.RESIDENTS_STORE_LOCATION);
  const zipCodeId = useId();

  // const [genderSelected, setGenderSelected] = React.useState(
  //   initialProfile.gender
  // );
  // const [commentVisibility, setCommentVisibility] = React.useState(
  //   CommentVisibility.NOTHING
  // );

  //setValue, control are from useForm
  const { register, handleSubmit, errors, formState, control } = useForm<FormFields>(
    {
      mode: 'onChange',
      defaultValues: {
        firstName: initialProfile.firstName,
        lastName: initialProfile.lastName,
        zipCode: initialProfile.zipCode,
        email: initialProfile.email,
        weeklyEmailEnabled: initialProfile.weeklyEmailEnabled,
        outreachEmailEnabled: initialProfile.outreachEmailEnabled,
        outcomeEmailEnabled: initialProfile.outcomeEmailEnabled,
      },
    }
  );
  const submitted = formState.isSubmitted;

  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const submit = async (fields: FormFields) => {
    const result = await events.submit(fields);
    if (Result.isSuccess(result)) {
      setSaveSucceeded(true);
    } else {
      setSubmitError(
        result.value.graphQLErrors.length > 0
          ? gqlRespondentUpdateErrorToMessage(
              result.value.graphQLErrors[0].extra.errors
            )
          : 'Unknown error'
      );
      setSaveSucceeded(false);
    }
  };

  const text = (
    label: string,
    k: keyof FormFields,
    id: string,
    feedbackId: string,
    opts?: {
      readonly inputType?: TextInputProps['inputType'];
      readonly autoComplete?: string;
      readonly placeholder?: string;
      readonly maxLength?: number;
      readonly note?: string;
      readonly disabled?: boolean;
      readonly hideLabel?: boolean;
      readonly required?: boolean;
    }
  ) => {
    const isInvalid = !!errors[k]?.message && submitted;
    return (
      <Form.Group as={Col}>
        <Form.Label
          className={`font-size-sm ${!!opts?.hideLabel ? 'd-none' : ''}`}
          htmlFor={id}
        >
          <div className="d-flex">
            <div className="text-jungle">{label}</div>
            {opts?.note && (
              <div className="pl-2 font-italic font-size-xs text-gray-40 align-self-center font-weight-normal">
                {opts.note}
              </div>
            )}
          </div>
        </Form.Label>
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={label ?? undefined}
          autoComplete={opts?.autoComplete}
          className={`${
            label ? '' : `${baseClass}-no-margin`
          } rounded accessible-input ${
            !!errors[k]?.message && submitted ? 'has-error' : ''
          }`}
          disabled={opts?.disabled}
          id={id}
          isInvalid={isInvalid}
          maxLength={opts?.maxLength}
          name={k}
          placeholder={opts?.placeholder}
          ref={createRef(validations[k] ?? {})}
          required={opts?.required}
          type={opts?.inputType}
        />
        <Form.Control.Feedback
          className={submitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[k]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const toggle = (
    label: string,
    k: keyof FormFields,
    opts?: {
      readonly placeholder?: string;
      readonly disabled?: boolean;
    }
  ) => {
    return (
      <Controller
        control={control}
        name={k}
        render={({ onChange, value }) => {
          return (
            <Form.Group as={Col} className="d-flex justify-content-between">
              <Switch
                applyAccessibleStyles
                ariaLabel={label}
                disabled={opts?.disabled}
                id={k}
                isInvalid={!!errors[k]}
                label={label || ''}
                name={k}
                onChange={() => onChange(!value)}
                value={value}
              />
              {errors[k] && (
                <Form.Control.Feedback
                  className={submitted ? 'd-block' : ''}
                  type="invalid"
                >
                  {errors[k]?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          );
        }}
        rules={validations[k]}
      />
    );
  };

  return (
    <>
      {/* <div className="pl-3">
          <UploadPicture image={initialProfile.image} />
        </div> */}
      <Helmet>
        <title>Polco - Account</title>
      </Helmet>
      <Form
        className={`${baseClass} p-4 mr-auto ml-auto`}
        onKeyUp={handlePressEnter(handleSubmit(submit))}
        onSubmit={handleSubmit(submit)}
      >
        <div className="font-weight-bold font-size-lg mb-3">
          {EDIT_PROFILE_COPY.account}
        </div>

        <div className="pl-3">
          <Form.Row>
            {text(
              EDIT_PROFILE_COPY.fields.firstName.label,
              'firstName',
              useId(),
              useId(),
              {
                autoComplete: 'given-name',
                placeholder: EDIT_PROFILE_COPY.fields.firstName.placeholder,
                note: EDIT_PROFILE_COPY.fields.firstName.note,
              }
            )}
          </Form.Row>
          <Form.Row>
            {text(
              EDIT_PROFILE_COPY.fields.lastName.label,
              'lastName',
              useId(),
              useId(),
              {
                autoComplete: 'given-name',
                placeholder: EDIT_PROFILE_COPY.fields.lastName.placeholder,
                hideLabel: true,
              }
            )}
          </Form.Row>
          {!locationFlowActivated && (
            <Form.Row>
              {text(
                EDIT_PROFILE_COPY.fields.zipCode.label,
                'zipCode',
                zipCodeId,
                zipCodeId,
                {
                  autoComplete: 'postal-code',
                  placeholder: EDIT_PROFILE_COPY.fields.zipCode.placeholder,
                }
              )}
            </Form.Row>
          )}
          <Form.Row>
            {text(EDIT_PROFILE_COPY.fields.email.label, 'email', useId(), useId(), {
              autoComplete: 'email',
              placeholder: EDIT_PROFILE_COPY.fields.email.placeholder,
              required: true,
            })}
          </Form.Row>

          {locationFlowActivated && <LocationSection {...props.initialProfile} />}

          <Form.Row>
            <h2 className="font-weight-bold font-size-lg mb-3">
              {EDIT_PROFILE_COPY.notifications}
            </h2>
          </Form.Row>
          <Form.Row>
            {toggle(
              EDIT_PROFILE_COPY.fields.outreachEmailEnabled.label,
              'outreachEmailEnabled',
              {
                placeholder:
                  EDIT_PROFILE_COPY.fields.outreachEmailEnabled.placeholder,
              }
            )}
          </Form.Row>
          <Form.Row>
            {toggle(
              EDIT_PROFILE_COPY.fields.outcomeEmailEnabled.label,
              'outcomeEmailEnabled',
              {
                placeholder:
                  EDIT_PROFILE_COPY.fields.outcomeEmailEnabled.placeholder,
              }
            )}
          </Form.Row>
          <Form.Row>
            {toggle(
              EDIT_PROFILE_COPY.fields.weeklyEmailEnabled.label,
              'weeklyEmailEnabled',
              {
                placeholder: EDIT_PROFILE_COPY.fields.weeklyEmailEnabled.placeholder,
              }
            )}
          </Form.Row>
          {submitError && (
            <Well className="invalid" type={WellType.ERROR}>
              {submitError} {EDIT_PROFILE_COPY.errorMessages.serverError}
            </Well>
          )}
          <SaveButton
            action={async () => {
              await handleSubmit(submit)();
              return saveSucceeded ? Result.success(null) : Result.failure(null);
            }}
            className="rounded w-100 mt-2"
          />
          <div className="mt-2">
            <AppLink
              className="text-jungle"
              to={ClientUrlUtils.respondent.resetPasswordRequest.path()}
            >
              {EDIT_PROFILE_COPY.changePassword}
            </AppLink>
          </div>

          {/* TODO: implement additional fields*/}
          {/* <Controller
            as={
              <Dropdown
                prompt="Select gender"
                options={Object.keys(genderLabels) as GenderCategories[]}
                value={genderSelected}
                keySelect={k => k}
                labelSelect={l => genderLabels[l]}
                onChange={v => setGenderSelected(v)}
              />
            }
            name="gender"
            control={control}
          />
          <Controller
            as={
              <Dropdown
                prompt="On comments, show"
                options={
                  Object.keys(commentVisibilityLabel) as CommentVisibility[]
                }
                value={commentVisibility}
                keySelect={k => k}
                labelSelect={l => commentVisibilityLabel[l]}
                onChange={v => setCommentVisibility(v)}
              />
            }
            name="commentVisibility"
            control={control}
          /> */}
          {/* <Form.Label className="my-3">
            {EDIT_PROFILE_COPY.interestArea}
          </Form.Label> */}
          {/* <Form.Text className="font-weight-">
            Choose up to {MAX_TOPIC_SELECTION} topics you're interested in:
          </Form.Text>

          <Controller
            as={
              <ChooseTopic
                maxSelection={MAX_TOPIC_SELECTION}
                topics={Object.keys(interestTopicLabel) as InterestTopic[]}
                labelSelect={l => interestTopicLabel[l]}
                setValue={setValue}
                initialTopics={initialProfile.interestTopic}
                formName={'interestTopic'}
              />
            }
            name="interestTopic"
            control={control}
          /> */}
        </div>
      </Form>
    </>
  );
};

function gqlRespondentUpdateErrorToMessage(
  gqlError: PolcoGqlErrors.ClientUpdateProfileFailureReason
): string {
  switch (gqlError) {
    case PolcoGqlErrors.ClientUpdateProfileFailureReason.EMAIL_TAKEN:
      return 'This email is already in use. Please login with that account.';
    case PolcoGqlErrors.ClientUpdateProfileFailureReason.NO_RESPONDENT:
      return 'Respondent not found.';
    case PolcoGqlErrors.ClientUpdateProfileFailureReason.NO_USER:
      return 'User not found.';
    case PolcoGqlErrors.ClientUpdateProfileFailureReason.DEFAULT:
      return 'Something went wrong.';
  }
}

const LocationSection: React.FC<LocationProps> = ({ inputtedLat, inputtedLon }) => {
  const [SnackbarMessage, setSnackbarMessage] = useSnackbarMessage();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <SnackbarMessage />
      <div className="d-flex mb-3 flex-column">
        <p className="font-size-sm font-weight-bold text-jungle mb-2">
          {EDIT_PROFILE_COPY.fields.location.label}
        </p>
        <p className="text-gray-40 font-size-xs mb-2">
          {EDIT_PROFILE_COPY.fields.location.description}
        </p>
        <div
          className={`${baseClass}-location-container d-flex flex-column rounded border-gray-20 border bg-white w-100`}
        >
          {inputtedLat && inputtedLon ? (
            <MapPreview center={{ lat: inputtedLat, lng: inputtedLon }} />
          ) : (
            <div
              className={`${baseClass}-no-location d-flex flex-column align-items-center justify-content-center rounded bg-grayscale-2`}
            >
              <img
                alt="Edit location logo"
                className={`${baseClass}-location-logo mb-3`}
                src={editLocation}
              />
              <span className="text-gray-40 font-size-sm font-weight-bold">
                {EDIT_PROFILE_COPY.fields.location.notSet}
              </span>
            </div>
          )}
          <div className="px-4 py-3 w-100">
            <Btn
              action={() => setIsOpen(true)}
              className="w-100"
              type={ButtonTypes.SECONDARY}
            >
              {EDIT_PROFILE_COPY.fields.location.action}
            </Btn>
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          bodyClassName="p-0"
          className={`${baseClass}-location-modal`}
          header={{
            as: 'h5',
            title: 'Set Your Location',
            bsClassName: 'border-gray-20 border-bottom',
          }}
          isCloseable
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          size="md"
        >
          <LocationContainer
            onSaveLocation={() => setIsOpen(false)}
            setSnackbarMessage={setSnackbarMessage}
            showSkipButton={false}
          />
        </Modal>
      )}
    </>
  );
};
