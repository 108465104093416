import { IconButton } from '@material-ui/core';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import * as React from 'react';
import { useHistory } from 'react-router';
import './styles.scss';

export const baseClass = 'pn-respondent-plg';
export const RespondentPlgWrapper: React.FC<{ readonly title: string }> = (p) => {
  const history = useHistory();

  return (
    <MainPage hideRightSidebar title={`${p.title} - Polco`}>
      {(_respondentUser) => {
        return (
          <div className={`${baseClass} bg-white`}>
            <div className="d-flex flex-row align-items-center mx-4 px-3">
              <div className="font-size-lg font-weight-bold text-brand-xd my-3 py-3">
                {p.title}
              </div>
              <IconButton
                className={`${baseClass}-close-icon ml-auto`}
                onClick={() => {
                  history.push(ClientUrlUtils.respondent.feed.path());
                }}
              >
                <MaterialIcon
                  className={`${baseClass}-close-icon`}
                  icon={MaterialIconName.CLOSE}
                />
              </IconButton>
            </div>
            <div className="mx-4 px-3 mb-3 pb-3">{p.children}</div>
          </div>
        );
      }}
    </MainPage>
  );
};
