import * as React from 'react';
import { CurrentUser } from 'client/respondent/hooks/use-respondent';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { BalancingActSimulationIFrame } from '../components';
import { Redirect } from 'react-router-dom';

export const BalancingActSimulationContainer: React.FC<{
  readonly respondent: CurrentUser | null;
  readonly simulationId: number;
  readonly isTaxReceipt: boolean;
}> = (p) => {
  const querySimulation = useQueryInfo(QueryInfos.adminOpenBalancingActSimulation, {
    variables: {
      balancingActSimulationId: p.simulationId,
    },
    skip: p.isTaxReceipt,
  });
  const queryTaxReceipt = useQueryInfo(QueryInfos.adminOpenBalancingActTaxReceipt, {
    variables: {
      balancingActTaxReceiptId: p.simulationId,
    },
    skip: !p.isTaxReceipt,
  });

  if (querySimulation.loading || queryTaxReceipt.loading) {
    return <RespondentPageLoader />;
  }

  const data =
    querySimulation.data?.openBalancingActSimulation ||
    queryTaxReceipt.data?.openBalancingActTaxReceipt;

  const url = data?.url;

  if (!url) {
    return <Redirect to={'/res/not-found'} />;
  }
  return <BalancingActSimulationIFrame url={url} />;
};
