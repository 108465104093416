import * as React from 'react';

import { AuthnFlowSliderModal } from '../../account/containers/authn-flow-slider-modal';
import PolcoManIdea from 'client/respondent/shared/account/containers/sliders/assets/polcoman-idea.svg';
import polcoLogo from 'client/assets/polco-logo.svg';

const copy = {
  informationPrompt: {
    description:
      'Polco improves how community leaders seek input on important topics and how their members provide it. As a result, the civic process becomes a lively exchange of ideas, restoring civility and quality to policy-making discourse.',
    description2: `Polco's civic engagement platform makes meaningful communication between community leaders and the people they serve not only possible, but enjoyable.`,
    title: 'What is Polco?',
    button: 'Continue',
  },
  description:
    'When you sign up, we make sure no one else responds on your behalf and we personalize your experience. Questions on Polco are posted by officials in YOUR local government or community who want your input.',
  title: 'Why create an account?',
};

export const LearnMoreAboutPolcoPrompt: React.FC<{
  readonly onClose: () => void;
}> = (p) => {
  return (
    <AuthnFlowSliderModal
      informationPrompt={{
        name: 'What is Polco?',
        paragraphs: [
          <p>{copy.informationPrompt.description}</p>,
          <p className="mt-3">{copy.informationPrompt.description2}</p>,
        ],
        background: PolcoManIdea,
        buttons: {
          name: copy.informationPrompt.button,
        },
      }}
      isOpen
      onClose={p.onClose}
      registrationPrompt={{
        description: copy.description,
        image: (
          <div className="w-100 d-flex justify-content-center">
            <img alt="Polco logo" src={polcoLogo} />
          </div>
        ),
        title: copy.title,
      }}
    />
  );
};
