import * as React from 'react';
import './styles.scss';
import { OverviewItem, PublishingEntityAsset } from 'client/respondent/core/types';
import { OVERVIEW_COPY } from './copy';
import { ClientQuestionId } from 'client/shared/core/question';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { VotingHeader } from '../../../shared/components/header';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { QuestionSetType, wrap } from 'core';
import { AlertBanner } from 'client/respondent/voting/shared/components/alert-banner';

const baseClass = 'pn-overview';
interface Props {
  readonly setType: QuestionSetType;
  readonly pubName: string;
  readonly assets: readonly PublishingEntityAsset[];
  readonly shareable: boolean;
  readonly events: {
    readonly goToOutcome: (() => void) | null;
    readonly goToQuestion: (
      questionId: ClientQuestionId,
      isLiveEvent: boolean
    ) => void;
    readonly goToReport: (reportId: string) => void;
    readonly goToFeed: () => void;
    readonly goToPublishingEntity: () => void;
    readonly goBack: () => void;
  };
  readonly overviewItem: readonly OverviewItem[];
}

interface ViewItemProps {
  readonly setType: QuestionSetType;
  readonly overviewItem: OverviewItem;
  readonly itemNumber: number;
  readonly isLiveEvent: boolean;
  readonly events: {
    readonly goToQuestion: (
      questionId: ClientQuestionId,
      isLiveEvent: boolean
    ) => void;
    readonly goToReport: (reportId: string) => void;
  };
}

const Title: React.FC<{
  readonly setType: QuestionSetType;
}> = (props) => {
  const title = wrap(() => {
    switch (props.setType) {
      case QuestionSetType.SET:
        return OVERVIEW_COPY.pollSetOverview;
      case QuestionSetType.POLCO_LIVE:
        return OVERVIEW_COPY.liveEventOverview;
      case QuestionSetType.SURVEY:
        return OVERVIEW_COPY.reportOverview;
    }
  });
  return (
    <div className={`${baseClass}-title text-center font-weight-bold`}>{title}</div>
  );
};

const wellText: Record<QuestionSetType, string> = {
  [QuestionSetType.SET]: OVERVIEW_COPY.pollClosed,
  [QuestionSetType.POLCO_LIVE]: OVERVIEW_COPY.liveClosed,
  [QuestionSetType.SURVEY]: OVERVIEW_COPY.surveyClosed,
  [QuestionSetType.CONTENT_POST]: OVERVIEW_COPY.contentPostClosed,
};

export const Overview: React.FC<Props> = (p) => {
  const subTitle = wrap(() => {
    switch (p.setType) {
      case QuestionSetType.POLCO_LIVE:
      case QuestionSetType.SET:
        return OVERVIEW_COPY.overviewDescForSet;
      case QuestionSetType.SURVEY:
        return OVERVIEW_COPY.overviewDescForReport;
    }
  });

  return (
    <div className={`${baseClass} bg-white text-wrap`}>
      <VotingHeader
        events={{
          goToFeed: p.events.goToFeed,
          goToPublishingEntity: p.events.goToPublishingEntity,
          goBack: p.events.goBack,
        }}
        navPercentage={100}
        primaryLogo={publishingEntityPrimaryLogoUrl(p.assets)}
        publisherName={p.pubName}
        shareable={p.shareable}
      />

      <div className="position-relative z3">
        <AlertBanner alertText={wellText[p.setType]} errorType />
      </div>

      <div>
        {!!p.events.goToOutcome && (
          <Btn
            action={p.events.goToOutcome}
            className="p-2 text-jungle mr-3 mt-5"
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_LEFT} />
            {OVERVIEW_COPY.viewOutcome}
          </Btn>
        )}

        <div
          className={`${baseClass}-title text-center font-weight-bold ${
            !p.events.goToOutcome ? `mt-5` : ''
          }`}
        >
          <Title setType={p.setType} />
        </div>

        <div className={'text-center py-3 font-size-sm text-gray-40'}>
          {subTitle}
        </div>
        {p.overviewItem.map((q, i) => (
          <ViewItem
            events={p.events}
            isLiveEvent={p.setType === QuestionSetType.POLCO_LIVE}
            itemNumber={i + 1}
            key={q.id}
            overviewItem={q}
            setType={p.setType}
          />
        ))}
      </div>
    </div>
  );
};

export const ViewItem: React.FC<ViewItemProps> = (props) => {
  const { overviewItem, itemNumber, isLiveEvent, setType } = props;

  return (
    <div>
      <div className="font-weight-bold row px-3">
        <div className="col-8 py-2">
          <span>
            {itemNumber}. {overviewItem.title}
          </span>
        </div>
        <div className="col-4 text-right">
          <Btn
            action={() => {
              if (setType === QuestionSetType.SURVEY) {
                props.events.goToReport(overviewItem.id);
              } else {
                props.events.goToQuestion(overviewItem.id, isLiveEvent);
              }
            }}
            className="p-0"
            size={'large'}
            type={ButtonTypes.SEAMLESS}
          >
            View
          </Btn>
        </div>
      </div>
    </div>
  );
};
