export const OUTCOME_COPY = {
  viewResult: 'View results',
  viewSurvey: 'View my answers',
  returnToFeed: 'Return Home',
  pollClosed: 'This poll has closed',
  liveClosed: 'This live event has closed',
  surveyClosed: 'This survey has closed',
  contentPostClosed: 'This content post has closed',
  pollDescription: 'Poll Set Description',
  liveDescription: 'Live Event Description',
  surveyDescription: 'Survey Description',
  contentPostDescription: 'Content Post Description',
  youResponded: (date: string) => `You responded on ${date}`,
};
