import * as React from 'react';
import { Following } from '../../components/following';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { ClientRespondentId } from 'client/respondent/core';
import { SearchPublisher } from '../../components/search-publisher';
import { Redirect } from 'react-router';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { sortBy } from 'lodash';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { TabBar } from 'client/shared/components/base/tab-bar';
import { usePublisherSubscriptionData } from 'client/respondent/hooks/use-respondent-subscription-info';
import { SubscriptionsTabs } from 'client/respondent/core/types';
import { Helmet } from 'react-helmet';
export const SubscriptionsPage: React.FC = () => {
  return (
    <MainPage hideRightSidebar={true}>
      {(currentUser) => {
        const respId = currentUser?.user?.respondent?.id ?? null;
        return respId ? (
          <SubscriptionsPageInner respId={respId} />
        ) : (
          <Redirect to={ClientUrlUtils.respondent.feed.path()} />
        );
      }}
    </MainPage>
  );
};
SubscriptionsPage.displayName = 'SubscriptionsPage';

export const SubscriptionsPageInner: React.FC<{
  readonly respId: ClientRespondentId;
}> = (p) => {
  const [currentTab, setCurrentTab] = React.useState(SubscriptionsTabs.FOLLOWING);

  const { subscribablePubs, subscribedPubs, events, query } =
    usePublisherSubscriptionData(p.respId);
  const searchablePubs = [...subscribablePubs, ...subscribedPubs];

  const tabs = [
    { key: SubscriptionsTabs.FOLLOWING, label: 'Following' },
    { key: SubscriptionsTabs.SEARCHING, label: 'Search' },
  ];

  const changeTab = async (tab: SubscriptionsTabs) => {
    setCurrentTab(tab);

    // following mutation returns the publishing entity meaning
    // our local cache of subscriptions is not updated (that would be possible
    // if the mutation returned the respondent). So we need to force that update
    // ourselves
    await query.refetch();
  };

  return (
    <div className="w-100">
      <Helmet>
        <title>Polco - Feeds</title>
      </Helmet>
      <TabBar
        selected={tabs.find((tab) => tab.key === currentTab) ?? tabs[0]}
        tabSelected={(tab) => changeTab(tab.key)}
        tabs={tabs}
      />
      {query.loading ? (
        <RespondentPageLoader />
      ) : currentTab === SubscriptionsTabs.FOLLOWING ? (
        <Following
          event={events}
          goToSearchTab={changeTab}
          publishers={sortBy(subscribedPubs, (s) => s.name)}
        />
      ) : (
        <div className="bg-white">
          <SearchPublisher event={events} searchablePublishers={searchablePubs} />
        </div>
      )}
    </div>
  );
};
