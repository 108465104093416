import * as React from 'react';
import './styles.scss';

import { VotingHeader } from 'client/respondent/voting/shared/components/header';
import { OutcomeTitle } from 'client/shared/components/outcomes/outcome-title';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { PublishingEntityAsset } from 'client/respondent/core/types';
import { Btn, ButtonTypes, HtmlContent } from 'client/shared/components/base';
import { OUTCOME_COPY } from './copy';
import moment from 'moment';
import { PARSE_DATE_FORMAT, QuestionSetType } from 'core';
import { AlertBanner } from 'client/respondent/voting/shared/components/alert-banner';

const baseClass = 'pn-outcome';

interface Props {
  readonly setType: QuestionSetType;
  readonly pubName: string;
  readonly assets: readonly PublishingEntityAsset[];
  readonly setTitle: string;
  readonly setDesc: string | null;
  readonly outcome: string;
  readonly totalResponses: number;
  readonly responseDate: Date | null;
  readonly hasReportTab: boolean;
  readonly shareable: boolean;
  readonly events: {
    readonly goToFeed: () => void;
    readonly goToPublishingEntity: () => void;
    readonly exit: () => void;
    readonly goToOverview: () => void;
    readonly goToSurvey: () => void;
  };
}

const wellText: Record<QuestionSetType, string> = {
  [QuestionSetType.SET]: OUTCOME_COPY.pollClosed,
  [QuestionSetType.POLCO_LIVE]: OUTCOME_COPY.liveClosed,
  [QuestionSetType.SURVEY]: OUTCOME_COPY.surveyClosed,
  [QuestionSetType.CONTENT_POST]: OUTCOME_COPY.contentPostClosed,
};

const descriptionText: Record<QuestionSetType, string> = {
  [QuestionSetType.SET]: OUTCOME_COPY.pollDescription,
  [QuestionSetType.POLCO_LIVE]: OUTCOME_COPY.liveDescription,
  [QuestionSetType.SURVEY]: OUTCOME_COPY.surveyDescription,
  [QuestionSetType.CONTENT_POST]: OUTCOME_COPY.contentPostDescription,
};

export const Outcome: React.FC<Props> = (p) => {
  return (
    <div className={`${baseClass} bg-white text-wrap d-flex flex-column`}>
      <VotingHeader
        events={{
          goToFeed: p.events.goToFeed,
          goToPublishingEntity: p.events.goToPublishingEntity,
          goBack: p.events.exit,
        }}
        navPercentage={100}
        primaryLogo={publishingEntityPrimaryLogoUrl(p.assets)}
        publisherName={p.pubName}
        shareable={p.shareable}
      />
      <div className="position-relative z3">
        <AlertBanner alertText={wellText[p.setType]} errorType />
      </div>
      <div className="mx-3 py-4 d-flex flex-column flex-grow-1 mt-5">
        <div className={`${baseClass}-title-container`}>
          <h1 className={`${baseClass}-title font-weight-bold mb-0`}>
            {p.setTitle}
          </h1>
        </div>

        <div className="d-flex my-3 font-size-xs">
          <p className="mr-1">{`${p.totalResponses} responses`}</p>
          {p.responseDate && (
            <p>
              {' - '}
              {OUTCOME_COPY.youResponded(
                moment(p.responseDate).format(PARSE_DATE_FORMAT)
              )}
            </p>
          )}
        </div>

        {p.setDesc && (
          <>
            <h2 className="font-weight-bold font-size-base m-0">
              {descriptionText[p.setType]}
            </h2>
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <HtmlContent html={p.setDesc} imageAlt={descriptionText[p.setType]} />
            </div>
          </>
        )}

        <OutcomeTitle setType={p.setType} />
        <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
          <HtmlContent html={p.outcome} imageAlt="outcome" />
        </div>

        <div
          className={`${
            p.setType === QuestionSetType.SURVEY
              ? 'd-flex justify-content-between'
              : 'd-flex justify-content-end'
          } pn-action-buttons w-100 pt-3`}
        >
          {p.setType === QuestionSetType.SURVEY && !p.hasReportTab ? (
            <Btn
              action={() => p.events.goToFeed()}
              className={`${baseClass}-start-button`}
              type={ButtonTypes.SECONDARY}
            >
              <div>{OUTCOME_COPY.returnToFeed}</div>
            </Btn>
          ) : (
            <Btn
              action={() => p.events.goToOverview()}
              className={`${baseClass}-start-button`}
              type={ButtonTypes.SECONDARY}
            >
              <div>{OUTCOME_COPY.viewResult}</div>
            </Btn>
          )}
          {p.setType === QuestionSetType.SURVEY && (
            <Btn
              action={() => p.events.goToSurvey()}
              className={`${baseClass}-start-button`}
              type={ButtonTypes.PRIMARY}
            >
              <div>{OUTCOME_COPY.viewSurvey}</div>
            </Btn>
          )}
        </div>
      </div>
    </div>
  );
};
