import * as React from 'react';
import { MainPage } from '../main-page';
import { NotAvailableUI } from './page-ui';

export const NotAvailable: React.FC = () => {
  return (
    <MainPage>
      {() => {
        return <NotAvailableUI />;
      }}
    </MainPage>
  );
};
NotAvailable.displayName = 'NotAvailable';
