import * as React from 'react';
import './styles.scss';

import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { LIVE_ACTION_BUTTON_COPY } from './copy';
import { LoadedEvents, VotingInteraction } from 'client/shared/core/types';

interface ActionButtonEvent {
  readonly submit: () => Promise<void>;
  readonly nextQuestion: () => void;
  readonly goToOverview: () => void;
  readonly doneAnsweringQuestions: LoadedEvents['doneAnsweringQuestions'];
  readonly followPublisher: LoadedEvents['followPublisher'];
  readonly setInteraction: LoadedEvents['setInteraction'];
}

interface Props {
  readonly events: ActionButtonEvent;
  readonly voted: boolean;
  readonly closed: boolean;
  readonly allowSubmit: boolean;
  readonly setIdOrSlug: string;
  readonly error: string | null;
  readonly fromOverview: boolean;
  readonly showAddComment: boolean;
  readonly hideBtns: boolean;
  readonly liveEventEnded: boolean;
}

interface LeftButtonProps {
  readonly events: ActionButtonEvent;
  readonly fromOverview: boolean;
  readonly showAddComment: boolean;
}

interface RightButtonProps {
  readonly events: ActionButtonEvent;
  readonly allowSubmit: boolean;
  readonly voted: boolean;
  readonly closed: boolean;
  readonly liveEventEnded: boolean;
  readonly error: string | null;
  readonly fromOverview: boolean;
}

const baseClass = 'pn-live-buttons';

export const LiveActionButtons: React.FC<Props> = (props) => {
  const {
    voted,
    closed,
    allowSubmit,
    events,
    error,
    fromOverview,
    showAddComment,
    hideBtns,
    liveEventEnded,
  } = props;

  return (
    <div className={`${baseClass} w-100`}>
      <div className="d-flex p-4 justify-content-center align-items-center">
        {!hideBtns && (
          <>
            <div className="w-50 ">
              <LeftButton
                events={events}
                fromOverview={fromOverview}
                showAddComment={showAddComment}
              />
            </div>
            <div className="w-50 d-flex justify-content-end">
              <RightButton
                allowSubmit={allowSubmit}
                closed={closed}
                error={error}
                events={events}
                fromOverview={fromOverview}
                liveEventEnded={liveEventEnded}
                voted={voted}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const LeftButton: React.FC<LeftButtonProps> = (p) => {
  const { showAddComment } = p;
  return (
    <div className="d-flex">
      {showAddComment && (
        <Btn
          action={() => p.events.setInteraction(VotingInteraction.COMMENTING)}
          className="p-2 mr-1"
          type={ButtonTypes.SECONDARY}
        >
          {LIVE_ACTION_BUTTON_COPY.comment}
        </Btn>
      )}
    </div>
  );
};

export const RightButton: React.FC<RightButtonProps> = (p) => {
  const { allowSubmit, events, closed, voted, fromOverview, error, liveEventEnded } =
    p;
  return (
    <div>
      {fromOverview && (voted || closed) && !liveEventEnded ? (
        <Btn
          action={events.goToOverview}
          className="rounded p-2"
          type={ButtonTypes.PRIMARY}
        >
          {LIVE_ACTION_BUTTON_COPY.returnToOverview}
        </Btn>
      ) : (
        <>
          {!voted && error && (
            <div className="text-danger font-size-sm">{error}</div>
          )}

          {closed || voted ? (
            <Btn
              action={events.nextQuestion}
              className="rounded p-2"
              type={ButtonTypes.PRIMARY}
            >
              {LIVE_ACTION_BUTTON_COPY.nextQuestion}{' '}
              <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_RIGHT} />
            </Btn>
          ) : (
            <Btn
              action={events.submit}
              className="rounded p-2"
              disabled={!allowSubmit}
              type={ButtonTypes.PRIMARY}
            >
              {LIVE_ACTION_BUTTON_COPY.submit}
              <MaterialIcon className="pl-2" icon={MaterialIconName.CHECK} />
            </Btn>
          )}
        </>
      )}
    </div>
  );
};
