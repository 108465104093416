import * as React from 'react';

import {
  ClientRespondentId,
  feedItemConverter,
  isPublisherFollowed,
} from 'client/respondent/core';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { useLanguageByQueryParam } from 'client/shared/hooks';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { PublisherInfo, PublisherProfile, UnfollowConfirm } from '../../components';
import { PublisherAlert } from '../feed';
import { FollowPublisherAccountPrompt } from 'client/respondent/shared/components/follow-publisher-account-prompt';
import { Helmet } from 'react-helmet';

export type Props = RouteComponentProps<{
  readonly pubSlug: string;
}>;

export const PublisherProfilePage: React.FC<Props> = (props: Props) => {
  return (
    <MainPage>
      {(respondentUser) => (
        <PublisherProfileInner
          respId={respondentUser?.user?.respondent?.id ?? null}
          slug={props.match.params.pubSlug}
        />
      )}
    </MainPage>
  );
};
PublisherProfilePage.displayName = 'PublisherProfilePage';

const PublisherProfileInner: React.FC<{
  readonly slug: string;
  readonly respId: ClientRespondentId | null;
}> = (p) => {
  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();

  React.useEffect(() => {
    if (state.lastFeedLocation !== p.slug) {
      dispatch(RespondentActions.setLastFeedLocation(p.slug));
    }
  }, [state.lastFeedLocation, dispatch, p.slug]);

  const voteAction = useMutationInfo(MutationInfos.voteForQuestion, {
    refetchQueries: [
      QueryInfos.respondentPublisherProfile.refetchInfo({
        slug: p.slug,
        respondentId: p.respId,
      }),
    ],
  });

  const followPublisher = useMutationInfo(MutationInfos.followPublishingEntity, {});

  const query = useQueryInfo(QueryInfos.respondentPublisherProfile, {
    variables: {
      slug: p.slug,
      respondentId: p.respId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const pubId = query.data?.openPublishingEntityBySlug?.id;
  const [showLoginPrompt, setShowLoginPrompt] = React.useState(false);
  const [publisherAlert, setPublisherAlert] = React.useState<PublisherAlert | null>(
    null
  );
  const { selectLanguageText } = useLanguageByQueryParam();

  const followAction = async (isFollowingAction: boolean) => {
    // Logged in follow action:
    if (p.respId && pubId) {
      const result = await followPublisher.fn({
        variables: {
          follow: isFollowingAction,
          publishingEntityId: pubId,
          respondentId: p.respId,
        },
        refetchQueries: [
          QueryInfos.respondentSubscriptions.refetchInfo({
            respondentId: p.respId,
          }),
        ],
      });
      if (result.data && !result.errors) {
        setShowLoginPrompt(false);
        return true;
      }
      return false;
    } else {
      setShowLoginPrompt(true);
      return false;
    }
  };

  const publisher = query.data?.openPublishingEntityBySlug;
  if (query.loading && !query.data) {
    // Just wait for the load to finish.
    return <RespondentPageLoader />;
  } else if (!publisher) {
    return <Redirect to={'/res/not-found'} />;
  }

  const publisherInfo: PublisherInfo = {
    assets: publisher.assets,
    publisherId: publisher.id,
    publisherName: selectLanguageText(publisher.name),
    center: publisher.presidingArea?.center ?? null,
    location: publisher.presidingArea?.name ?? null,
    seal: publishingEntityPrimaryLogoUrl(publisher.assets) ?? null,
    description: selectLanguageText(publisher.description),
  };

  const followingPub = isPublisherFollowed(publisher.subscriptionType);

  const args = {
    voteAction,
    respId: p.respId,
    setPublisherAlert,
    setShowLoginPrompt,
    currentLocation: state.currentLocation,
    followAction: followPublisher,
    dispatch,
    setSharingUrl: () => {},
    selectLanguageText,
    ideaVoteAction: null,
  };
  const feedItems = publisher.publisherFeed.items
    .map(feedItemConverter(args))
    .map((a) => a.banner);

  const onAuthenticationSuccess = async (authArgs: {
    readonly respondentId: ClientRespondentId;
  }) => {
    if (pubId) {
      await followPublisher.fn({
        variables: {
          follow: true,
          publishingEntityId: pubId,
          respondentId: authArgs.respondentId,
        },
        refetchQueries: [
          QueryInfos.respondentSubscriptions.refetchInfo({
            respondentId: authArgs.respondentId,
          }),
        ],
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Feed - {publisherInfo.publisherName} - Polco</title>
      </Helmet>
      {publisherAlert?.action === 'UNFOLLOW' && (
        <UnfollowConfirm
          events={{
            cancel: () => setPublisherAlert(null),
            unfollow: async () => {
              if (!p.respId) {
                setShowLoginPrompt(true);
                return;
              }
              const result = await followPublisher.fn({
                variables: {
                  publishingEntityId: publisherAlert.publisher.id,
                  follow: false,
                  respondentId: p.respId,
                },
                refetchQueries: [
                  QueryInfos.respondentSubscriptions.refetchInfo({
                    respondentId: p.respId,
                  }),
                ],
              });
              if (result.data && !result.errors) {
                setPublisherAlert(null);
              }
            },
          }}
          isOpen={!!publisherAlert}
          publisherName={selectLanguageText(publisherAlert.publisher.name)}
        />
      )}
      <PublisherProfile
        events={{
          followAction,
        }} // need to query to get this data param pub_id and resp_id
        feedItems={feedItems}
        following={followingPub}
        info={publisherInfo}
        preLoginAction={state.preLoginAction}
        resId={p.respId}
        showLoginPrompt={showLoginPrompt}
      />
      {showLoginPrompt && (
        <FollowPublisherAccountPrompt
          onAuthenticationSuccess={onAuthenticationSuccess}
          onClose={() => setShowLoginPrompt(false)}
          publisherName={publisherInfo.publisherName}
        />
      )}
    </>
  );
};
