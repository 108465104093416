export const POLCO_LIVE_WAITING_COPY = {
  title(questions: boolean) {
    return `Waiting for hosts to post the ${questions ? 'next' : 'first'} poll`;
  },
  desc(hasQuestion: boolean) {
    if (!hasQuestion) {
      return 'The first poll will show as soon as it becomes available';
    }
    return 'The next poll will be shown when posted. View the previous polls below';
  },
  answered(isMoreThanOne: boolean) {
    return `Below ${isMoreThanOne ? 'are' : 'is'} the ${
      isMoreThanOne ? 'questions' : 'question'
    } you've answered`;
  },

  unanswered(isMoreThanOne: boolean) {
    return `Below ${isMoreThanOne ? 'are' : 'is'} the ${
      isMoreThanOne ? 'questions' : 'question'
    } you've not answered`;
  },
  closedQuestion: 'Closed',
  open: 'View',
  closed: 'View',
  answerNow: 'Answer now',
};
