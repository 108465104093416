import * as React from 'react';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { Card } from 'client/shared/components/base/card';
import { ConfirmationModal } from 'client/shared/components/modal/confirmation-modal';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { useRedirect } from 'client/shared/hooks';
import { COPY } from './copy';
import { CreateIdeaForm } from './create-idea-form';
import { usePublisherSubscriptionCanPublishData } from 'client/respondent/hooks';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { NoSubscriptionsToCreateIdea } from '../../components/no-subscriptions-to-create-idea';

export const pageCreateIdeaCardBaseClassName = 'pn-create-idea-card';

export const CreateIdeaCard: React.FC<{ readonly respondentId: string }> = (
  props
) => {
  const redirect = useRedirect();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const { query, hasSubscriptions } = usePublisherSubscriptionCanPublishData(
    props.respondentId
  );

  if (query.loading) {
    return <RespondentPageLoader />;
  }

  function onCancel() {
    setOpenDialog(!openDialog);
  }
  function onConfirm() {
    redirect(ClientUrlUtils.respondent.feed.path(), { push: true });
  }

  return (
    <Card
      className={`${pageCreateIdeaCardBaseClassName} mb-3`}
      interactive={false}
      removeExtraBorder
    >
      <Card.Title className={`${pageCreateIdeaCardBaseClassName}-title`}>
        <div className="d-flex flex-row border-bottom">
          <div className="pr-3 flex-grow-1 w-80">
            <div className="d-flex justify-content-between mt-2 ml-3 mb-2">
              {COPY.pageTitle}
            </div>
          </div>
          <Btn
            action={onCancel}
            ariaLabel={COPY.confirmationModal.closeButton}
            className="text-sky-xd"
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon icon={MaterialIconName.CLOSE} />
          </Btn>
        </div>
      </Card.Title>
      <Card.Body className="d-flex flex-column">
        {!hasSubscriptions ? (
          <NoSubscriptionsToCreateIdea />
        ) : (
          <CreateIdeaForm
            className={pageCreateIdeaCardBaseClassName}
            respondentId={props.respondentId}
          />
        )}
      </Card.Body>
      {openDialog && (
        <ConfirmationModal
          buttonsCentered
          cancelLabel={COPY.confirmationModal.actions.noStay}
          confirmLabel={COPY.confirmationModal.actions.yesExit}
          copy={<ConfirmationModalBody />}
          events={{
            confirm: onConfirm,
            cancel: onCancel,
          }}
          isOpen={openDialog}
          prompt={COPY.confirmationModal.title}
        />
      )}
    </Card>
  );
};

CreateIdeaCard.displayName = 'CreateIdeaCard';

const ConfirmationModalBody: React.FC = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <MaterialIcon
        className={`${pageCreateIdeaCardBaseClassName}-icon text-jungle mb-5`}
        icon={MaterialIconName.EXIT_TO_APP}
      />
      <div className={`font-weight-bold h3 mb-2 text-center`}>
        {COPY.confirmationModal.body}
      </div>
    </div>
  );
};
