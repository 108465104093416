import { Well, Props as WellProps } from 'client/shared/components/well';
import * as React from 'react';
import { COPY } from './copy';
import {
  BtnLink,
  ButtonTypes,
  MaterialIconName,
} from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';

export const NoSubscriptionsToCreateIdea: React.FC = () => {
  const wellProps: WellProps = {
    icon: MaterialIconName.INFO,
    description: COPY.description,
  };
  return (
    <section>
      <Well {...wellProps} />
      <BtnLink
        className="mt-3"
        style={{ width: '100%' }}
        target={'_self'}
        to={ClientUrlUtils.respondent.feed.path()}
        type={ButtonTypes.PRIMARY}
      >
        {COPY.redirectButton.text}
      </BtnLink>
    </section>
  );
};
