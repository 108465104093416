import * as React from 'react';
import { VotingProps } from 'client/respondent/core/types';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { Redirect } from 'react-router';
import { QuestionSetLoaded } from 'client/respondent/voting/poll-set/containers/question-set-loaded';
import { PolcoLiveLoaded } from 'client/respondent/voting/polco-live/containers/polco-live-loaded';

function switchRender(props: VotingProps.QuestionProps) {
  switch (props.type) {
    case VotingProps.VotingPropsType.LOADING:
      return <RespondentPageLoader />;
    case VotingProps.VotingPropsType.LOADED:
      switch (props.setType) {
        case VotingProps.SetType.POLL_SET:
          return <QuestionSetLoaded {...props} />;
        case VotingProps.SetType.POLCO_LIVE:
          return <PolcoLiveLoaded {...props} />;
      }
    case VotingProps.VotingPropsType.ERROR_LOADING:
    case VotingProps.VotingPropsType.NOT_FOUND:
      return <Redirect to={'/res/not-found'} />;
  }
}

export const QuestionVotingContainer: React.FC<VotingProps.QuestionProps> = (
  props
) => <div>{switchRender(props)}</div>;
QuestionVotingContainer.displayName = 'QuestionVotingContainer';
