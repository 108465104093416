import { ClientRespondentId } from 'client/respondent/core';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { useLanguageByQueryParam } from 'client/shared/hooks';

import { QueryResult } from '@apollo/client';

import { FollowingPublisher } from '../core/types';
import {
  RespondentSubscriptionsQueryType,
  gqlToClient_FollowingPublisher,
} from './respondent-helpers';
import { RespondentActiveSubscriptionsForIdea } from 'client/shared/graphql-client/graphql-operations.g';

export function usePublisherSubscriptionCanPublishData(
  respId?: ClientRespondentId,
  options?: {
    readonly useCache?: boolean;
  }
): {
  readonly hasSubscriptions: boolean;
  readonly subscriptions: readonly FollowingPublisher[];
  readonly query: QueryResult<RespondentActiveSubscriptionsForIdea>;
} {
  const query = useQueryInfo(QueryInfos.RespondentActiveSubscriptionsForIdea, {
    variables: {
      respondentId: respId ?? 'skip-protected',
    },
    skip: !respId,
    fetchPolicy: options?.useCache ? 'cache-and-network' : 'no-cache',
  });

  const { selectLanguageText } = useLanguageByQueryParam();

  const respondent = query.data?.openRespondent;

  const subscriptions = respondent
    ? gqlToClient_FollowingPublisher(
        { type: RespondentSubscriptionsQueryType.CAN_PUBLISH_IDEA_PUBS, respondent },
        selectLanguageText
      )
    : [];

  return {
    hasSubscriptions: subscriptions.length > 0,
    subscriptions,
    query,
  };
}
