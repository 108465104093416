import * as React from 'react';
import * as SubscriptionInfos from 'client/shared/graphql-subscriptions/subscription-infos';
import { Props } from './events';
import { useQueryInfo } from 'client/shared/containers/query';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { useLanguageByQueryParam, useRedirect } from 'client/shared/hooks';
import { useMutationInfo } from 'client/shared/containers/mutation';
import * as MutationInfos from 'client/shared/graphql-mutations/mutation-infos';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { QuestionVotingContainer } from 'client/respondent/voting/shared/containers/question-voting';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { CurrentUser } from 'client/respondent/hooks/use-respondent';
import qs from 'query-string';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { QuestionType } from 'client/shared/core/question';
import {
  modifyUpvotes,
  convertToQuestionVotingProps,
} from '../../../shared/functions';
import {
  _dangerousUseEffectOnMount,
  useLocalStorage,
} from 'client/shared/helpers/hooks';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { QuestionPageRedirectLocation } from 'client/respondent/core/types';
import { useSubscriptionInfo } from 'client/shared/containers/subscription';
import {
  QuestionNewComment,
  QuestionNewCommentVariables,
  Voting,
} from 'client/shared/graphql-client/graphql-operations.g';
import { UpdateQueryFn } from '@apollo/client/core/watchQueryOptions';

export function questionIdFromParamsProps(props: Pick<Props, 'match'>): string {
  return props.match.params.questionId;
}

function questionSetSlugFromParamsProps(props: Props): string {
  return props.match.params.setSlug;
}

function pubSlugFromParamsProps(props: Props): string | undefined {
  return props.match.params.pubSlug;
}

function questionChoiceIdFromParamsProps(props: Props): string | undefined {
  const q = qs.parse(props.location.search);
  return q?.selection;
}

function redirectedFrom(props: Props): QuestionPageRedirectLocation | undefined {
  const q = qs.parse(props.location.search);
  if (q?.from && Object.values(QuestionPageRedirectLocation).includes(q.from)) {
    return q.from;
  } else {
    return undefined;
  }
}

export const QuestionVotingPage: React.FC<Props> = (p: Props) => {
  const from = redirectedFrom(p);
  const redirect = useRedirect();
  const goBack =
    p.history.length > 1
      ? p.history.goBack
      : () => {
          redirect(ClientUrlUtils.respondent.feed.path(), { push: false });
        };
  const questionId = questionIdFromParamsProps(p);
  const setSlug = questionSetSlugFromParamsProps(p);
  const pubSlug = pubSlugFromParamsProps(p);
  const questionChoiceId = questionChoiceIdFromParamsProps(p);

  if (p.isPolcoLive) {
    return (
      <QuestionVotingInner
        goBack={goBack}
        pubSlug={pubSlug ?? 'by-id'}
        questionChoiceId={questionChoiceId}
        questionId={questionId} // not actually used, but here for clarity
        redirectedFrom={from}
        respondentUser={p.respondent ?? null}
        setSlug={setSlug}
      />
    );
  }

  return (
    <MainPage hideTopNav={true} showTermsAndPrivacyDesktop>
      {(respondentUser) => (
        <QuestionVotingInner
          goBack={goBack}
          pubSlug={pubSlug ?? 'by-id'}
          questionChoiceId={questionChoiceId}
          questionId={questionId} // not actually used, but here for clarity
          redirectedFrom={from}
          respondentUser={respondentUser}
          setSlug={setSlug}
        />
      )}
    </MainPage>
  );
};
QuestionVotingPage.displayName = 'QuestionVotingPage';

const QuestionVotingInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
  readonly questionChoiceId?: string;
  readonly questionId: string;
  readonly pubSlug: string;
  readonly setSlug: string;
  readonly goBack: () => void;
  readonly redirectedFrom?: QuestionPageRedirectLocation;
}> = (p) => {
  const respId = p.respondentUser?.user?.respondent?.id ?? null;
  const dispatch = RespondentContext.useRespondentDispatch();

  const localStorage = useLocalStorage();

  const questionSetQuery = useQueryInfo(QueryInfos.respondentVotingPage, {
    variables: {
      questionSetSlug: p.setSlug,
      publishingEntitySlug: p.pubSlug,
      respondentId: respId,
    },
    errorPolicy: 'all',
  });
  const qResult = useQueryInfo(QueryInfos.votingPage, {
    variables: {
      respondentId: respId,
      questionId: p.questionId,
      pagination: {
        page: 1,
        perPage: 10000,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const contentSetId = questionSetQuery.data?.openContentSetBySlug?.id;
  const isLive =
    questionSetQuery.data?.openContentSetBySlug?.__typename === 'PolcoLive';
  const { questionId } = p;
  React.useEffect(() => {
    if (isLive) {
      const disposeSubscription =
        SubscriptionInfos.questionNewComment.subscribeToMore<Voting>(
          qResult,
          { questionId },
          addNewCommentToQueryResult
        );
      return () => {
        disposeSubscription();
      };
    }
  }, [isLive, qResult, questionId]);
  const skip = !isLive || !contentSetId;
  useSubscriptionInfo(SubscriptionInfos.liveContentSet, {
    skip,
    variables: contentSetId
      ? {
          contentSetId,
          respondentId: respId,
        }
      : undefined,
  });

  // If we don't have a user, voting might create a guest one, so we need to check.
  const refetchUser = respId ? [] : [QueryInfos.currentRespondent.refetchInfo({})];

  const refetchQueries = [
    QueryInfos.votingPage.refetchInfo({
      questionId: p.questionId,
      respondentId: respId,
      pagination: {
        page: 1,
        perPage: 1000,
      },
    }),
    ...refetchUser,
  ];

  // question mutations
  const questionMuts: MutationInfos.QuestionVotingMutationReturns = {
    voteForQuestion: useMutationInfo(MutationInfos.voteForQuestion, {
      refetchQueries,
    }),
    addComment: useMutationInfo(MutationInfos.addComment, {
      refetchQueries,
    }),
    upvoteComment: useMutationInfo(MutationInfos.upvoteComment, {
      update: (dp, r) => modifyUpvotes(dp, { type: 'UPVOTE', mutation: r }),
    }),
    unUpvoteComment: useMutationInfo(MutationInfos.unUpvoteComment, {
      update: (dp, r) => modifyUpvotes(dp, { type: 'UNUPVOTE', mutation: r }),
    }),
    logout: useMutationInfo(MutationInfos.logout),
    followOnVoting: useMutationInfo(MutationInfos.followPublishingEntity, {
      refetchQueries: [
        QueryInfos.respondentSubscriptions.refetchInfo({
          respondentId: respId ?? 'never',
        }),
      ],
    }),
  };

  const state = RespondentContext.useRespondentState();
  const redirect = useRedirect();
  const { selectLanguageText } = useLanguageByQueryParam();

  // Magic Link voting, this will prepopulate MC choice selections
  _dangerousUseEffectOnMount(() => {
    if (p.questionChoiceId) {
      dispatch(
        RespondentActions.startInProcessVote(
          p.questionId,
          QuestionType.MULTIPLE_CHOICE
        )
      );
      dispatch(
        RespondentActions.selectedMultipleChoice(
          [{ id: p.questionChoiceId }],
          p.questionId
        )
      );
    }
  });

  if (
    (questionSetQuery.loading && !questionSetQuery.data) ||
    (qResult.loading && !qResult.data)
  ) {
    return <RespondentPageLoader />;
  }

  const props = convertToQuestionVotingProps(
    state,
    dispatch,
    qResult,
    questionMuts,
    redirect,
    questionSetQuery,
    p.respondentUser,
    p.questionId,
    p.goBack,
    selectLanguageText,
    localStorage,
    p.redirectedFrom
  );

  return <QuestionVotingContainer {...props} />;
};
QuestionVotingInner.displayName = 'QuestionVotingInner';

const addNewCommentToQueryResult: UpdateQueryFn<
  Voting,
  QuestionNewCommentVariables,
  QuestionNewComment
> = (prev, { subscriptionData }) => {
  if (!prev || !prev.openQuestion) {
    return prev;
  }
  const { openQuestion } = prev;
  const newComment = subscriptionData.data.questionNewComment;
  if (!newComment) {
    return prev;
  }
  const existingComments = openQuestion.commentsData.comments;
  const matchingIndex = existingComments.findIndex((c) => c.id === newComment.id);

  // swap out old data if already present
  const newComments =
    matchingIndex >= 0
      ? existingComments.map((x, i) => (i === matchingIndex ? newComment : x))
      : [newComment, ...existingComments];
  return {
    openQuestion: {
      ...openQuestion,
      commentsData: {
        ...openQuestion.commentsData,
        totalCount: newComments.length,
        comments: newComments,
      },
    },
  };
};
