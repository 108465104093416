import * as React from 'react';
import { PUBLISHER_PROFILE_COPY } from './copy';
import {
  FeedCardBanner,
  FeedCardBannerProps,
} from 'client/respondent/feed/components/body/feed-card/feed-card-banner';
import { UnfollowConfirm } from '../unfollow-confirm-modal';
import { ClientRespondentId } from 'client/respondent/core';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import { processPreLoginAction } from 'client/respondent/shared/functions';
import { Coordinate } from 'core';
import { PreLoginAction, NotLoggedInActions } from 'client/respondent/core/types';
import {
  ClientPublishingEntityId,
  publishingEntityProfileCoverUrl,
} from 'client/shared/core/publishing-entity';

import './styles.scss';
import {
  FollowingBtn,
  FollowStatus,
} from 'client/respondent/shared/components/following-button';
import { HtmlContent } from 'client/shared/components/base';
import { ProfileMap } from 'client/shared/components/profile-map';
import { PublishingEntityAssetType } from 'client/shared/graphql-client/graphql-operations.g';

export interface PublisherProfileProps {
  readonly info: PublisherInfo;
  readonly resId: ClientRespondentId | null;
  readonly following: boolean;
  readonly events: {
    readonly followAction: (isFollowingAction: boolean) => Promise<boolean>;
  };
  readonly feedItems: readonly FeedCardBannerProps[];
  readonly showLoginPrompt: boolean;
  readonly preLoginAction: PreLoginAction | null;
}

export interface PublisherDescriptionProps {
  readonly description: string;
  readonly publisherName: string;
}

export interface PublisherMenuProps {
  readonly following: boolean;
  readonly events: {
    readonly followAction: (isFollowingAction: boolean) => Promise<boolean>;
  };
  readonly publisherName: string;
  readonly publisherId: ClientPublishingEntityId;
}

export interface PublisherInfo {
  readonly publisherId: string;
  readonly publisherName: string;
  readonly description: string;
  readonly center: {
    readonly coordinate: Coordinate;
    readonly size: number;
  } | null;
  readonly location: string | null;
  readonly seal: string | null;
  readonly assets: readonly {
    readonly type: PublishingEntityAssetType;
    readonly url: string;
  }[];
}

interface PublisherInfoProps {
  readonly info: PublisherInfo;
  readonly following: boolean;
  readonly events: {
    readonly followAction: (isFollowingAction: boolean) => Promise<boolean>;
  };
}

const baseClass = 'pn-publisher-profile';

export const PublisherProfile: React.FC<PublisherProfileProps> = (props) => {
  const { info, events, following, feedItems } = props;
  const profileCoverImgUrl = publishingEntityProfileCoverUrl(info.assets);
  const dispatch = RespondentContext.useRespondentDispatch();

  React.useEffect(() => {
    processPreLoginAction(
      props.preLoginAction,
      NotLoggedInActions.FOLLOW,
      dispatch,
      (act) => {
        if (act.data.pubId !== props.info.publisherId) return null;
        return events.followAction(true);
      }
    );
  }, [dispatch, events, props.info.publisherId, props.preLoginAction]);

  return (
    <div className={baseClass}>
      <h1 className="d-none">Publisher profile page</h1>
      <div className="pn-feed-card-rounded">
        <div className={`${baseClass}-cover-container`}>
          {profileCoverImgUrl ? (
            <img
              alt=""
              className={`${baseClass}-cover-image`}
              src={profileCoverImgUrl}
            />
          ) : (
            <ProfileMap center={info.center} publisherName={info.publisherName} />
          )}
        </div>
        <div className={`${baseClass}-info-and-tab`}>
          <PublisherInfo events={events} following={following} info={info} />
        </div>
        <div
          className={`${baseClass}-sticky-shadow ${
            !info.seal ? 'no-seal-image' : ''
          }`}
        ></div>
        <PublisherDescription
          description={info.description}
          publisherName={info.publisherName}
        />
      </div>
      <div className="mt-4">
        <div className={`${baseClass}-divider`}></div>
      </div>
      {feedItems.map((item) => (
        <div
          className="pn-respondent-feed-card mb-2 mb-sm-3 bg-gray-10 pn-feed-card-rounded"
          key={item.key}
        >
          <FeedCardBanner {...item} />
        </div>
      ))}
    </div>
  );
};

const PublisherDescription: React.FC<PublisherDescriptionProps> = (props) => {
  return (
    <div className={`${baseClass}-description px-3 pb-3 bg-white`}>
      <div className="font-size-sm text-gray-40 py-2">
        <HtmlContent
          html={props.description}
          imageAlt={PUBLISHER_PROFILE_COPY.descriptionImageAltTag(
            props.publisherName
          )}
        />
      </div>
    </div>
  );
};

const PublisherInfo: React.FC<PublisherInfoProps> = (props) => {
  const { info, following, events } = props;
  const { publisherName, publisherId, location, seal } = info;
  return (
    <div className="d-flex flex-column px-3 pb-2">
      <div className="d-flex justify-content-between align-items-center pt-3">
        <div className="d-flex align-items-center">
          <div className="pr-3">
            <h2 className="font-weight-bold font-size-base m-0">{publisherName}</h2>
            {location && <p className="font-size-sm text-gray-40">{location}</p>}
          </div>
          {seal && (
            <img
              alt={`${publisherName} seal`}
              className="pn-publisher-info-seal"
              src={seal}
            />
          )}
        </div>
        <PublisherMenu
          events={events}
          following={following}
          publisherId={publisherId}
          publisherName={publisherName}
        />
      </div>
    </div>
  );
};

const PublisherMenu: React.FC<PublisherMenuProps> = ({
  events,
  following,
  publisherName,
  publisherId,
}) => {
  const [isUnfollowModalOpen, setIsUnfollowModalOpen] = React.useState(false);
  return (
    <div>
      <FollowingBtn
        customMargin="mx-0"
        events={{
          follow: () => events.followAction(true),
          unfollow: () => setIsUnfollowModalOpen(true),
        }}
        followStatus={
          following ? FollowStatus.FOLLOWING : FollowStatus.NOT_FOLLOWING
        }
        pubId={publisherId}
      />
      <UnfollowConfirm
        events={{
          cancel: () => setIsUnfollowModalOpen(false),
          unfollow: async () => {
            const result = await events.followAction(false);
            if (result) {
              setIsUnfollowModalOpen(false);
            }
          },
        }}
        isOpen={isUnfollowModalOpen}
        publisherName={publisherName}
      />
    </div>
  );
};
