import * as React from 'react';
import qs from 'query-string';
import { Modal } from 'client/shared/components/modal';
import { UNSUBSCRIBE_CONFIRM_COPY } from './copy';
import { UnsubscribeResult } from 'core';
import { useLocation, useHistory } from 'react-router-dom';

export interface UnsubscribeConfirmProps {
  readonly events: {
    readonly ok: () => Promise<any>;
  };
  readonly isOpen: boolean;
}

export const UnsubscribeConfirm: React.FC = (_props) => {
  const history = useHistory();
  const location = useLocation();
  const result = qs.parse(location.search)['modal'] as UnsubscribeResult;
  return (
    <Modal
      className="d-flex justify-center align-items-center h-100"
      footer={{
        primaryAction: {
          label: UNSUBSCRIBE_CONFIRM_COPY.ok,
          action: () => {
            const { modal: _trash, ...params } = qs.parse(location.search);
            history.replace({
              pathname: history.location.pathname,
              search: `?${qs.stringify(params)}`,
            });
          },
        },
      }}
      isCloseable={false}
      isOpen={!!result}
    >
      <div className="text-center">
        <div className="font-size-sm my-3 py-3">
          {result && UNSUBSCRIBE_CONFIRM_COPY.desc[result]}
        </div>
      </div>
    </Modal>
  );
};
