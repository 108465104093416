import * as React from 'react';
import './styles.scss';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';

import { LoadedEvents } from 'client/shared/core/types';
import { VotingHeader } from '../../../shared/components/header';
import { LIVE_STREAM_COPY } from './copy';
import { SubscriptionType } from 'client/shared/graphql-client/graphql-operations.g';

interface Props {
  readonly isHidden: boolean;
  readonly liveEventEnded: boolean;
  readonly isEventCurrentlyRunning: boolean;
  readonly videoLink: string | null;
  readonly votingHeaderInfo: {
    readonly percentage: number;
    readonly pubName: string;
    readonly subscriptionType: SubscriptionType | null;
    readonly logo: string | null;
    readonly voted?: boolean;
  };
  readonly events: {
    readonly goToFeed: LoadedEvents['goToFeed'];
    readonly goBack: LoadedEvents['goBack'];
    readonly openShareModal?: () => void;
    readonly publisherAction?: () => Promise<void>;
    readonly goToPublishingEntity: () => void;
    readonly showOrHideStream: () => void;
    readonly goToPreviousQuestion?: () => void;
    readonly goToOverview: () => void;
  };
}

const baseClass = 'pn-live-event-shared-header';

export const PolcoLiveSharedHeader: React.FC<Props> = (props) => {
  const {
    isHidden,
    votingHeaderInfo,
    events,
    videoLink,
    isEventCurrentlyRunning,
    liveEventEnded,
  } = props;

  return (
    <div className="bg-white">
      <VotingHeader
        closed={liveEventEnded}
        events={{
          goToFeed: events.goToFeed,
          goBack: events.goBack,
          goToPublishingEntity: events.goToPublishingEntity,
          openShareModal: events.openShareModal,
          publisherAction: events.publisherAction,
        }}
        isEventCurrentlyRunning={isEventCurrentlyRunning}
        navPercentage={votingHeaderInfo.percentage}
        primaryLogo={votingHeaderInfo.logo}
        publisherName={votingHeaderInfo.pubName}
        shareable={true}
        subscriptionType={votingHeaderInfo.subscriptionType}
        voted={votingHeaderInfo.voted}
      />

      <div>
        <div className="d-flex flex-row justify-content-between">
          {events.goToPreviousQuestion ? (
            <Btn
              action={events.goToPreviousQuestion}
              className="p-2 text-primary m-1"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_LEFT} />
              {LIVE_STREAM_COPY.previous}
            </Btn>
          ) : (
            <div />
          )}
          {props.liveEventEnded && (
            <Btn
              action={events.goToOverview}
              className="p-2 text-primary m-1"
              type={ButtonTypes.SEAMLESS}
            >
              {LIVE_STREAM_COPY.liveEventOverview}
            </Btn>
          )}

          {videoLink && (
            <Btn action={events.showOrHideStream} type={ButtonTypes.SEAMLESS}>
              {isHidden ? (
                <span className="font-weight-normal">
                  <MaterialIcon
                    className={`${baseClass}-show-icon`}
                    icon={MaterialIconName.LIVE_TV}
                  />
                  {LIVE_STREAM_COPY.showStream}
                </span>
              ) : (
                <span className="font-weight-normal">
                  <MaterialIcon
                    className={`${baseClass}-hide-icon`}
                    icon={MaterialIconName.TV_OFF}
                  />
                  {LIVE_STREAM_COPY.hideStream}
                </span>
              )}
            </Btn>
          )}
        </div>
        {!isHidden && videoLink && !liveEventEnded && (
          <iframe
            className={`${baseClass}-iframe`}
            src={videoLink}
            title="Live stream"
          />
        )}
      </div>
    </div>
  );
};
