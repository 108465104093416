import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MainPage } from '../main-page';
import { BadShortUrlUI } from './page-ui';

export const BadShortUrl: React.FC<
  RouteComponentProps<{
    readonly shortId: string;
  }>
> = (p) => {
  return (
    <MainPage>
      {() => {
        return <BadShortUrlUI shortId={p.match.params.shortId} />;
      }}
    </MainPage>
  );
};
BadShortUrl.displayName = 'BadShortUrl';
