import './styles.scss';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { useRedirect } from 'client/shared/hooks';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CreateIdeaCard } from './create-idea-card';

export const CreateIdeaPage: React.FC<RouteComponentProps<void>> = () => {
  const redirect = useRedirect();
  return (
    <MainPage hideRightSidebar>
      {(respondentUser) => {
        const respondentId = respondentUser?.user?.respondent?.id;
        if (respondentId) {
          return <CreateIdeaCard respondentId={respondentId} />;
        }
        redirect(ClientUrlUtils.respondent.login.path(), { push: true });
        return null;
      }}
    </MainPage>
  );
};
