import React, { useState, useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as RegisterServiceWorker from '../../../serviceWorker';
import CustomNotification, { NotificationMode } from './custom-notification-bar';
import { swallowExpression } from 'core';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {
  isInStandaloneMode,
  isIOSSafari,
  isLocalHost,
} from 'client/shared/core/helpers';
import { useDeferredInstallPrompt } from 'client/respondent/hooks';

const useStyles = makeStyles({
  snackbarContent: {
    padding: '9px 10px',
    fontSize: '13px',
  },
  message: {
    fontSize: '12px',
    paddingLeft: 0,
    marginRight: 0,
  },
});

const autoHideDurationTime = 6000;
const COPY = {
  message: 'There is a new version of Polco available.',
  action: 'Reload Now',
};

const ServiceWorkerUpdate: React.FC = () => {
  const classes = useStyles();

  const [showBottomBar, setShowBottomBar] = useState<boolean>(false);
  const [showBottomInstallNotification, setShowBottomInstallNotification] =
    useState<NotificationMode | null>(null);
  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker | null>(null);

  const deferredPrompt = useDeferredInstallPrompt();

  useEffect(() => {
    const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
      //Skipping waiting for SW because skipWaiting is on true for dev on client.config
      if (!isLocalHost) {
        setShowBottomBar(true);
        setWaitingServiceWorker(registration.waiting);
      }
    };

    //Register SW
    RegisterServiceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }, []);

  useEffect(() => {
    // Checks if should display install popup notification
    if (isIOSSafari() && !isInStandaloneMode()) {
      setShowBottomInstallNotification(NotificationMode.IOS_INSTRUCTIONS);
    } else if (deferredPrompt) {
      setShowBottomInstallNotification(NotificationMode.INSTALL_PROMPT);
    } else {
      setShowBottomInstallNotification(null);
    }
  }, [deferredPrompt]);

  const reloadApp = () => {
    swallowExpression(waitingServiceWorker?.postMessage({ type: 'SKIP_WAITING' }));
    setShowBottomBar(false);
    window.location.reload();
  };

  function install() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
  }

  return (
    <>
      {showBottomInstallNotification && (
        <CustomNotification
          mode={showBottomInstallNotification}
          onInstall={install}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={autoHideDurationTime}
        onClose={reloadApp}
        open={showBottomBar}
      >
        <SnackbarContent
          action={
            <Button
              classes={{ root: classes.message }}
              color="inherit"
              onClick={reloadApp}
              size="small"
            >
              {COPY.action}
            </Button>
          }
          classes={{ root: classes.snackbarContent }}
          message={COPY.message}
        />
      </Snackbar>
    </>
  );
};

export default ServiceWorkerUpdate;
