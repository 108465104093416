import * as React from 'react';
import { baseClass, RespondentPlgWrapper } from './plg_page_wrapper';

const COPY = {
  title: 'Is This Safe?',
  privacy: 'Your privacy and security are our top priorities on Polco. ',
  subHeader: {
    main: "Here's how we protect your information and ensure a safe, trustworthy environment:",
    listItems: [
      {
        header: 'Anonymous Interaction: ',
        main: 'Your responses and participation remain anonymous to other users and organizations. Share your honest opinions freely, without privacy concerns.',
      },
      {
        header: 'Secure Information: ',
        main: 'Your personal information, such as name, email, and location, is kept confidential and is used solely to provide a more tailored and influential user experience.',
      },
      {
        header: 'No Data Sharing: ',
        main: 'Rest assured, your individual responses and personal data are not sold or shared with third parties. We value your trust and privacy above all.',
      },
      {
        header: 'Voluntary Information Sharing: ',
        main: 'Choose how much information you share. Even basic details like your ZIP code can enhance your Polco experience while keeping your identity protected',
      },
    ],
  },
  peaceOfMind:
    "At Polco, we believe in the power of community engagement free from the worry of personal data misuse. Log in with peace of mind and start contributing to your community's future today. ",
};

export const IsThisSafePage: React.FC = () => {
  return (
    <RespondentPlgWrapper title={COPY.title}>
      <hr className={`${baseClass}-separator w-100`} />
      <div className="text-gray-40 mb-3 pb-3 font-weight-bold">{COPY.privacy}</div>
      <hr className={`${baseClass}-separator w-100`} />
      <ul className="mb-3 pb-3">
        {COPY.subHeader.listItems.map((item, idx) => {
          return (
            <li key={idx}>
              <span className="font-weight-bold">{item.header}</span>
              {item.main}
            </li>
          );
        })}
      </ul>
      <div className="text-gray-40 font-weight-bold">{COPY.peaceOfMind}</div>
    </RespondentPlgWrapper>
  );
};
