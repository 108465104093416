export const OVERVIEW_COPY = {
  pollSetOverview: 'Poll Set Overview',
  liveEventOverview: 'Live Event Overview',
  reportOverview: 'Report Overview',
  contentPostOverview: 'Content Post Overview',

  overviewDescForSet: 'Below is an overview of all polls in this set',
  overviewDescForReport: 'All sections of the report are listed below',
  viewOutcome: 'View Outcome',

  pollClosed: 'This poll has closed',
  liveClosed: 'This live event has closed',
  surveyClosed: 'This survey has closed',
  contentPostClosed: 'This content post has closed',
};
