import * as React from 'react';
import './styles.scss';
export const BalancingActSimulationIFrame: React.FC<{ readonly url: string }> = (
  p
) => {
  return (
    <iframe
      className="pn-ba-simulation-iframe"
      src={p.url}
      title="balancingActSim"
    />
  );
};
