import { ApolloError, OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql';
import {
  QueryResult,
  SubscriptionHookOptions,
  useSubscription,
} from '@apollo/client/react';
import { UpdateQueryFn } from '@apollo/client/core/watchQueryOptions';

export interface TypedSubscriptionOptions<TVariables> {
  readonly document: DocumentNode;
  readonly variables: TVariables;
}

export interface SubscriptionInfo<TData, TVariables> {
  readonly document: DocumentNode;
  readonly _vars?: TVariables; // never set
  readonly _data?: TData; // never set
  subscribeToMore<TQueryData>(
    queryResult: QueryResult<TQueryData>,
    vars: TVariables,
    callback: UpdateQueryFn<TQueryData, TVariables, TData>
  ): () => void;
}

export function subscriptionInfo<TData, TVariables = OperationVariables>(
  document: DocumentNode
): SubscriptionInfo<TData, TVariables> {
  return {
    document,
    subscribeToMore<TQueryData>(
      result: QueryResult<TQueryData>,
      variables: TVariables,
      callback: UpdateQueryFn<TQueryData, TVariables, TData>
    ) {
      return result.subscribeToMore({
        document,
        variables,
        updateQuery: callback,
      });
    },
  };
}

export interface SubscriptionReturn<TData, TVariables> {
  readonly variables: TVariables | undefined;
  readonly loading: boolean;
  readonly data?: TData | undefined;
  readonly error?: ApolloError | undefined;
}

export function useSubscriptionInfo<TData, TVariables>(
  si: SubscriptionInfo<TData, TVariables>,
  options: SubscriptionHookOptions<TData, TVariables>
): SubscriptionReturn<TData, TVariables> {
  const ret = useSubscription(si.document, options);
  //console.log('useSubscription', { options, ret });
  return ret;
}
