import * as React from 'react';
import './styles.scss';
import { Form } from 'react-bootstrap';
import { useForm, RegisterOptions } from 'react-hook-form';
import {
  AppLink,
  Btn,
  ButtonTypes,
  Well,
  WellType,
} from 'client/shared/components/base';
import { handlePressEnter, ClientUrlUtils } from 'client/shared/core/helpers';
import {
  PasswordResetResult,
  RequestPasswordEvents,
} from 'client/respondent/core/types';
import { MIN_PASSWORD_LENGTH } from 'core';
import { FormPassword } from 'client/shared/components/form-password';
import { RBRef } from 'client/shared/core/types';
import { ACCESS_COPY as copy } from './copy';

export namespace PasswordReset {
  export interface Props {
    readonly events: RequestPasswordEvents;
  }

  export interface Fields {
    readonly password: string;
  }

  type ValidationSet = Record<keyof Fields, RegisterOptions>;
  export const validations: ValidationSet = {
    password: {
      required: {
        value: true,
        message: copy.errorMessages.password.required,
      },
      minLength: {
        value: MIN_PASSWORD_LENGTH,
        message: copy.errorMessages.password.minLength,
      },
    },
  };
}

export const PasswordResetForm: React.FC<PasswordReset.Props> = (props) => {
  const { register, handleSubmit, errors, clearErrors, formState } =
    useForm<PasswordReset.Fields>({
      mode: 'onChange',
    });
  const [error, setError] = React.useState<string | null>(null);

  const submitted = formState.isSubmitted;
  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const submit = async (fields: PasswordReset.Fields) => {
    clearErrors();
    setError(null);
    const result = await props.events.reset(fields);

    if (result === PasswordResetResult.ERROR) {
      setError(copy.errorMessages.server);
    }
    if (result === PasswordResetResult.ERROR_EXPIRED) {
      setError(copy.errorMessages.passwordReset.expiration);
    }
  };
  return (
    <Form
      className="pn-registration-password-reset p-4 mr-auto ml-auto"
      onKeyUp={handlePressEnter(handleSubmit(submit))}
      onSubmit={handleSubmit(submit)}
    >
      <div className="font-weight-bold font-size-lg mb-3">
        {copy.requestPasswordReset.title}
      </div>
      <Form.Row>
        <FormPassword
          createRef={createRef}
          error={errors.password?.message ?? null}
          label={copy.labels.newPassword}
          submitted={submitted}
        />
      </Form.Row>
      <Btn
        action={handleSubmit(submit)}
        className="w-100"
        type={ButtonTypes.PRIMARY}
      >
        {copy.actions.requestPasswordReset}
      </Btn>
      {error && <Well type={WellType.ERROR}>{error}</Well>}
      <div className="mt-3">
        <AppLink
          className="font-size-sm"
          to={ClientUrlUtils.respondent.login.path()}
        >
          {copy.requestPasswordReset.back}
        </AppLink>
      </div>
    </Form>
  );
};
