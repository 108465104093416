import * as React from 'react';
import * as SubscriptionInfos from 'client/shared/graphql-subscriptions/subscription-infos';
import { Splash } from 'client/respondent/voting/shared/components/splash';
import { QueryInfos } from 'client/shared/graphql-queries';
import { VotingProps } from 'client/respondent/core/types';
import { CurrentUser } from 'client/respondent/hooks';
import { useQueryInfo } from 'client/shared/containers/query';

import { match, Redirect, Switch, useRouteMatch } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import {
  ApiDate,
  ExtractGql,
  hasProperty,
  QuestionSetType,
  RespondentsSetStatus,
  wrap,
} from 'core';

import { ClientUrlUtils } from 'client/shared/core/helpers';

import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { RespondentVotingPage } from 'client/shared/graphql-client/graphql-operations.g';
import { PolcoLiveWaitingPage } from '../polco-live-waiting';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { PolcoLiveSharedHeader } from '../../components/live-stream';
import { useSubscriptionInfo } from 'client/shared/containers/subscription';
import {
  RedirectFunction,
  useCurrentTime,
  useLanguageByQueryParam,
  useRedirect,
} from 'client/shared/hooks';
import { QuestionVotingPage } from '../../../shared/pages/question-voting';
import { nth, isEqual } from 'lodash';
import { ClientQuestionId, QuestionStatus } from 'client/shared/core/question';
import { WithDotsAction } from 'client/respondent/voting/shared/containers/dots-actions';
import { Helmet } from 'react-helmet';

type SetForRespondentVote = ExtractGql<
  NonNullable<RespondentVotingPage['openContentSetBySlug']>,
  'PolcoLive'
>['setForRespondentVote'];

enum SharedComponent {
  SPLASH = 'SPLASH',
  VOTING_HEADER_VIDEO = 'VOTING_HEADER_VIDEO',
}
interface SetRouteSlugs {
  readonly pubSlug?: string;
  readonly setSlug: string;
}

export type Props = RouteComponentProps<SetRouteSlugs>;
export const PolcoLivePage: React.FC<Props> = (p) => {
  const questionMatch = useRouteMatch<{ readonly questionId?: string }>(
    ClientUrlUtils.respondent.question.path({
      pubSlug: ':pubSlug?',
      setIdOrSlug: ':setSlug',
      questionId: ':questionId',
      setType: QuestionSetType.POLCO_LIVE,
    })
  );
  const questionId = questionMatch?.params.questionId ?? null;
  const redirect = useRedirect();
  const goBack =
    p.history.length > 1
      ? p.history.goBack
      : () => {
          redirect(ClientUrlUtils.respondent.feed.path(), { push: false });
        };
  const goToFeed = () =>
    redirect(ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });

  return (
    <MainPage hideTopNav={true} showTermsAndPrivacyDesktop>
      {(respondentUser, showingSidebars) => {
        return (
          <PolcoLivePageInner
            animateDirection={showingSidebars ? 'slideInUp' : 'slideInRight'}
            curUser={respondentUser}
            goBack={goBack}
            goToFeed={goToFeed}
            match={p.match}
            questionId={questionId}
            redirect={redirect}
            showingSidebars={showingSidebars}
            slugs={p.match.params}
          />
        );
      }}
    </MainPage>
  );
};

const PolcoLivePageInner: React.FC<{
  readonly match: match<SetRouteSlugs>;
  readonly slugs: SetRouteSlugs;
  readonly questionId: ClientQuestionId | null;
  readonly curUser: CurrentUser | null;
  readonly animateDirection: 'slideInRight' | 'slideInUp';
  readonly goBack: () => void;
  readonly goToFeed: () => void;
  readonly showingSidebars: boolean;
  readonly redirect: RedirectFunction;
}> = (p) => {
  const [hideStream, setHideStream] = React.useState<boolean>(false);

  const handleShowOrHideStream = () => {
    setHideStream(!hideStream);
  };
  const resp = p.curUser?.user?.respondent ?? null;
  const respId = resp?.id ?? null;

  const setSlug = p.slugs.setSlug;
  const pubSlug = p.slugs.pubSlug ?? 'by-id'; // not actually used, but helps clarify the code here

  const time = useCurrentTime({ frequencyMillis: 1000 });
  const redirect = useRedirect();
  const [loadedDataCount, setLoadedDataCount] = React.useState(0);

  const query = useQueryInfo(QueryInfos.respondentVotingPage, {
    variables: {
      questionSetSlug: setSlug,
      publishingEntitySlug: pubSlug,
      respondentId: respId,
    },
    onCompleted: (d) => {
      if (d?.openContentSetBySlug) {
        setLoadedDataCount(loadedDataCount + 1);
      }
    },
  });
  const isInitialLoad = loadedDataCount === 1;

  // to update Splash page when a resp is waiting at the Splash page and an admin changes the date
  const contentSetId = query.data?.openContentSetBySlug?.id;
  useSubscriptionInfo(SubscriptionInfos.liveContentSet, {
    skip: !contentSetId,
    variables: contentSetId ? { contentSetId, respondentId: respId } : undefined,
  });

  const queryData = query.data?.openContentSetBySlug;
  const questionId = p.questionId;
  const navPercentage = React.useMemo(
    () => calculateNavPercentage(queryData, questionId),
    [queryData, questionId]
  );
  const { selectLanguageText } = useLanguageByQueryParam();

  if (query.loading && !query.data) {
    return <RespondentPageLoader />;
  }

  if (!(queryData?.__typename === 'PolcoLive')) {
    switch (queryData?.__typename) {
      case 'Survey': {
        return (
          <Redirect
            to={ClientUrlUtils.respondent.set.path({
              setIdOrSlug: queryData.slug ?? queryData.id,
              pubSlug: queryData.publishingEntity.slug,
              setType: QuestionSetType.SURVEY,
            })}
          />
        );
      }
      case 'QuestionSet': {
        return (
          <Redirect
            to={ClientUrlUtils.respondent.set.path({
              setIdOrSlug: queryData.slug ?? queryData.id,
              pubSlug: queryData.publishingEntity.slug,
              setType: QuestionSetType.SET,
            })}
          />
        );
      }
      default: {
        return <Redirect to={ClientUrlUtils.respondent.notFound.path()} />;
      }
    }
  }

  const { totalRespondents, setForRespondentVote: questionSetData } = queryData;
  const numOfQuestions = questionSetData.questions.length ?? 0;

  const image = queryData.image;

  let status = questionSetData.status;
  if (status === RespondentsSetStatus.NOT_STARTED) {
    const startDate = ApiDate.fromApi(queryData.settings.eventStartDate);
    if (startDate && startDate.getTime() < time.getTime()) {
      status = RespondentsSetStatus.START;
    }
  }
  const firstOpenUnansweredQuestionId: ClientQuestionId | null =
    questionSetData.questions.find(
      (q) =>
        q.schedule.status === QuestionStatus.PUBLISHED &&
        (q.schedule.closeDate === null ||
          q.schedule.closeDate.raw > time.getTime()) &&
        !q.previousVote
    )?.id || null;
  if (
    p.match.isExact && // splash screen
    isInitialLoad &&
    numOfQuestions > 0 &&
    status === RespondentsSetStatus.START &&
    hasProperty(p.slugs, 'pubSlug')
  ) {
    return redirectIntoFirstEventPage(p.slugs, firstOpenUnansweredQuestionId);
  }

  const videoLink = queryData.settings.liveVideoLink;
  const publishingEntity = query.data?.contextPublishingEntity;
  const index = questionSetData.questions.findIndex((q) => q.id === p.questionId);
  const currentIndex = Math.max(0, index);
  const prevQuestionId =
    currentIndex === 0
      ? null
      : nth(questionSetData.questions, currentIndex - 1)?.id ?? null;
  const firstUnansweredQuestionId: ClientQuestionId | null =
    questionSetData.questions.find((q) => !q.previousVote)?.id || null;
  const hasOutcomeAndClosed =
    !!queryData.outcome &&
    queryData.setForRespondentVote.status === RespondentsSetStatus.CLOSED;

  const handleGoToPreviousQuestion = prevQuestionId
    ? () => {
        redirect(
          ClientUrlUtils.respondent.question.path({
            questionId: prevQuestionId,
            pubSlug,
            setIdOrSlug: setSlug,
            setType: QuestionSetType.POLCO_LIVE,
          }),
          {
            push: true,
          }
        );
      }
    : undefined;

  const handleGoToOverview = () => {
    redirect(
      ClientUrlUtils.respondent.setOverview.path({
        pubSlug,
        setIdOrSlug: setSlug,
      }),
      {
        push: true,
      }
    );
  };

  const goToOutcome = () =>
    redirect(
      ClientUrlUtils.respondent.setOutcome.path({
        pubSlug: pubSlug,
        setIdOrSlug: setSlug,
      }),
      {
        push: true,
      }
    );
  if (!publishingEntity) {
    return <RespondentPageLoader />;
  }

  const eventDate = ApiDate.fromApi(queryData.settings.eventStartDate) ?? undefined;
  const componentToRender = getShareComponent(p.match);
  const shareUrl = questionId
    ? ClientUrlUtils.respondent.question.url({
        questionId: questionId,
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
        setType: QuestionSetType.POLCO_LIVE,
      })
    : null;
  const voted: boolean = questionSetData.questions.every((q) => !!q.previousVote);

  return (
    <div>
      <Helmet>
        <title>
          {selectLanguageText(queryData.name)} -{' '}
          {selectLanguageText(queryData.publishingEntity.name)} - Polco
        </title>
      </Helmet>
      {wrap(() => {
        switch (componentToRender) {
          case SharedComponent.SPLASH:
            return (
              <Splash
                backgroundColor={image?.backgroundColor ?? null}
                eventDate={eventDate}
                events={{
                  start: () => {
                    if (hasOutcomeAndClosed) {
                      goToOutcome();
                    } else {
                      redirectFromSplash(
                        questionSetData,
                        p.redirect,
                        setSlug,
                        pubSlug,
                        firstUnansweredQuestionId
                      )();
                    }
                  },

                  goBack: p.goBack,
                  goToFeed: p.goToFeed,
                }}
                hasOutcomeAndClosed={hasOutcomeAndClosed}
                imageUrl={image?.verticalUrl ?? null}
                numOfQuestions={numOfQuestions}
                setType={VotingProps.SetType.POLCO_LIVE}
                status={status}
                title={selectLanguageText(queryData.name)}
                totalRespondents={totalRespondents ?? 0}
              />
            );

          case SharedComponent.VOTING_HEADER_VIDEO:
            return (
              <WithDotsAction
                isGuest={!resp || (!!resp?.guest && !!respId)}
                publisherId={publishingEntity.id}
                publisherName={selectLanguageText(publishingEntity.name)}
                respondentId={respId}
                shareUrl={shareUrl}
                subscriptionType={publishingEntity.subscriptionType}
              >
                {({ executePublisherAction, executeShareModalAction }) => (
                  <PolcoLiveSharedHeader
                    events={{
                      showOrHideStream: handleShowOrHideStream,
                      goToFeed: p.goToFeed,
                      goBack: p.goBack,
                      goToOverview: handleGoToOverview,
                      goToPreviousQuestion: handleGoToPreviousQuestion,
                      goToPublishingEntity: () => {
                        redirect(
                          ClientUrlUtils.respondent.pubProfile.path({
                            slug: pubSlug,
                          }),
                          {
                            push: true,
                          }
                        );
                      },
                      openShareModal: () => executeShareModalAction(true),
                      publisherAction: executePublisherAction,
                    }}
                    isEventCurrentlyRunning={status === RespondentsSetStatus.START}
                    isHidden={hideStream}
                    liveEventEnded={status === RespondentsSetStatus.CLOSED}
                    videoLink={videoLink}
                    votingHeaderInfo={{
                      percentage: navPercentage,
                      pubName: selectLanguageText(publishingEntity.name),
                      logo: publishingEntityPrimaryLogoUrl(publishingEntity.assets),
                      subscriptionType: publishingEntity.subscriptionType,
                      voted,
                    }}
                  />
                )}
              </WithDotsAction>
            );
        }
      })}

      <Switch key={p.match.path}>
        <Route
          exact
          path={ClientUrlUtils.respondent.polcoLiveWaiting.path({
            pubSlug: ':pubSlug?',
            setIdOrSlug: ':setSlug',
          })}
          render={(props) => (
            <PolcoLiveWaitingPage {...props} respondentUser={p.curUser} />
          )}
        />
        <Route
          exact
          path={ClientUrlUtils.respondent.question.path({
            pubSlug: ':pubSlug?',
            setIdOrSlug: ':setSlug',
            questionId: ':questionId',
            setType: QuestionSetType.POLCO_LIVE,
          })}
          render={(props) => {
            return (
              <QuestionVotingPageWrapper
                {...props}
                isPolcoLive={true}
                respondent={p.curUser}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

function calculateNavPercentage(
  queryData: RespondentVotingPage['openContentSetBySlug'] | null | undefined,
  questionId: ClientQuestionId | null
) {
  const setData =
    queryData?.__typename === 'QuestionSet' || queryData?.__typename === 'PolcoLive'
      ? queryData.setForRespondentVote
      : null;
  const questionsLength = setData?.questions.length ?? 0;
  const index = setData?.questions.findIndex((q) => q.id === questionId) ?? -1;
  const currentIndex = Math.max(0, index);
  const percentage = ((currentIndex + 1) / questionsLength) * 100;
  return percentage;
}

function redirectFromSplash(
  questionSetData: SetForRespondentVote,
  redirect: RedirectFunction,
  setSlug: string,
  pubSlug: string,
  firstUnansweredQuestionId: ClientQuestionId | null
) {
  return () => {
    // If event is closed, go to first question
    if (questionSetData.status === RespondentsSetStatus.CLOSED) {
      return redirect(
        ClientUrlUtils.respondent.question.path({
          questionId: questionSetData.questions[0].id,
          setIdOrSlug: setSlug,
          pubSlug,
          setType: QuestionSetType.POLCO_LIVE,
        }),
        {
          push: true,
        }
      );
    } else if (firstUnansweredQuestionId) {
      // Else, we want to start you at your first unanswered question or the waiting page if there is no unanswered question

      return redirect(
        ClientUrlUtils.respondent.question.path({
          questionId: firstUnansweredQuestionId,
          setIdOrSlug: setSlug,
          pubSlug,
          setType: QuestionSetType.POLCO_LIVE,
        }),
        {
          push: true,
        }
      );
    } else {
      return redirect(
        ClientUrlUtils.respondent.polcoLiveWaiting.path({
          setIdOrSlug: setSlug,
          pubSlug,
        }),
        {
          push: true,
        }
      );
    }
  };
}

function getShareComponent(m: match<SetRouteSlugs>) {
  if (m.isExact) {
    return SharedComponent.SPLASH;
  } else {
    return SharedComponent.VOTING_HEADER_VIDEO;
  }
}

export function redirectIntoFirstEventPage(
  slugs: Required<SetRouteSlugs>,
  questionId: ClientQuestionId | null
) {
  if (questionId) {
    return (
      <Redirect
        to={ClientUrlUtils.respondent.question.path({
          questionId: questionId,
          setIdOrSlug: slugs.setSlug,
          pubSlug: slugs.pubSlug,
          setType: QuestionSetType.POLCO_LIVE,
        })}
      />
    );
  } else {
    return (
      <Redirect
        to={ClientUrlUtils.respondent.polcoLiveWaiting.path({
          setIdOrSlug: slugs.setSlug,
          pubSlug: slugs.pubSlug,
        })}
      />
    );
  }
}

const QuestionVotingPageWrapper = React.memo(QuestionVotingPage, isEqual);
