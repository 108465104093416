import * as React from 'react';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';
import { CurrentUser } from 'client/respondent/hooks/use-respondent';
import { useLanguageByQueryParam, useRedirect } from 'client/shared/hooks';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { PolcoLiveThankYou } from 'client/respondent/voting/polco-live/components/polco-live-thank-you';
import { VotingHeader } from '../../../shared/components/header';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { LoaderBars } from 'client/shared/components/loader-bars';
import { RespondentsSetStatus } from 'core';
import { LearnMoreAboutPolcoPrompt } from 'client/respondent/shared/components/learn-more-about-polco-prompt';

export type Props = RouteComponentProps<{
  readonly setSlug: string;
  readonly pubSlug?: string;
}>;

function questionSetSlugFromParamsProps(props: Props): string {
  return props.match.params.setSlug;
}
function pubSlugFromParamsProps(props: Props): string | undefined {
  return props.match.params.pubSlug;
}

export const PolcoLiveThankYouPage: React.FC<Props> = (p: Props) => {
  const setSlug = questionSetSlugFromParamsProps(p);
  const pubSlug = pubSlugFromParamsProps(p);

  return (
    <MainPage hideTopNav showTermsAndPrivacyDesktop>
      {(respondentUser) => (
        <PolcoLiveThankYouInner
          pubSlug={pubSlug ?? 'by-id'}
          respondentUser={respondentUser}
          setSlug={setSlug}
        />
      )}
    </MainPage>
  );
};

const PolcoLiveThankYouInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
  readonly pubSlug: string;
  readonly setSlug: string;
}> = (p) => {
  const redirect = useRedirect();
  const history = useHistory();
  const respId = p.respondentUser?.user?.respondent?.id ?? null;
  const isGuest = p.respondentUser?.user?.respondent?.guest ?? true;

  const [showOnboarding, setShowOnboarding] = React.useState<boolean>(false);

  const query = useQueryInfo(QueryInfos.respondentPolcoLiveBase, {
    variables: {
      questionSetSlug: p.setSlug,
      publishingEntitySlug: p.pubSlug,
      respondentId: respId,
    },
  });
  const { selectLanguageText } = useLanguageByQueryParam();

  const publishingEntity = query.data?.contextPublishingEntity;
  if (!publishingEntity) {
    return <LoaderBars />;
  }

  if (!(query.data?.openContentSetBySlug?.__typename === 'PolcoLive')) {
    return <Redirect to={'/res/not-found'} />;
  }

  const status = query.data.openContentSetBySlug.setForRespondentVote.status;

  const goToPublishingEntity = () => {
    redirect(
      ClientUrlUtils.respondent.pubProfile.path({
        slug: p.pubSlug,
      }),
      {
        push: true,
      }
    );
  };

  const goToFeed = () => {
    redirect(ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });
  };
  const goBack =
    history.length > 1
      ? history.goBack
      : () => {
          redirect(ClientUrlUtils.respondent.feed.path(), { push: true });
        };

  return (
    <>
      <h1 className="d-none">Live Event page</h1>
      <VotingHeader
        events={{
          goToFeed,
          goBack,
          goToPublishingEntity,
        }}
        isEventCurrentlyRunning={status === RespondentsSetStatus.START}
        navPercentage={100}
        primaryLogo={publishingEntityPrimaryLogoUrl(publishingEntity.assets)}
        publisherName={selectLanguageText(publishingEntity.name)}
        shareable={true}
      />
      {showOnboarding && (
        <LearnMoreAboutPolcoPrompt onClose={() => setShowOnboarding(false)} />
      )}
      <PolcoLiveThankYou
        events={{
          learnMore: () => setShowOnboarding(true),
          goToFeed: () =>
            redirect(ClientUrlUtils.respondent.feed.path(), {
              push: true,
            }),
        }}
        isGuest={isGuest}
      />
    </>
  );
};
