import * as React from 'react';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { SUBSCRIPTIONS_COPY } from './copy';
import { FollowingCard, CardType } from '../following-card';
import {
  SubscriptionsTabs,
  FollowingEvent,
  FollowingPublisher,
} from 'client/respondent/core/types';

export interface FollowingProps {
  readonly publishers: readonly FollowingPublisher[];
  readonly event: FollowingEvent | null;
  readonly goToSearchTab: (t: SubscriptionsTabs) => void;
}

const baseClass = 'pn-subscriptions';
export const Following: React.FC<FollowingProps> = (props) => {
  const { publishers, event, goToSearchTab } = props;

  return (
    <div className="bg-white d-flex flex-column">
      <div className="mt-2 px-3 py-1 font-weight-bold font-size-lg">
        Following{' '}
        <span className={`${baseClass}-following-number font-size-xs text-gray-50`}>
          {publishers.filter((p) => p.currentRespondentFollowing).length}
        </span>
      </div>
      {publishers.map((p) => {
        return (
          <FollowingCard
            cardType={
              p.currentRespondentFollowing ? CardType.FOLLOWING : CardType.FOLLOW
            }
            event={event}
            key={p.name}
            publisher={p}
          />
        );
      })}

      <div className="d-flex flex-column justify-content-center align-items-center my-3 py-3">
        <div className="text-gray-50">{SUBSCRIPTIONS_COPY.searchMore}</div>

        <Btn
          action={() => goToSearchTab(SubscriptionsTabs.SEARCHING)}
          type={ButtonTypes.SEAMLESS}
        >
          {SUBSCRIPTIONS_COPY.searchMoreLink}
        </Btn>
      </div>
    </div>
  );
};
