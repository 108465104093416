export const COPY = {
  pageTitle: 'New Idea',
  publishingEntitySelector: 'Where would you like to post this idea?',
  publishingEntityRequiredMessage: 'A community has to be chosen',
  title: 'Title',
  titlePlaceholder: 'Enter a descriptive title',
  titleRequiredMessage: 'A title is required',
  titleMinLengthtMessage: 'Minimum 15 characters',
  titleMaxLengthtMessage: 'Maximum 140 characters',
  description: 'Details (Optional)',
  descriptionPlaceholder: 'Include any additional relevant details',
  submitIdea: 'Submit Idea',
  confirmationModal: {
    closeButton: 'Cancel',
    title: '',
    body: 'Are you sure you want to exit?',
    actions: {
      noStay: 'No, stay',
      yesExit: 'Yes, exit',
    },
  },
} as const;
