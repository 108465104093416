import * as React from 'react';
import { RespondentPlgWrapper } from './plg_page_wrapper';

const COPY = {
  title: 'What Kind of Data?',
  insights: {
    header: 'See Insights in Action: ',
    main: 'On Polco, your input transforms into actionable insights for community improvement. Wondering what this means for you and your neighborhood?',
  },
  lists: [
    {
      main: "Let's dive in:",
      listItems: [
        {
          header: 'Community Feedback: ',
          main: 'Through polls and surveys, you can express your opinions on various local issues, from park renovations to traffic management. This feedback is aggregated and shared with decision-makers to guide their planning and actions.',
        },
        {
          header: 'Data-Driven Decisions: ',
          main: "Your responses help form a clearer picture of community needs and preferences. This data informs local government strategies, ensuring they align with residents' actual desires and concerns.",
        },
        {
          header: 'Visual Insights: ',
          main: 'On your dashboard, explore results from past surveys and polls, displayed in easy-to-understand formats. See how your community feels about different topics and how your views compare.',
        },
      ],
    },
    {
      main: 'As a resident, your participation shapes:',
      listItems: [
        {
          header: 'Targeted Content: ',
          main: "Subscribe to specific organization feeds, from local government initiatives to school board updates, ensuring you're always informed and engaged with matters close to your heart.",
        },
        {
          header: 'Tailored Insights: ',
          main: 'As a logged-in user, your feedback carries more weight, providing you with more specific insights and ensuring your voice is heard clearly in community planning.',
        },
        {
          header: 'Real-Time Updates: ',
          main: 'Follow ongoing projects and engage with real-time content to stay informed and contribute your views on the developments affecting your area.',
        },
        {
          header: 'Transparent Results: ',
          main: 'View the outcomes and impacts of your participation through shared results from surveys, polls, and other engagement tools.',
        },
      ],
    },
    {
      main: 'Here’s how you can make an impact:',
      listItems: [
        {
          header: 'Focused Content: ',
          main: "Customize which organization feeds you receive - whether it's your city council, school board, or neighborhood association - so you're always informed and ready to provide meaningful feedback.",
        },
        {
          header: 'Enhanced Engagement: ',
          main: 'By logging in, you ensure your opinions are given greater consideration, providing more accurate and influential feedback for community decisions.',
        },
        {
          header: 'Stay Updated: ',
          main: 'Follow projects and initiatives relevant to your community, and participate in the dialogue through surveys and polls to help guide their direction.',
        },
        {
          header: 'Transparency and Feedback: ',
          main: "See how your and others' contributions are influencing community decisions, with organizations often sharing outcomes and thanking participants.",
        },
      ],
    },
  ],
  participation:
    'Your active participation on Polco does more than just express your opinion; it helps shape the community where you live. Get involved and see the difference you can make!',
};

export const WhatKindOfDataPage: React.FC = () => {
  return (
    <RespondentPlgWrapper title={COPY.title}>
      <div className="text-gray-40 mb-3 pb-3">
        <span className="font-weight-bold">{COPY.insights.header}</span>
        {COPY.insights.main}
      </div>
      {COPY.lists.map((l, idx) => {
        return (
          <div key={idx}>
            <div className="text-gray-40 mb-3 pb-3 font-weight-bold">{l.main}</div>
            <ul className="mb-3 pb-3">
              {l.listItems.map((item) => {
                return (
                  <li key={item.header}>
                    <span className="font-weight-bold">{item.header}</span>
                    {item.main}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      <div className="text-gray-40 font-weight-bold">{COPY.participation}</div>
    </RespondentPlgWrapper>
  );
};
