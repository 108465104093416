import { Btn, ButtonTypes } from 'client/shared/components/base';
import { RichText } from 'client/shared/components/rich-text';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { ClientUrlUtils, handlePressEnter } from 'client/shared/core/helpers';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useRedirect } from 'client/shared/hooks';
import _ from 'lodash';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';

import { COPY } from './copy';
import { PublishingEntitySelector } from '../../components';

export namespace IdeaForm {
  export interface Fields {
    readonly publishingEntityId: string | null;
    readonly title: string | null;
    readonly description: string | null;
  }

  type ValidationSet = Record<keyof Fields, RegisterOptions>;
  export const validations: ValidationSet = {
    publishingEntityId: {
      required: { value: true, message: COPY.publishingEntityRequiredMessage },
      setValueAs: setEmptyOrStr,
      validate: (value) =>
        !!value.trim() ? undefined : COPY.publishingEntityRequiredMessage,
    },
    title: {
      required: { value: true, message: COPY.titleRequiredMessage },
      minLength: { value: 15, message: COPY.titleMinLengthtMessage },
      maxLength: { value: 140, message: COPY.titleMaxLengthtMessage },
      setValueAs: setEmptyOrStr,
      validate: (value) => (!!value.trim() ? undefined : COPY.titleRequiredMessage),
    },
    description: {},
  };
}

export const CreateIdeaForm: React.FC<{
  readonly className?: string;
  readonly respondentId: string;
}> = (props) => {
  const { control, handleSubmit, formState, errors, register } =
    useForm<IdeaForm.Fields>({
      mode: 'onChange',
      defaultValues: {
        publishingEntityId: null,
        title: null,
        description: null,
      },
    });

  const redirect = useRedirect();

  const { fn: createIdea } = useMutationInfo(MutationInfos.createIdea);

  async function onSubmit({
    publishingEntityId,
    title,
    description,
  }: IdeaForm.Fields) {
    if (!publishingEntityId?.trim()) {
      throw new Error(COPY.publishingEntityRequiredMessage);
    }
    if (!title?.trim()) {
      throw new Error(COPY.titleRequiredMessage);
    }

    await createIdea({
      variables: {
        input: {
          publishingEntityId,
          title,
          description,
        },
      },
    });

    redirect(ClientUrlUtils.respondent.feed.path(), { push: true });
  }

  return (
    <Form
      className={`${props.className}-form`}
      onKeyUp={handlePressEnter(handleSubmit(onSubmit))}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group>
        <Form.Label
          className="font-weight-bold font-size-sm mb-2"
          htmlFor={'publishingEntityId'}
        >
          {COPY.publishingEntitySelector}
        </Form.Label>

        <Controller
          aria-label={COPY.publishingEntitySelector}
          aria-placeholder={COPY.publishingEntitySelector}
          control={control}
          isInvalid={!!errors['publishingEntityId']}
          name={'publishingEntityId'}
          render={({ onChange }) => {
            return (
              <Form.Group>
                <PublishingEntitySelector
                  onSelect={onChange}
                  respondentId={props.respondentId}
                />
              </Form.Group>
            );
          }}
          rules={IdeaForm.validations['publishingEntityId'] ?? {}}
        />

        <Form.Control.Feedback
          className={formState.isSubmitted ? 'd-block' : ''}
          type="invalid"
        >
          {errors['publishingEntityId']?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label className="font-weight-bold font-size-sm mb-2" htmlFor={'title'}>
          {COPY.title}
        </Form.Label>

        <Form.Control
          aria-label={COPY.title}
          aria-placeholder={COPY.titlePlaceholder}
          className={`${props.className}-form-title rounded`}
          isInvalid={!!errors['title']}
          name={'title'}
          placeholder={COPY.titlePlaceholder}
          ref={register(IdeaForm.validations['title'] ?? {})}
        />
        <Form.Control.Feedback
          className={formState.isSubmitted ? 'd-block' : ''}
          type="invalid"
        >
          {errors['title']?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label
          className="font-weight-bold font-size-sm mb-2"
          htmlFor={'publishingEntityId'}
        >
          {COPY.description}
        </Form.Label>

        <Controller
          aria-label={COPY.description}
          aria-placeholder={COPY.descriptionPlaceholder}
          control={control}
          isInvalid={!!errors['description']}
          name={'description'}
          placeholder={COPY.descriptionPlaceholder}
          render={({ onChange, value }) => {
            return (
              <Form.Group>
                <RichText
                  allowCode={true}
                  customHeight={'300px'}
                  id={`${props.className}-form-description`}
                  onChange={onChange}
                  value={value ?? ''}
                />
              </Form.Group>
            );
          }}
          rules={IdeaForm.validations['description'] ?? {}}
        />

        <Form.Control.Feedback
          className={formState.isSubmitted ? 'd-block' : ''}
          type="invalid"
        >
          {errors['description']?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Btn
        action={handleSubmit((data) => onSubmit(data))}
        className={`${props.className}-form-button w-100`}
        disabled={!formState.isValid || formState.isSubmitting}
        type={ButtonTypes.PRIMARY}
      >
        {COPY.submitIdea}
      </Btn>
    </Form>
  );
};

CreateIdeaForm.displayName = 'CreateIdeaForm';

function setEmptyOrStr(v: unknown) {
  if (_.isString(v) && _.isEmpty(v)) return undefined;
  else return v;
}
