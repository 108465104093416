import { App } from 'client/shared/components/app';
import { getConfig } from 'client/shared/core/config';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { QuestionSetType } from 'core';
import * as React from 'react';
import { Switch, match as Match } from 'react-router';
import { Route } from 'react-router-dom';

import { LoginPage } from './account/pages/access/login';
import { RequestResetPasswordPage } from './account/pages/access/reset-password/request-reset';
import { ResetPasswordPage } from './account/pages/access/reset-password/reset';
import { SignupPage } from './account/pages/access/signup';
import { RespondentAccountPage } from './account/pages/respondent-account';
import { FeedPage } from './feed/pages/feed';
import { PublisherProfilePage } from './feed/pages/publisher-profile';
import { CreateIdeaPage } from './residents-input/pages/create-idea';
import ServiceWorkerUpdate from './shared/components/service-worker-update';
import { BadShortUrl } from './shared/pages/bad-short-url';
import { NotAvailable } from './shared/pages/not-available';
import { NotFound } from './shared/pages/not-found';
import { SubscriptionsPage } from './subscriptions/pages/subscriptions';
import {
  BalancingActSimulationPage,
  Props as BalancingActSimulationPageProps,
} from './voting/balancing-act/pages/simulation';
import { SetOutcomePage } from './voting/outcome/pages/outcome';
import { SetOverviewPage } from './voting/overview/pages/overview';
import { PolcoLivePage } from './voting/polco-live/pages/polco-live';
import { PolcoLiveThankYouPage } from './voting/polco-live/pages/polco-live-thank-you';
import { QuestionVotingPage } from './voting/shared/pages/question-voting';
import { VotingPage } from './voting/shared/pages/voting-wrapper';
// import { useRespondentAppcues } from './hooks/use-respondent-appcues';
import { ViewIdeaPage } from './residents-input/pages/view-idea';
import { WhatIsPolcoPage } from './plg/pages/what_is_polco';
import { WhatKindOfDataPage } from './plg/pages/what_kind_of_data';
import { IsThisSafePage } from './plg/pages/is_this_safe';

const config = getConfig();

const RespondentUrlUtils = ClientUrlUtils.respondent;

export const Respondent: React.FC = () => {
  // useRespondentAppcues();

  return (
    <App>
      <Route path="/res">
        {({ match }: { match: Match }) => {
          return (
            <>
              {process.env.NODE_ENV !== 'test' ? <ServiceWorkerUpdate /> : null}
              <Routes path={match?.path} />
            </>
          );
        }}
      </Route>
    </App>
  );
};

function Routes(p: { readonly path: string | undefined }) {
  const isResidentIdeasEnabled = config.envFeatures.enableResidentIdeas;
  return (
    <Switch>
      <Route component={FeedPage} exact path={RespondentUrlUtils.feed.path()} />
      <Route
        component={VotingPage}
        exact
        path={RespondentUrlUtils.set.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
          setType: QuestionSetType.SET,
        })}
      />
      <Route
        component={VotingPage}
        exact
        path={RespondentUrlUtils.set.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
          setType: QuestionSetType.SURVEY,
        })}
      />
      <Route
        component={QuestionVotingPage}
        exact
        path={RespondentUrlUtils.question.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
          questionId: ':questionId',
          setType: QuestionSetType.SET,
        })}
      />

      <Route
        component={SetOutcomePage}
        exact
        path={RespondentUrlUtils.setOutcome.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
        })}
      />
      <Route
        // no exact because it's nested routes
        component={SetOverviewPage}
        path={RespondentUrlUtils.setOverview.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
        })}
      />
      <Route
        // no exact because it's nested routes
        component={PolcoLivePage}
        path={RespondentUrlUtils.set.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
          setType: QuestionSetType.POLCO_LIVE,
        })}
      />
      <Route
        component={PolcoLiveThankYouPage}
        exact
        path={RespondentUrlUtils.polcoLiveThankYou.path({
          pubSlug: ':pubSlug?',
          setIdOrSlug: ':setSlug',
        })}
      />
      <Route
        component={BalancingActSimulationPage}
        exact
        path={RespondentUrlUtils.balancingActSimulation.path({
          simulationId: ':simulationId',
        })}
      />
      <Route
        component={(props: BalancingActSimulationPageProps) => (
          <BalancingActSimulationPage {...props} isTaxReceipt={true} />
        )}
        exact
        path={RespondentUrlUtils.balancingActSimulation.path({
          simulationId: ':simulationId',
          isTaxReceipt: true,
        })}
      />
      <Route component={LoginPage} exact path={`${p.path}/login`} />
      <Route
        component={RequestResetPasswordPage}
        exact
        path={`${p.path}/reset-password-request`}
      />

      <Route component={ResetPasswordPage} exact path={`${p.path}/reset-password`} />
      <Route component={SignupPage} exact path={`${p.path}/signup`} />
      <Route
        component={PublisherProfilePage}
        exact
        path={RespondentUrlUtils.pubProfile.path({ slug: ':pubSlug' })}
      />
      <Route component={RespondentAccountPage} exact path={`${p.path}/account`} />
      <Route component={SubscriptionsPage} exact path={`${p.path}/subscriptions`} />
      <Route
        component={WhatIsPolcoPage}
        exact
        path={RespondentUrlUtils.whatIsPolco.path()}
      />
      <Route
        component={WhatKindOfDataPage}
        exact
        path={RespondentUrlUtils.whatKindOfData.path()}
      />
      <Route
        component={IsThisSafePage}
        exact
        path={RespondentUrlUtils.isThisSafe.path()}
      />

      {isResidentIdeasEnabled ? (
        <Route
          component={CreateIdeaPage}
          exact
          path={RespondentUrlUtils.createIdea.path()}
        />
      ) : null}
      {isResidentIdeasEnabled ? (
        <Route
          component={ViewIdeaPage}
          exact
          path={RespondentUrlUtils.viewIdea.path(':id')}
        />
      ) : null}
      <Route
        component={BadShortUrl}
        exact
        path={`${p.path}/short-url-help/:shortId`}
      />

      <Route component={NotAvailable} exact path={`${p.path}/not-available`} />
      {/* NOTE: Could filter these paths by env var, but they won't get
        tree-shaken out then */
      /* <Route path={`${p.path}/internal-test`}>
        {({ match }) => {
          return <InternalTests path={match?.path ?? ''} />;
        }}
      </Route> */}
      <Route component={NotFound} />
    </Switch>
  );
}
