import * as React from 'react';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { ClientRespondentId } from 'client/respondent/core';
import {
  RespondentAccount,
  GenderCategories,
  CommentVisibility,
  InterestTopic,
  ProfileFields,
} from 'client/respondent/shared/account/components/account';
import { useQueryInfo } from 'client/shared/containers/query';
import { QueryInfos } from 'client/shared/graphql-queries';
import { Redirect } from 'react-router';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { UnsubscribeConfirm } from 'client/respondent/shared/components/unsubscribe-confirm-modal';
import { GqlError, PolcoGqlError, PolcoGqlErrors, Result } from 'core';

export const RespondentAccountPage: React.FC = () => {
  return (
    <MainPage>
      {(currentUser) => {
        return currentUser?.user?.respondent?.id &&
          !currentUser?.user?.respondent?.guest ? (
          <div>
            <RespondentAccountPageInner respId={currentUser.user.respondent.id} />
            <UnsubscribeConfirm />
          </div>
        ) : (
          <Redirect push={true} to={ClientUrlUtils.respondent.feed.path()} />
        );
      }}
    </MainPage>
  );
};
RespondentAccountPage.displayName = 'RespondentAccountPage';

const RespondentAccountPageInner: React.FC<{
  readonly respId: ClientRespondentId;
}> = (p) => {
  const query = useQueryInfo(QueryInfos.respondentProfile, {
    variables: {
      respondentId: p.respId,
    },
  });
  const updateProfileMut = useMutationInfo(MutationInfos.updateRespondentProfile);

  if (query.loading) {
    return <RespondentPageLoader />;
  }

  const updateProfile = async (
    fields: ProfileFields
  ): Promise<
    Result.Type<
      unknown,
      GqlError<PolcoGqlError<PolcoGqlErrors.ClientUpdateProfileFailureReason>>
    >
  > => {
    return Result.fromPromise<
      unknown,
      GqlError<PolcoGqlError<PolcoGqlErrors.ClientUpdateProfileFailureReason>>
    >(
      updateProfileMut.fn({
        refetchQueries: [
          QueryInfos.respondentProfile.refetchInfo({
            respondentId: p.respId,
          }),
          QueryInfos.respondentSubscriptions.refetchInfo({
            respondentId: p.respId,
          }),
        ],
        variables: {
          respondentId: p.respId,
          input: {
            firstName: fields.firstName,
            lastName: fields.lastName,
            avatarUrl: fields.image,
            zipCode: fields.zipCode,
            email: fields.email,
            weeklyEmailEnabled: fields.weeklyEmailEnabled,
            outreachEmailEnabled: fields.outreachEmailEnabled,
            outcomeEmailEnabled: fields.outcomeEmailEnabled,
          },
        },
      })
    );
  };

  const respInfo =
    query.data?.openRespondent?.__typename === 'PrivateRespondent'
      ? query.data?.openRespondent
      : null;

  return (
    <RespondentAccount
      events={{ submit: updateProfile }}
      initialProfile={{
        firstName: respInfo?.firstName ?? '',
        lastName: respInfo?.lastName ?? '',
        image: respInfo?.avatarUrl ?? null,
        zipCode: respInfo?.zipCode ?? '',
        email: respInfo?.email ?? '',
        weeklyEmailEnabled: respInfo?.emailSettings?.weeklyEmailEnabled ?? false,
        outreachEmailEnabled: respInfo?.emailSettings?.outreachEmailEnabled ?? false,
        outcomeEmailEnabled: respInfo?.emailSettings?.outcomeEmailEnabled ?? false,
        inputtedLat: respInfo?.inputtedLat || null,
        inputtedLon: respInfo?.inputtedLon || null,
        // TODO: should get below fields from query. Currently query doesn't provide these data
        commentVisibility: CommentVisibility.NOTHING,
        interestTopic: [InterestTopic.ELECTIONS],
        gender: GenderCategories.PREFER_NOT_TO_SAY,
      }}
    />
  );
};
