import * as React from 'react';
import './styles.scss';

import { VotingHeader } from 'client/respondent/voting/shared/components/header';
import { publishingEntityPrimaryLogoUrl } from 'client/shared/core/publishing-entity';
import { PublishingEntityAsset } from 'client/respondent/core/types';
import { AlertBanner } from 'client/respondent/voting/shared/components/alert-banner';
import { REPORT_COPY } from './copy';
import {
  Btn,
  ButtonTypes,
  Dropdown,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { OutcomeReportItem } from 'client/shared/components/outcomes/report-item';
import { ReportTabContent } from 'core';

const baseClass = 'pn-report';
export interface ReportItem {
  readonly id: string;
  readonly title: string;
  readonly content: ReportTabContent;
}

interface Props {
  readonly pubName: string;
  readonly assets: readonly PublishingEntityAsset[];
  readonly surveyTitle: string;
  readonly shareable: boolean;
  readonly events: {
    readonly goToFeed: () => void;
    readonly goToPublishingEntity: () => void;
    readonly goBack: () => void;
    readonly goToOverview: () => void;
    readonly goToSurvey: () => void;
    readonly goToReport: (reportId: string) => void;
  };
  readonly currentReport: ReportItem;
  readonly reports: readonly ReportItem[];

  readonly previousReportId: string | null;
  readonly nextReportId: string | null;
}

export const OutcomeReport: React.FC<Props> = (p) => {
  const previousReportId = p.previousReportId;
  const nextReportId = p.nextReportId;

  return (
    <div className={`${baseClass} bg-white text-wrap`}>
      <VotingHeader
        events={{
          goToFeed: p.events.goToFeed,
          goToPublishingEntity: p.events.goToPublishingEntity,
          goBack: p.events.goBack,
        }}
        navPercentage={100}
        primaryLogo={publishingEntityPrimaryLogoUrl(p.assets)}
        publisherName={p.pubName}
        shareable={p.shareable}
      />
      <div className="position-relative z3">
        <AlertBanner alertText={REPORT_COPY.surveyClosed} errorType />
      </div>

      {/* top navivation buttons */}
      <div
        className={`${baseClass}-top-btn-group d-flex mt-5 bg-white ${
          p.previousReportId ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        {previousReportId && (
          <Btn
            action={() => p.events.goToReport(previousReportId)}
            className="p-2 text-primary mr-3"
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_LEFT} />{' '}
            {REPORT_COPY.previousTab}
          </Btn>
        )}

        <Btn
          action={p.events.goToOverview}
          className="p-2 text-primary mr-3 "
          type={ButtonTypes.SEAMLESS}
        >
          {REPORT_COPY.reportOverview}
        </Btn>
      </div>

      <div className="mx-3">
        <div className={`${baseClass}-title font-weight-bold`}>{p.surveyTitle}</div>
      </div>

      <Dropdown
        className={`${baseClass}-top-dropdown`}
        keySelect={(item) => item.id}
        labelSelect={(item, i) => `${i + 1}. ${item.title}`}
        onChange={(item) => {
          p.events.goToReport(item.id);
        }}
        options={p.reports}
        prompt={'Report Tab'}
        value={p.currentReport}
      />

      {/* body */}
      <OutcomeReportItem reportItem={p.currentReport} />

      {/* bottom buttons */}
      <div className="d-flex justify-content-between p-3">
        <Btn action={p.events.goToSurvey} className="" type={ButtonTypes.SECONDARY}>
          {REPORT_COPY.viewSurvey}
        </Btn>
        {nextReportId && (
          <Btn
            action={() => p.events.goToReport(nextReportId)}
            className=""
            type={ButtonTypes.PRIMARY}
          >
            {REPORT_COPY.nextTab}
            <MaterialIcon icon={MaterialIconName.KEYBOARD_ARROW_RIGHT} />{' '}
          </Btn>
        )}
      </div>
    </div>
  );
};
OutcomeReport.displayName = 'OutcomeReport';
