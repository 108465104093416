import * as React from 'react';
import {
  AppLink,
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { Form } from 'react-bootstrap';
import { useForm, RegisterOptions } from 'react-hook-form';
import { handlePressEnter, ClientUrlUtils } from 'client/shared/core/helpers';
import {
  LocationSource,
  UserLocation,
  UserLocationWithSource,
} from 'client/respondent/core/types';
import { ZIP_PROMPT_COPY } from './copy';
import { useSpring, animated } from 'react-spring';
import { RBRef } from 'client/shared/core/types';

import './styles.scss';
import { useRedirect } from 'client/shared/hooks';
import { useId } from 'react-use-id-hook';
import { COLORS_POLCO_GREEN_HEX } from 'client/shared/core/colors';

interface Props {
  readonly setLocation: (location: UserLocation, source: LocationSource) => void;
  readonly location: UserLocationWithSource | null;
  readonly saveZipCode: (zip: string) => Promise<void>;
  readonly guest: boolean;
}

const baseClass = 'pn-zip-prompt';

export const ZipPrompt: React.FC<Props> = (p: Props) => {
  const { guest } = p;

  //TODO implement GPS to location or save lat/lng
  // function gpsSuccess(pos: Position) {
  //   const crd = pos.coords;
  //   p.setLocation(
  //     {
  //       zip: null,
  //       latLng: { lat: crd.latitude, lng: crd.longitude },
  //     },
  //     LocationSource.GPS
  //   );
  //   setUseZip(false);
  // }
  // function error(err: PositionError) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }
  // const getLocation = () => {
  //   navigator.geolocation.getCurrentPosition(gpsSuccess, error, {
  //     timeout: 5000,
  //     maximumAge: 0,
  //     enableHighAccuracy: true,
  //   });
  // };

  const promptCopy = (zipSource?: LocationSource) => {
    const prefix = guest ? ZIP_PROMPT_COPY.guestTitle : '';
    if (zipSource === LocationSource.manualZip) {
      return (
        <div>
          {prefix}
          {ZIP_PROMPT_COPY.local.regular}
          <AppLink
            className={`${baseClass}-get-started-link`}
            to={ClientUrlUtils.respondent.signup.path()}
            unstyled
          >
            {ZIP_PROMPT_COPY.local.link}
          </AppLink>
        </div>
      );
    } else {
      return (
        <div>
          {prefix}
          {ZIP_PROMPT_COPY.noZip.regular}
          <span className="font-weight-bold">{ZIP_PROMPT_COPY.noZip.bold}</span>
        </div>
      );
    }
  };
  return (
    <div
      className={`${baseClass} w-100 mb-4 bg-white d-flex  flex-column flex-md-row align-items-center font-size-sm text-gray-50`}
    >
      {promptCopy(p.location?.source)}
      <div
        className={`${baseClass}-form-container d-flex flex-row align-items-center mt-2 mt-md-0`}
      >
        <SaveZipForm {...p} />
      </div>
    </div>
  );
};

export const GuestLogin: React.FC = () => {
  const redirect = useRedirect();

  return (
    <div className="text-center mt-1">
      <Btn
        action={() =>
          redirect(ClientUrlUtils.respondent.signup.path(), { push: true })
        }
        className="px-4 py-1"
        type={ButtonTypes.PRIMARY}
      >
        {ZIP_PROMPT_COPY.accountSetup}
      </Btn>
    </div>
  );
};

export const ZipSubmitted: React.FC = () => {
  const slideUpAnimation = useSpring({
    from: { opacity: 1, marginTop: '0px' },
    to: { opacity: 0, marginTop: '-200px' },
    delay: 4000,
  });

  return (
    <animated.div style={slideUpAnimation}>
      <div className={`${baseClass} w-100 px-4 bg-gray-5 text-center`}>
        <div className="font-weight-bold text-jungle text-center pt-4">
          {ZIP_PROMPT_COPY.thanks.title}
        </div>
        <div className="font-weight-bold text-jungle text-center">
          {ZIP_PROMPT_COPY.thanks.header}
        </div>
        <div className="text-gray-60 text-center font-size-sm py-3 px-4">
          {ZIP_PROMPT_COPY.thanks.description}{' '}
          <AppLink to={ClientUrlUtils.respondent.account.path()}>settings.</AppLink>
        </div>
        <div
          className={`${baseClass}-icon font-size-lg rounded-circle border-polco-green text-center mb-3`}
        >
          <MaterialIcon
            className="text-polco-green text-center font-weight-bold"
            icon={MaterialIconName.CHECK}
          />
        </div>
      </div>
    </animated.div>
  );
};

export const SaveZipForm: React.FC<Props> = (p) => {
  const { location } = p;

  const { register, handleSubmit, errors } = useForm<{
    readonly zipCode: string | null;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      //needed because zip could be null but we want the default value to be undefined if thats the case
      zipCode:
        location?.source === LocationSource.manualZip ? location?.zip : undefined,
    },
  });
  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };

  const submitZip = async (fields: { readonly zipCode: string }) => {
    p.setLocation(
      {
        zip: fields.zipCode,
        latLng: null,
      },
      LocationSource.manualZip
    );
    if (fields.zipCode) {
      await p.saveZipCode(fields.zipCode);
    }
  };
  const zipCodeIsInvalid = !!errors.zipCode;
  const zipCodeControlId = useId();
  const zipCodeFeedbackId = useId();

  return (
    <Form
      className="w-100"
      onKeyUp={handlePressEnter(handleSubmit(submitZip))}
      onSubmit={handleSubmit(submitZip)}
    >
      <div
        className={`${baseClass}-form d-flex align-items-center justify-content-center`}
      >
        {/*TODO implement GPS to location or save lat/lng <Btn
                size="small"
                type={ButtonTypes.SECONDARY}
                action={() => getLocation()}
                className="btn-sm mr-2"
              >
                Use GPS
              </Btn> */}
        <MaterialIcon
          className="pr-3 px-md-4"
          icon={MaterialIconName.ARROW_FORWARD}
          style={{ color: COLORS_POLCO_GREEN_HEX }}
        />
        {p.guest ? (
          <GuestLogin />
        ) : (
          <>
            <Form.Label className="d-none" htmlFor={zipCodeControlId}>
              ZIP code
            </Form.Label>
            <Form.Control
              aria-errormessage={zipCodeIsInvalid ? zipCodeFeedbackId : undefined}
              aria-invalid={zipCodeIsInvalid}
              aria-label="Zip code"
              className={`${baseClass}-input rounded h-100 accessible-input ${
                !!errors.zipCode ? 'has-error' : ''
              }`}
              defaultValue={
                location?.zip && location.source === LocationSource.manualZip
                  ? parseInt(location?.zip)
                  : undefined
              }
              id={zipCodeControlId}
              isInvalid={zipCodeIsInvalid}
              name={'zipCode'}
              placeholder={ZIP_PROMPT_COPY.placeholder}
              ref={createRef({
                pattern: {
                  value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  message: 'Zipcode format is not valid',
                },
              })}
            />
            <Btn
              action={handleSubmit(submitZip)}
              className={`${baseClass}-submit-btn ml-3`}
              disabled={!!errors.zipCode}
              size="small"
              type={ButtonTypes.PRIMARY}
            >
              {ZIP_PROMPT_COPY.zipBtn}
            </Btn>
          </>
        )}
      </div>
      <Form.Control.Feedback
        className="text-center"
        id={zipCodeFeedbackId}
        type="invalid"
      >
        {errors.zipCode?.message}
      </Form.Control.Feedback>
    </Form>
  );
};
