import * as React from 'react';
import { baseClass, RespondentPlgWrapper } from './plg_page_wrapper';

const COPY = {
  title: 'What is Polco?',
  mainHeader: {
    main: "We're a cutting-edge digital platform designed to enhance communication between community residents like you and a variety of organizations ranging from local government bodies to educational institutions and public service entities. This unique tool allows you to:",
    listItems: [
      {
        header: 'Access a Variety of Feeds: ',
        main: "Receive updates and engage with content from the US government, state, county, and local level governments, Homeowners' Associations (HOAs), school districts, and other public needs organizations.",
      },
      {
        header: 'Customize Your Experience: ',
        main: 'Search for and subscribe to feeds from different organizations. Tailor your main feed page to show only the content that matters to you.',
      },
      {
        header: 'Engage Anonymously: ',
        main: 'Even without logging in, enter your ZIP code to explore example content feeds and get a taste of what Polco offers.',
      },
      {
        header: 'Contribute to Your Community: ',
        main: 'Participate in surveys, polls, and discussions. Your feedback directly influences local projects and decisions.',
      },
    ],
  },
  joining:
    'Joining Polco empowers you to be an active participant in shaping the future of your community with just a few clicks. Your voice has never been more powerful or more needed.  Polco empowers you, the resident, by providing a direct line to your community leaders and decision-makers.',
  subHeader: {
    main: 'Here are some things that makes Polco unique:',
    listItems: [
      {
        header: 'Engagement: ',
        main: "Participate in surveys, polls, and discussions that directly impact your community's future. Your voice matters, and here, it's heard.",
      },
      {
        header: 'Transparency: ',
        main: "Get clear insights into what's happening in your local government and the initiatives being considered. Understand the 'why' and 'how' behind decisions affecting your community.",
      },
      {
        header: 'Education: ',
        main: 'Learn more about local policies, projects, and plans as they unfold. Stay informed about community developments and upcoming events.',
      },
    ],
  },
  proactive:
    'With Polco, being a proactive community member is easy and impactful. Start making a difference today by joining our growing network of engaged residents!',
};

export const WhatIsPolcoPage: React.FC = () => {
  return (
    <RespondentPlgWrapper title={COPY.title}>
      <div className="text-gray-40 mb-3 pb-3">{COPY.mainHeader.main}</div>
      <ul className="mb-3 pb-3">
        {COPY.mainHeader.listItems.map((item, idx) => {
          return (
            <li key={idx}>
              <span className="font-weight-bold">{item.header}</span>
              {item.main}
            </li>
          );
        })}
      </ul>
      <hr className={`${baseClass}-separator w-100`} />
      <div className="text-gray-40 mb-3 pb-3 font-weight-bold">{COPY.joining}</div>
      <hr className={`${baseClass}-separator w-100`} />
      <div className="text-gray-40 mb-3 pb-3">{COPY.subHeader.main}</div>
      <ul className="mb-3 pb-3">
        {COPY.subHeader.listItems.map((item, idx) => {
          return (
            <li key={idx}>
              <span className="font-weight-bold">{item.header}</span>
              {item.main}
            </li>
          );
        })}
      </ul>
      <div className="text-gray-40 font-weight-bold">{COPY.proactive}</div>
    </RespondentPlgWrapper>
  );
};
