import * as React from 'react';
import './styles.scss';
import { THANK_YOU_COPY } from './copy';
import {
  AppLink,
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { ClientUrlUtils } from 'client/shared/core/helpers';

export interface Props {
  readonly isGuest: boolean;
  readonly events: {
    readonly learnMore: () => void;
    readonly goToFeed: () => void;
  };
}

const baseClass = 'pn-polco-live-thank-you';

export const PolcoLiveThankYou: React.FC<Props> = (props) => {
  return (
    <div className={`${baseClass} text-center bg-white px-2 py-4`}>
      <MaterialIcon className={`${baseClass}-icon`} icon={MaterialIconName.CHECK} />
      <div className={`${baseClass}-title my-4 pb-3 mx-auto`}>
        <h2 className="h4 font-weight-bold">{THANK_YOU_COPY.title}</h2>
      </div>

      <div className="mb-3 pb-3 mx-auto text-gray-60">{THANK_YOU_COPY.subTitle}</div>

      {props.isGuest ? (
        <>
          <div className={`${baseClass}-learn-more mb-3 pb-3 mx-auto text-gray-60`}>
            {THANK_YOU_COPY.learnMoreTitle}
          </div>
          <Btn
            action={props.events.learnMore}
            className="py-1 px-4 font-size-sm mx-1 mb-1"
            type={ButtonTypes.PRIMARY}
          >
            {THANK_YOU_COPY.learnMore}
          </Btn>

          <div className="mt-3 font-size-sm">
            Already have an account?{' '}
            <AppLink
              className="m-auto text-jungle"
              to={ClientUrlUtils.respondent.login.path()}
            >
              Log in
            </AppLink>
          </div>
        </>
      ) : (
        <Btn
          action={props.events.goToFeed}
          className="py-1 px-4 font-size-sm mx-1 mb-1"
          type={ButtonTypes.PRIMARY}
        >
          {THANK_YOU_COPY.goToFeed}
        </Btn>
      )}
    </div>
  );
};
