import React from 'react';
import { ActionLink, Pill, PillTypes } from '../base';

interface Props {
  readonly publisherName: string;
  readonly goToPublishingEntity: () => void;
}

const baseClass = 'pn-publishing-entity-pill';

export const PublishingEntityPill: React.FC<Props> = ({
  publisherName,
  goToPublishingEntity,
}) => {
  return (
    <div className={`${baseClass} d-flex mb-4 flex-wrap`}>
      <Pill type={PillTypes.FACET}>
        <ActionLink
          action={goToPublishingEntity}
          className={`${baseClass}-publisher-name text-jungle`}
        >
          {publisherName}
        </ActionLink>
      </Pill>
    </div>
  );
};
