import { MainPage } from 'client/respondent/shared/pages/main-page';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import * as React from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';
import { IdeaDetailsCard } from 'client/respondent/residents-input/components/idea-details';
import { ApiDate } from 'core';
import { QueryInfos } from 'client/shared/graphql-queries';
import { useQueryInfo } from 'client/shared/containers/query';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { useDefaultLanguage } from 'client/shared/hooks';
import { ShareModal } from 'client/shared/components/share-modal';
import { shareIdeaUrl } from 'client/shared/components/idea/idea-more-menu';
import { fbShare } from 'client/shared/integrations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { VoteType } from 'client/shared/graphql-client/graphql-operations.g';
import { voteTypeToIdeaInteractionAction } from 'client/shared/core/resident-inputs';
import { ClientRespondentId } from 'client/respondent/core';
import {
  AUTHN_CONTEXT,
  AuthnVoteSectionProps,
} from 'client/respondent/shared/account/components/authn-vote-section';
import { twitterShare } from 'client/shared/integrations/twitter';

export const ViewIdeaPage: React.FC<RouteComponentProps<{ readonly id: string }>> = (
  props
) => {
  return (
    <MainPage hideRightSidebar>
      {(respondentUser) => (
        <ViewIdeaPageInner
          ideaId={props.match.params.id}
          respId={respondentUser?.user?.respondent?.id ?? null}
        />
      )}
    </MainPage>
  );
};

const ViewIdeaPageInner: React.FC<{
  readonly ideaId: string;
  readonly respId: ClientRespondentId | null;
}> = (props) => {
  const { ideaId, respId } = props;
  const history = useHistory();
  const selectLanguageText = useDefaultLanguage();
  const query = useQueryInfo(QueryInfos.openIdea, {
    variables: { ideaId, respondentId: respId },
  });
  const [sharingUrl, setSharingUrl] = React.useState<string | null>(null);
  const ideaVoteAction = useMutationInfo(MutationInfos.interactWithIdea);

  if (!query.data && query.loading) {
    return <RespondentPageLoader />;
  }

  const idea = query.data?.openIdea;

  if ((query.error && query.error.message === '404') || !idea) {
    return <Redirect to={ClientUrlUtils.respondent.notFound.path()} />;
  }

  const publishingEntitySlug = idea.publishingEntity.slug;

  function goBackOrToTheFeed() {
    if (history.length) {
      history.goBack();
    }
    history.push(ClientUrlUtils.respondent.feed.path());
  }

  function goToPublishingEntity() {
    history.push(
      ClientUrlUtils.respondent.pubProfile.path({ slug: publishingEntitySlug })
    );
  }
  const authnVoteButton: AuthnVoteSectionProps = {
    action: async (voteType: VoteType, respondentId: ClientRespondentId) => {
      if (!ideaVoteAction) {
        return;
      }
      await ideaVoteAction.fn({
        variables: {
          input: {
            ideaId: idea.id,
            action: voteTypeToIdeaInteractionAction(voteType),
          },
          respondentId,
        },
      });
    },
    respId: respId ?? undefined,
    respondentVote: idea.respondentVote,
    voteCounts: {
      up: idea.aggregations?.voteUp ?? 0,
      down: idea.aggregations?.voteDown ?? 0,
    },
    promptItems: AUTHN_CONTEXT.ideaVote,
  };

  return (
    <>
      <ShareModal
        events={{
          facebook: {
            share: () => fbShare(sharingUrl ?? ''),
          },
          twitter: {
            share: () => twitterShare(sharingUrl ?? ''),
          },
          cancel: () => setSharingUrl(null),
        }}
        isOpen={!!sharingUrl}
        url={sharingUrl ?? ''}
      />
      <IdeaDetailsCard
        author={idea.respondent.publicName}
        createdAt={ApiDate.fromApi(idea.createDate)}
        description={idea.description ?? undefined}
        goBack={goBackOrToTheFeed}
        goToPublishingEntity={goToPublishingEntity}
        id={ideaId}
        onIdeaShared={(id) => setSharingUrl(shareIdeaUrl(id))}
        publisherName={selectLanguageText(idea.publishingEntity.name)}
        title={idea.title}
        voteButton={authnVoteButton}
      />
    </>
  );
};
