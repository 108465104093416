import * as React from 'react';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { PasswordResetForm } from 'client/respondent/shared/account/components/access/password-reset';
import { getFingerprint } from 'client/shared/integrations';
import { Redirect } from 'react-router';
import { RequestPasswordResetContext } from 'core';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { PasswordResetResult } from 'client/respondent/core/types';

export const ResetPasswordContainer: React.FC<{
  readonly token: string;
  readonly email: string;
}> = (p) => {
  const { fn: reset, result } = useMutationInfo(MutationInfos.passwordReset);

  if (result.data?.passwordReset.__typename === 'CurrentUser') {
    return <Redirect to="/res"></Redirect>;
  }

  return (
    <PasswordResetForm
      events={{
        async reset(d: { readonly password: string }) {
          const res = await reset({
            variables: {
              password: d.password,
              token: p.token,
              email: p.email,
              fingerprint: getFingerprint(),
              context: RequestPasswordResetContext.RESPONDENT_FORGOT_PASSWORD,
            },
          });

          switch (res.data?.passwordReset.__typename) {
            case 'CurrentUser':
              return PasswordResetResult.SUCCESS;
            case 'ResetPasswordFailed':
              switch (res.data.passwordReset.reason) {
                case Gql.ResetPasswordFailedReason.INVALID_TOKEN:
                  return PasswordResetResult.ERROR_EXPIRED;
                case Gql.ResetPasswordFailedReason.RESET_FAILED:
                  return PasswordResetResult.ERROR;
              }
          }

          return PasswordResetResult.ERROR;
        },
      }}
    />
  );
};
