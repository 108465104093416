import * as React from 'react';
import { FeedCard } from './feed-card';
import { FeedCardProps } from './feed-card/common';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';

export interface FeedItem extends FeedCardProps {
  readonly key: string;
}

export interface FeedProps {
  readonly items: readonly FeedItem[];
  readonly loading: boolean;
}

const baseClass = 'pn-feed';

export const Feed = (p: FeedProps) => {
  return (
    <>
      <div className={`${baseClass} mx-auto`}>
        {p.loading && <RespondentPageLoader className="mt-0" />}
        {p.items.map((item) => (
          <FeedCard {...item} />
        ))}
      </div>
    </>
  );
};
