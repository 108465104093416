import * as React from 'react';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { RequestPasswordResetForm } from 'client/respondent/shared/account/components/access/request-password-reset';
import { RequestPasswordResetContext } from 'core';
import { socialLoginHandler } from 'client/respondent/shared/account/containers/shared';
import {
  RegistrationErrors,
  NotLoggedInActionStatus,
} from 'client/respondent/core/types';
import { SocialLoginType } from 'client/shared/graphql-client/graphql-operations.g';
import {
  useRespondentAnalyticsCallback,
  useRespondentAnalyticsViewEvent,
  useSocialLogins,
} from 'client/respondent/hooks';
import { AnalyticsEventType } from 'client/respondent/core/analytics';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import { useRedirect } from 'client/shared/hooks';
import * as RespondentContext from 'client/respondent/core/reducers/context';

export const RequestResetPasswordContainer: React.FC = () => {
  const { fn: requestReset } = useMutationInfo(MutationInfos.requestPasswordReset);
  const [registrationErrors, setRegistrationErrors] =
    React.useState<RegistrationErrors>({
      [SocialLoginType.GOOGLE]: null,
      [SocialLoginType.FACEBOOK]: null,
    });
  const muts = {
    socialLogin: useMutationInfo(MutationInfos.socialLogin),
  };
  const eventHandler = useRespondentAnalyticsCallback();
  useRespondentAnalyticsViewEvent(AnalyticsEventType.VIEWED_REGISTRATION);
  const logins = useSocialLogins();
  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();
  const redirect = useRedirect();

  return (
    <RequestPasswordResetForm
      events={{
        async requestReset(d: { readonly email: string }) {
          await requestReset({
            variables: {
              email: d.email,
              context: RequestPasswordResetContext.RESPONDENT_FORGOT_PASSWORD,
            },
          });
        },
        socialLogin: (provider) =>
          socialLoginHandler(
            provider,
            logins,
            muts.socialLogin,
            eventHandler,
            ({ conversionType }) => {
              dispatch(RespondentActions.registrationSuccessful(conversionType));
              const redirectLink =
                state.preLoginAction &&
                state.preLoginAction.status !==
                  NotLoggedInActionStatus.ACTION_COMPLETE
                  ? state.preLoginAction.redirectLink
                  : '/res';
              redirect(redirectLink, {
                push: true,
              });
            },
            setRegistrationErrors
          ),
      }}
      registrationErrors={registrationErrors}
    />
  );
};
