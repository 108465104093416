import { SUPPORT_EMAIL, UnsubscribeResult } from 'core';

export const UNSUBSCRIBE_CONFIRM_COPY = {
  ok: 'Ok',
  desc: {
    [UnsubscribeResult.ALREADY_UNSUBSCRIBED]:
      "You have previously unsubscribed. If you'd like to subscribe again, you can do so on your profile settings.",
    [UnsubscribeResult.SUCCESS]:
      "You have successfully unsubscribed. If you'd like to subscribe again in the future, you can do so on your profile settings.",
    [UnsubscribeResult.INVALID_TOKEN]: `We were unable to unsubscribe you. Please contact ${SUPPORT_EMAIL} if this persists.`,
  },
};
