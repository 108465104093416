import QuestionNewComment from 'client/shared/graphql-subscriptions/QuestionNewComment.graphql';
import QuestionResults from 'client/shared/graphql-subscriptions/QuestionResults.graphql';
import SubscriptionInfo from 'client/shared/graphql-subscriptions/LiveContentSet.graphql';
import { subscriptionInfo } from 'client/shared/containers/subscription';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

export const liveContentSet = subscriptionInfo<
  Gql.LiveContentSet,
  Gql.LiveContentSetVariables
>(SubscriptionInfo);

export const questionResults = subscriptionInfo<
  Gql.QuestionResults,
  Gql.QuestionResultsVariables
>(QuestionResults);

export const questionNewComment = subscriptionInfo<
  Gql.QuestionNewComment,
  Gql.QuestionNewCommentVariables
>(QuestionNewComment);
