import _ from 'lodash';
import React from 'react';
import './styles.scss';

import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { getConfig } from 'client/shared/core/config';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { RespondentLayout, useLayout, useRedirect } from 'client/shared/hooks';
import { Maybe } from 'core';
import { COPY } from './messages/copy';
import { usePublisherSubscriptionCanPublishData } from 'client/respondent/hooks';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';

const config = getConfig();
export const createIdeaCardBaseClassName = 'pn-respondent-feed-create-idea-card';

export const CreateIdeaCardOnDesktop: React.FC<{
  readonly currentResidentId?: Maybe<string>;
  readonly guest?: boolean;
}> = ({ currentResidentId, guest }) => (
  <CreateIdeaCard
    currentResidentId={currentResidentId}
    guest={guest}
    renderOnMobileViewPort={false}
  />
);

export const CreateIdeaCardOnMobile: React.FC<{
  readonly currentResidentId?: Maybe<string>;
  readonly guest?: boolean;
}> = ({ currentResidentId, guest }) => (
  <CreateIdeaCard
    currentResidentId={currentResidentId}
    guest={guest}
    renderOnMobileViewPort
  />
);

const CreateIdeaCard: React.FC<{
  readonly currentResidentId?: Maybe<string>;
  readonly guest?: boolean;
  readonly renderOnMobileViewPort: boolean;
}> = ({ currentResidentId, guest, renderOnMobileViewPort = true }) => {
  const { isMobile } = useLayout({
    mobileBreakingPoint: RespondentLayout.ContentWidth,
  });
  const { subscriptions, query } = usePublisherSubscriptionCanPublishData(
    currentResidentId ?? undefined
  );

  const hasActiveSession = !_.isNil(currentResidentId);
  const isFeatureEnabled = config.envFeatures.enableResidentIdeas;
  const allowsCreateIdea =
    !query.loading &&
    isFeatureEnabled &&
    hasActiveSession &&
    subscriptions.length > 0;

  if (!allowsCreateIdea) return null;

  const renderOnMobile = isMobile && renderOnMobileViewPort;
  if (renderOnMobile) return <MobileView guest={guest} />;

  const renderOnDesktop = !isMobile && !renderOnMobileViewPort;
  if (renderOnDesktop) return <DesktopView guest={guest} />;

  return null;
};

const MobileView: React.FC<{ readonly guest?: boolean }> = ({ guest }) => {
  return (
    <div
      className={`${createIdeaCardBaseClassName}--mobile w-100 py-2 bg-white fixed-bottom position-sticky mt-auto`}
    >
      <CallToActionButton guest={guest} text={COPY.createIdeaAction} />
    </div>
  );
};

const DesktopView: React.FC<{ readonly guest?: boolean }> = ({ guest }) => {
  return (
    <div
      className={`${createIdeaCardBaseClassName}--desktop mb-3 rounded bg-white border-1`}
    >
      <div className="text-left px-4 pt-3">
        <p className="font-size-lg text-break">{COPY.description}</p>
      </div>
      <div className="d-flex p-3">
        <div
          className={`${createIdeaCardBaseClassName}-avatar rounded-circle p-3`}
        ></div>
        <CallToActionButton
          className="ml-2"
          guest={guest}
          text={COPY.createIdeaAction}
        />
      </div>
    </div>
  );
};

const CallToActionButton: React.FC<{
  readonly className?: string;
  readonly guest?: boolean;
  readonly text: string;
}> = ({ className, guest, text }) => {
  const redirect = useRedirect();
  const [isOpen, setIsOpen] = React.useState(false);

  const navigateToAddIdea = () => {
    if (guest) {
      setIsOpen(true);
    } else {
      redirect(ClientUrlUtils.respondent.createIdea.path(), {
        push: true,
      });
    }
  };

  return (
    <>
      <Btn
        action={navigateToAddIdea}
        className={`${createIdeaCardBaseClassName}-button w-100 ${className ?? ''}`}
        type={ButtonTypes.SECONDARY}
      >
        {text}
      </Btn>

      {isOpen && (
        <AuthnFlowSliderModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          registrationPrompt={{
            description: COPY.modal.description,
            image: <MaterialIcon icon={MaterialIconName.LIGHTBULB} />,
            title: COPY.modal.title,
            secondaryButtonLabel: COPY.modal.secondaryButtonLabel,
            switchButtons: true,
          }}
        />
      )}
    </>
  );
};
