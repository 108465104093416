import { MainPage } from 'client/respondent/shared/pages/main-page';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BalancingActSimulationContainer } from '../../containers';

export type Props = RouteComponentProps<{
  readonly simulationId: string;
}> & {
  readonly isTaxReceipt?: boolean;
};
export const BalancingActSimulationPage: React.FC<Props> = (p) => {
  return (
    <MainPage hideLeftSidebar={true} hideRightSidebar={true} simulationPage={true}>
      {(respondent) => {
        const simId = Number(p.match.params.simulationId);

        return (
          <BalancingActSimulationContainer
            isTaxReceipt={p.isTaxReceipt ?? false}
            respondent={respondent}
            simulationId={simId}
          />
        );
      }}
    </MainPage>
  );
};
BalancingActSimulationPage.displayName = 'BalancingActSimulationPage';
