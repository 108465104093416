import * as React from 'react';
import './styles.scss';
import { FeedCardHeader } from './feed-card-header';
import { FeedCardBanner } from './feed-card-banner';
import { FeedCardProps } from './common';

export const FeedCard = (p: FeedCardProps) => {
  return (
    <article className="pn-respondent-feed-card mb-2 mb-sm-3 bg-gray-10 pn-feed-card-rounded">
      {!p.banner.singleQuestion && <FeedCardHeader {...p.header} />}
      <FeedCardBanner {...p.banner} />
    </article>
  );
};
