import * as React from 'react';
import notAvailableImageUrl from './assets/not-available.svg';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { useRedirect } from 'client/shared/hooks';

export const copy = {
  title: 'This content is not available anymore',
  body: "It looks like the content you are trying to access is not available or you don't have access to it anymore. Please use the top menu or the button below to navigate back to familiarity.",
  continue: 'Go to my feed',
};

export const NotAvailableUI: React.FC = () => {
  const redirect = useRedirect();
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="mt-5 mx-auto">
          <div className="col-12 d-flex justify-content-center">
            <img alt={copy.title} className={``} src={notAvailableImageUrl} />
          </div>
          <div className="mt-5 text-jungle text-center font-weight-bold">
            {copy.title}
          </div>
          <div className="mt-4 text-center">
            <div className="d-flex justify-content-center">
              <div className="text-gray-60 w-75 font-size-sm">{copy.body}</div>
            </div>
            <Btn
              action={() => redirect('/res', { push: true })}
              className="mt-5 mb-3 w-75"
              type={ButtonTypes.PRIMARY}
            >
              {copy.continue}
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};
