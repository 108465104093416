export const ZIP_PROMPT_COPY = {
  welcome: 'Welcome to Polco!',
  header: "Let's get you the questions that matter to you.",
  login: 'Log In',
  accountSetup: 'Account Setup',
  baseContent:
    "We've located you in the following ZIP code, but feel free to change it if we are off.",
  usingGPS: 'The content you see below is based on your GPS location.',
  usingZIP: 'The content you see below is based on your ZIP code.',
  useZip: 'Save ZIP',
  placeholder: 'Enter ZIP code',
  useGPS: 'Use GPS instead.',
  thanks: {
    title: 'Thanks!',
    header: 'Your ZIP code has been saved',
    description: 'You can always change it in your',
  },
  guestTitle: "You're in guest mode.  ",
  guestDescription:
    'You can only respond to surveys that allow guest responses. Finish setting up your account to get full access with a personalized feed with the issues that matter most to you.',
  noZip: {
    regular:
      'You are viewing an example content feed. To get more relevant content, ',
    bold: 'enter your ZIP code to see your local feed.',
  },
  local: {
    regular:
      'This is an example of your local feed. Change the ZIP code to a different local feed or ',
    link: 'click here to get started',
  },
  zipBtn: "Let's Go",
};
