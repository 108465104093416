import * as React from 'react';
import './styles.scss';

import {
  VotingProps,
  NotLoggedInActions,
  QuestionPageRedirectLocation,
} from 'client/respondent/core/types';
import { VotingButtons } from '../../../shared/components/voting-buttons';
import { POLCO_LIVE_COPY } from './copy';
import { Comments } from '../../../shared/components/comments';
import { MoreInfo } from '../../../shared/components/more-info';
import { ShareModal } from 'client/shared/components/share-modal';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { AddComment } from '../../../shared/components/add-comment';
import { VotingResult } from '../../../shared/components/voting-result';
import { ClientQuestionId, QuestionType } from 'client/shared/core/question';
import { nth, compact, isEqual, truncate } from 'lodash';
import { ClientRespondentId } from 'client/respondent/core';
import { AlertBanner } from '../../../shared/components/alert-banner';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { VotingInteraction, VoteResultType } from 'client/shared/core/types';
import { _dangerousUseEffectOnMount } from 'client/shared/helpers/hooks';
import {
  checkIfInProcessVoteIsValid,
  processPreLoginAction,
} from 'client/respondent/shared/functions';
import { useRespondentDispatch } from 'client/respondent/core/reducers/context';
import * as SubscriptionInfos from 'client/shared/graphql-subscriptions/subscription-infos';
import { useSubscriptionInfo } from 'client/shared/containers/subscription';
import { LiveActionButtons } from '../../components/live-action-buttons';
import { useRedirect, useCurrentTime } from 'client/shared/hooks';
import moment from 'moment';
import { QuestionSetType } from 'client/shared/core/question-set';
import { TextWithLineBreak } from 'client/shared/components/text-with-line-break';
import { RespondentsSetStatus } from 'core';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';
import polcoLogo from 'client/assets/polco-logo.svg';

const baseClass = 'pn-voting-prevote';
const maxTitleLengthForBanner = 20;

enum ModalType {
  MORE_INFO = 'MORE_INFO',
  CREATE_ACCOUNT_PRE_VOTE = 'CREATE_ACCOUNT_PRE_VOTE',
  CONVERT_GUEST = 'CONVERT_GUEST',
  SHARING = 'SHARING',
}

export enum BannerType {
  SHARE = 'SHARE',
  COMMENT = 'COMMENT',
  VIEW_COMMENTS = 'VIEW_COMMENTS',
  NEW_QUESTION = 'NEW_QUESTION',
  NEW_QUESTION_WITH_AUTO_ADVANCE = 'NEW_QUESTION_WITH_AUTO_ADVANCE',
  CURRENT_CLOSING_SOON = 'CURRENT_CLOSING_SOON',
  UNANSWERED_CLOSING_SOON = 'UNANSWERED_CLOSING_SOON',
  CLOSED = 'CLOSED',
}

const AUTO_ADVANCE_TIME = 5000;

export const PolcoLiveLoaded: React.FC<VotingProps.QuestionSetLoaded> = (props) => {
  const redirect = useRedirect();
  const [eventStatus, setEventStatus] = React.useState(props.status);

  // This allows us to redirect when an event is just closed, but not when a user goes back into the event that has already been closed
  React.useEffect(() => {
    if (
      eventStatus !== RespondentsSetStatus.CLOSED &&
      props.status === RespondentsSetStatus.CLOSED
    ) {
      setEventStatus(RespondentsSetStatus.CLOSED);
      redirect(
        ClientUrlUtils.respondent.polcoLiveThankYou.path({
          setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
          pubSlug: props.publishingEntity.slug,
        }),
        { push: true }
      );
    }
  }, [
    eventStatus,
    props.id,
    props.publishingEntity.slug,
    props.questionSetId,
    props.status,
    redirect,
    props.questionSetSlug,
  ]);

  const now = useCurrentTime({ frequencyMillis: 1000 });

  // question ids for navigation
  const nextQuestionId =
    nth(props.setData.questions, props.setData.currentIndex + 1)?.id ?? null;

  const unAnsweredQuestions = props.setData.questions.filter(
    (q) => q.previousVote === null
  );
  const closingUnAnsweredQuestions = compact(
    unAnsweredQuestions.map((q) => {
      if (!q.schedule.closeDate) {
        return null;
      }
      const remainingSeconds = moment(q.schedule.closeDate).diff(
        moment(now),
        'seconds'
      );
      if (
        typeof remainingSeconds === 'number' &&
        remainingSeconds > 0 &&
        remainingSeconds <= 30
      ) {
        return q;
      } else {
        return null;
      }
    })
  );

  const voted = !!props.typedData.previousVote;
  const commented = !!props.typedData.previousVote?.comment;
  const dispatch = useRespondentDispatch();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [showBanner, setShowBanner] = React.useState<BannerType | null>(
    BannerType.VIEW_COMMENTS
  );
  const [error, setError] = React.useState<string | null>(null);
  const [modalType, setModalType] = React.useState<ModalType | null>(null);
  const [newestQuestionId, setNewestQuestionId] = React.useState<ClientQuestionId>(
    props.setData.questions[props.setData.questions.length - 1].id
  );

  const liveQuestion = props.setData.questions.find((q) => q.id === props.id);
  const timeRemainingSeconds = liveQuestion
    ? moment(liveQuestion.schedule.closeDate).diff(moment(now), 'seconds')
    : null;

  const isClosedQuestion = timeRemainingSeconds ? timeRemainingSeconds < 0 : false;

  // this will trigger updates to cache which will trigger new results from
  // useQueryInfo up above in parent component.
  // TODO: line up the query and subscription in the same file, for
  // sanity's sake
  useSubscriptionInfo(SubscriptionInfos.questionResults, {
    variables: {
      questionId: props.id,
    },
  });
  const voteForQuestion = async () => {
    const vote = await props.events.submitVote();
    if (vote.type === VoteResultType.SUCCESS) {
      await props.events.followPublisher();
    } else if (vote.type === VoteResultType.ERROR) {
      setError(vote.message);
    }
    return vote;
  };
  const handleSubmit = async () => {
    if ((!props.respId || props.isGuest) && !props.allowGuestRespondents) {
      setModalType(ModalType.CREATE_ACCOUNT_PRE_VOTE);
    } else {
      const vote = await voteForQuestion();
      if (vote.type === VoteResultType.SUCCESS) {
        props.events.setInteraction(VotingInteraction.VOTE_SUCCESSFUL);
        setSubmitting(false);
      }
    }
  };

  const handleGoToOverView = () => {
    redirect(
      ClientUrlUtils.respondent.polcoLiveWaiting.path({
        setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
        pubSlug: props.publishingEntity.slug,
      }),
      {
        push: true,
      }
    );
  };

  const handleNextQuestion = () => {
    // if event hasn't ended but there is no available question to vote,
    // redirect to waiting page
    if (props.status === RespondentsSetStatus.START && !nextQuestionId) {
      redirect(
        ClientUrlUtils.respondent.polcoLiveWaiting.path({
          pubSlug: props.publishingEntity.slug,
          setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
        }),
        {
          push: true,
        }
      );
    }
    if (props.setData.questions && nextQuestionId) {
      return props.events.goToQuestion(nextQuestionId);
    }
    if (props.status === RespondentsSetStatus.CLOSED) {
      redirect(
        ClientUrlUtils.respondent.polcoLiveThankYou.path({
          pubSlug: props.publishingEntity.slug,
          setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
        }),
        {
          push: true,
        }
      );
    }
  };

  const questionNumber = (props.setData.currentIndex + 1)
    .toString()
    .padStart(2, '0');

  _dangerousUseEffectOnMount(() =>
    processPreLoginAction(
      props.preLoginAction,
      NotLoggedInActions.SET_VOTE,
      dispatch,
      (act) => {
        if (act.data.questionId !== props.id) return null;
        return handleSubmit();
      }
    )
  );

  React.useEffect(() => {
    // This is to clear actions such as commenting from a previous question
    props.events.cancelInteraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  React.useEffect(() => {
    if (
      newestQuestionId !==
        props.setData.questions[props.setData.questions.length - 1].id &&
      props.status === RespondentsSetStatus.START
    ) {
      if ((voted && commented) || isClosedQuestion) {
        setShowBanner(BannerType.NEW_QUESTION_WITH_AUTO_ADVANCE);
        setTimeout(() => {
          redirect(
            ClientUrlUtils.respondent.question.path({
              questionId:
                props.setData.questions[props.setData.questions.length - 1].id,
              setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
              pubSlug: props.publishingEntity.slug,
              setType: QuestionSetType.POLCO_LIVE,
            }),
            {
              push: true,
            }
          );
        }, AUTO_ADVANCE_TIME);
      } else {
        if (closingUnAnsweredQuestions.length > 0) {
          setShowBanner(BannerType.UNANSWERED_CLOSING_SOON);
        } else {
          setShowBanner(BannerType.NEW_QUESTION);
        }
      }
    } else if (
      typeof timeRemainingSeconds === 'number' &&
      timeRemainingSeconds > 0 &&
      timeRemainingSeconds <= 30
    ) {
      setShowBanner(BannerType.CURRENT_CLOSING_SOON);
    } else if (
      !isClosedQuestion &&
      !voted &&
      props.comments.loadedQuestion.length > 0 &&
      props.typedData.questionType !== QuestionType.FREE_TEXT
    ) {
      setShowBanner(BannerType.VIEW_COMMENTS);
    } else if (
      !isClosedQuestion &&
      voted &&
      !commented &&
      props.typedData.questionType !== QuestionType.FREE_TEXT &&
      props.votingInteraction !== VotingInteraction.COMMENTING
    ) {
      setShowBanner(BannerType.COMMENT);
    } else if (isClosedQuestion) {
      setShowBanner(BannerType.CLOSED);
    } else {
      setShowBanner(null);
    }
  }, [
    isClosedQuestion,
    props.comments.loadedQuestion.length,
    props.isGuest,
    commented,
    props.typedData.questionType,
    newestQuestionId,
    props.setData.questions,
    props.status,
    setNewestQuestionId,
    props.id,
    voted,
    props.setData.currentIndex,
    props.questionSetSlug,
    props.questionSetId,
    props.publishingEntity.slug,
    redirect,
    timeRemainingSeconds,
    closingUnAnsweredQuestions.length,
    props.votingInteraction,
  ]);

  const renderAlert = () => {
    switch (showBanner) {
      case BannerType.VIEW_COMMENTS: {
        return (
          <AlertBanner
            alertAction={() => {
              props.events.setInteraction(VotingInteraction.VIEWING_COMMENTS);
              setShowBanner(null);
            }}
            alertText={POLCO_LIVE_COPY.alertText(BannerType.VIEW_COMMENTS)}
          />
        );
      }
      case BannerType.CLOSED: {
        return (
          <AlertBanner
            alertAction={() => {
              setShowBanner(null);
            }}
            alertText={POLCO_LIVE_COPY.alertText(BannerType.CLOSED)}
            errorType
          />
        );
      }
      case BannerType.COMMENT: {
        return (
          <AlertBanner
            alertAction={() => {
              props.events.setInteraction(VotingInteraction.COMMENTING);
              setShowBanner(null);
            }}
            alertText={POLCO_LIVE_COPY.alertText(BannerType.COMMENT)}
          />
        );
      }
      case BannerType.SHARE: {
        return (
          <AlertBanner
            alertAction={() => {
              setModalType(ModalType.SHARING);
              setShowBanner(null);
            }}
            alertText={POLCO_LIVE_COPY.alertText(BannerType.SHARE)}
          />
        );
      }
      case BannerType.NEW_QUESTION: {
        return (
          <AlertBanner
            alertAction={() => {
              setShowBanner(null);
              const newQuestionId =
                props.setData.questions[props.setData.questions.length - 1].id;
              setNewestQuestionId(newQuestionId);
              props.events.goToQuestion(newQuestionId);
            }}
            alertText={POLCO_LIVE_COPY.alertText(BannerType.NEW_QUESTION)}
          />
        );
      }
      case BannerType.NEW_QUESTION_WITH_AUTO_ADVANCE: {
        return (
          <AlertBanner
            alertAction={() => {
              setShowBanner(null);
              const newQuestionId =
                props.setData.questions[props.setData.questions.length - 1].id;
              setNewestQuestionId(newQuestionId);
              props.events.goToQuestion(newQuestionId);
            }}
            alertText={POLCO_LIVE_COPY.alertText(
              BannerType.NEW_QUESTION_WITH_AUTO_ADVANCE
            )}
          />
        );
      }
      case BannerType.CURRENT_CLOSING_SOON: {
        return (
          <div className="text-center">
            <span className="text-danger text-center">{`This poll is closing in ${timeRemainingSeconds} seconds`}</span>
          </div>
        );
      }
      case BannerType.UNANSWERED_CLOSING_SOON: {
        return (
          <div className="text-center">
            {closingUnAnsweredQuestions.map((q, i) => {
              return (
                <AlertBanner
                  alertAction={() => {
                    setShowBanner(null);
                    props.events.goToQuestion(q.id);
                  }}
                  alertText={`${truncate(q.title, {
                    length: maxTitleLengthForBanner,
                  })} is closing soon - click here to navigate.`}
                  key={i}
                />
              );
            })}
          </div>
        );
      }
    }
  };

  return (
    <div className={`${baseClass} bg-white text-wrap flex-grow-1`}>
      <h1 className="d-none">Live event page</h1>
      {!props.setData.hasLiveVideo && (
        <div className="position-absolute overflow-hidden">
          <div className={`${baseClass}-background-number z1`}>{questionNumber}</div>
        </div>
      )}

      {/* position relative is required so the text is on top of the background number */}
      <div className="position-relative z3">
        {showBanner && renderAlert()}
        <div className={`${baseClass}-container`}>
          <div className="mx-3 py-3">
            <div
              className={`${baseClass}-title-container mt-5 ${
                !props.setData.hasLiveVideo ? 'mod-with-gap' : ''
              }`}
            >
              <div className={`${baseClass}-title font-weight-bold`}>
                <TextWithLineBreak text={props.title} />
              </div>
            </div>
            {props.votingInteraction === VotingInteraction.VIEWING_COMMENTS && (
              <div className="w-100 mt-4">
                <Btn
                  action={props.events.cancelInteraction}
                  type={ButtonTypes.SECONDARY}
                >
                  {!isClosedQuestion && !props.typedData.previousVote
                    ? 'Answer Poll'
                    : 'View Poll'}
                </Btn>
              </div>
            )}
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <VotingInteractionInner
                {...props}
                isClosedQuestion={isClosedQuestion}
                setModalType={setModalType}
                submitting={submitting}
              />
            </div>
          </div>
          <LiveActionButtons
            allowSubmit={checkIfInProcessVoteIsValid(
              props.typedData,
              props.inProcessComment
            )}
            closed={isClosedQuestion}
            error={error}
            events={{
              submit: async () => {
                setSubmitting(true);
                return await handleSubmit();
              },
              nextQuestion: handleNextQuestion,
              doneAnsweringQuestions: props.events.goToFeed,
              followPublisher: props.events.followPublisher,
              goToOverview: handleGoToOverView,
              setInteraction: props.events.setInteraction,
            }}
            fromOverview={
              props.redirectedFrom === QuestionPageRedirectLocation.OVERVIEW
            }
            hideBtns={props.votingInteraction === VotingInteraction.COMMENTING}
            // This intentionally differs from Poll Sets in that guests can comment
            liveEventEnded={props.status === RespondentsSetStatus.CLOSED}
            setIdOrSlug={props.questionSetSlug ?? props.questionSetId}
            showAddComment={
              voted &&
              !isClosedQuestion &&
              !commented &&
              props.votingInteraction !== VotingInteraction.COMMENTING
            }
            voted={voted}
          />
        </div>
        {modalType && (
          <VotingModals
            details={props.details}
            events={{
              cancel: () => {
                setModalType(null);
                setSubmitting(false);
              },
              onSuccessfulSignup: async () => {
                await props.events.submitVote();
                await props.events.followPublisher();
              },
              onSuccessfulGuestConversion: async () => {
                await props.events.followPublisher();
              },
              promptRegistration: () => {
                props.events.promptRegistration(props.id);
              },
              facebook: {
                shareQuestion: () => {
                  props.events.facebook.shareQuestion(props.id, props.currentUserId);
                },
              },
              twitter: {
                shareQuestion: () => {
                  props.events.twitter.shareQuestion(props.id, props.currentUserId);
                },
              },
            }}
            modalType={modalType}
            respId={props.respId}
            shareUrl={ClientUrlUtils.respondent.question.url({
              questionId: props.id,
              setIdOrSlug: props.questionSetSlug ?? props.questionSetId,
              pubSlug: props.publishingEntity.slug,
              setType: QuestionSetType.POLCO_LIVE,
            })}
          />
        )}
      </div>
    </div>
  );
};
PolcoLiveLoaded.displayName = 'PolcoLiveLoaded';

const Voting: React.FC<
  VotingProps.QuestionSetLoaded & {
    readonly submitting: boolean;
    readonly setModalType: (type: ModalType) => void;
    readonly isClosedQuestion: boolean;
  }
> = (props) => {
  const showCommentPrompt =
    props.typedData.questionType !== QuestionType.FREE_TEXT &&
    props.comments.loadedQuestion.length;
  const commentButtonText = `${POLCO_LIVE_COPY.view} ${props.comments.loadedQuestion.length} ${POLCO_LIVE_COPY.comments}`;

  return (
    <div className="w-100">
      <div className="d-flex mb-3 align-items-center">
        {showCommentPrompt ? (
          <Btn
            action={() => {
              props.events.setInteraction(VotingInteraction.VIEWING_COMMENTS);
            }}
            ariaLabel={commentButtonText}
            className={`${baseClass}-button-view-comments mr-2`}
            type={ButtonTypes.SEAMLESS}
          >
            {commentButtonText}
          </Btn>
        ) : !props.comments.loadedQuestion.length &&
          props.typedData.questionType !== QuestionType.FREE_TEXT ? (
          <p className="mr-4">{POLCO_LIVE_COPY.noComments}</p>
        ) : null}
        {(props.details.description || props.details.summary) && (
          <Btn
            action={() => props.setModalType(ModalType.MORE_INFO)}
            ariaLabel={POLCO_LIVE_COPY.viewInfo}
            className={`${baseClass}-button-more-info`}
            type={ButtonTypes.SEAMLESS}
          >
            {POLCO_LIVE_COPY.viewInfo}
          </Btn>
        )}
      </div>

      <VotingButtonsOrResults
        allowGuestRespondents={props.allowGuestRespondents}
        choices={props.choices}
        closed={props.isClosedQuestion}
        comments={props.comments}
        events={props.events}
        id={props.id}
        inProcessComment={props.inProcessComment ?? null}
        preLoginAction={props.preLoginAction}
        respId={props.respId}
        submitting={props.submitting}
        title={props.title}
        typedData={props.typedData}
      />
    </div>
  );
};

const VotingInteractionInner: React.FC<
  VotingProps.QuestionSetLoaded & {
    readonly submitting: boolean;
    readonly setModalType: (type: ModalType) => void;
    readonly isClosedQuestion: boolean;
  }
> = (props) => {
  switch (props.votingInteraction) {
    case VotingInteraction.VIEWING_COMMENTS:
      return (
        <Comments
          choices={props.choices}
          closed={props.isClosedQuestion}
          comments={props.comments}
          currentUserId={props.currentUserId}
          events={{
            upvoteComment: props.events.upvoteComment,
            unUpvoteComment: props.events.unUpvoteComment,
            createAccount: () => props.setModalType(ModalType.CONVERT_GUEST),
          }}
          id={props.id}
          isGuest={props.isGuest}
          typedData={props.typedData}
          upvoteCommentsOnVote={props.upvoteCommentsOnVote}
        />
      );
    case VotingInteraction.COMMENTING:
      return (
        <AddComment
          choices={props.choices}
          comment={props.typedData.previousVote?.comment ?? null}
          events={{
            cancel: props.events.cancelInteraction,
            submitComment: props.events.submitComment,
            setInteraction: props.events.setInteraction,
          }}
          previousVote={props.typedData.previousVote}
          questionId={props.id}
        />
      );
    default:
      return <Voting {...props} />;
  }
};

interface VotingModalsProps {
  readonly modalType: ModalType;
  readonly details: VotingProps.QuestionSetLoaded['details'];
  readonly events: {
    readonly cancel: () => void;
    readonly onSuccessfulSignup: () => Promise<void>;
    readonly onSuccessfulGuestConversion: () => Promise<void>;
    readonly promptRegistration: () => void;
    readonly facebook: {
      readonly shareQuestion: () => void;
    };
    readonly twitter: {
      readonly shareQuestion: () => void;
    };
  };
  readonly respId: ClientRespondentId | null;
  readonly shareUrl: string;
}

const VotingModals: React.FC<VotingModalsProps> = (props) => {
  switch (props.modalType) {
    case ModalType.MORE_INFO:
      return (
        <MoreInfo cancel={props.events.cancel} details={props.details} isOpen />
      );
    case ModalType.SHARING:
      return (
        <ShareModal
          events={{
            facebook: {
              share: props.events.facebook.shareQuestion,
            },
            twitter: {
              share: props.events.twitter.shareQuestion,
            },
            cancel: props.events.cancel,
          }}
          isOpen
          url={props.shareUrl}
        />
      );
    case ModalType.CREATE_ACCOUNT_PRE_VOTE:
      return (
        <AuthnFlowSliderModal
          isOpen
          onAuthenticationSuccess={props.events.onSuccessfulSignup}
          onClose={props.events.cancel}
          registrationPrompt={{
            description: POLCO_LIVE_COPY.submitVoting.description,
            image: (
              <div className="w-100 d-flex justify-content-center">
                <img alt="Polco logo" src={polcoLogo} />
              </div>
            ),
            title: POLCO_LIVE_COPY.submitVoting.title,
          }}
        />
      );
    case ModalType.CONVERT_GUEST:
      return (
        <AuthnFlowSliderModal
          isOpen
          onAuthenticationSuccess={props.events.onSuccessfulGuestConversion}
          onClose={props.events.cancel}
          registrationPrompt={{
            description: POLCO_LIVE_COPY.commentOnQuestion.description,
            image: <MaterialIcon icon={MaterialIconName.ADD_COMMENT} />,
            title: POLCO_LIVE_COPY.commentOnQuestion.title,
          }}
        />
      );
  }
};

export const VotingButtonsOrResults: React.FC<
  Pick<
    VotingProps.QuestionSetLoaded,
    | 'typedData'
    | 'inProcessComment'
    | 'choices'
    | 'id'
    | 'preLoginAction'
    | 'events'
    | 'allowGuestRespondents'
    | 'comments'
    | 'respId'
    | 'closed'
    | 'title'
  > & {
    readonly submitting: boolean;
  }
> = React.memo((props) => {
  if (props.closed || props.typedData.previousVote) {
    return (
      <VotingResult
        {...props}
        animateComments={props.closed}
        minCommentsForWordCloud={1}
      />
    );
  } else {
    return (
      <VotingButtons
        allowGuestRespondents={props.allowGuestRespondents}
        choices={props.choices}
        events={{
          cancel: props.events.cancelInteraction,
          updateCommentText: props.events.updateCommentText,
          submit: props.events.submitVote,
          selectMultipleChoice: props.events.selectMultipleChoice,
          selectPointAllocation: props.events.selectPointAllocation,
          startInProcessVote: props.events.startInProcessVote,
        }}
        inProcessComment={props.inProcessComment ?? null}
        questionId={props.id}
        submitting={props.submitting}
        title={props.title}
        typedData={props.typedData}
      />
    );
  }
}, isEqual);
