import * as React from 'react';
import './styles.scss';
import {
  QuestionForWaitingPage,
  QuestionStateForWaitingPage,
} from 'client/respondent/core/types';
import { POLCO_LIVE_WAITING_COPY } from './copy';
import { Btn, ButtonTypes } from 'client/shared/components/base/btn';
import { ClientQuestionId } from 'client/shared/core/question';
import { wrap } from 'core';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
const baseClass = 'pn-polco-live-waiting';
export interface PolcoLiveWaitingProps {
  readonly questions: readonly QuestionForWaitingPage[];
  readonly events: {
    readonly goToQuestion: (questionId: ClientQuestionId) => void;
  };
  readonly hasEmbeddedVideo: boolean;
}

export interface PreviousQuestionProps {
  readonly question: QuestionForWaitingPage;
  readonly questionNumber: number;
  readonly events: {
    readonly goToQuestion: (questionId: ClientQuestionId) => void;
  };
}

export const PolcoLiveWaiting: React.FC<PolcoLiveWaitingProps> = (props) => {
  return (
    <div className={`${baseClass} py-5 bg-white`}>
      {!props.hasEmbeddedVideo && <RespondentPageLoader className="mt-0" />}

      <div className={`${baseClass}-title text-center font-weight-bold mt-3`}>
        {POLCO_LIVE_WAITING_COPY.title(props.questions.length > 0)}
      </div>

      <div className={`${baseClass}-desc text-center py-3`}>
        {POLCO_LIVE_WAITING_COPY.desc(props.questions.length > 0)}
      </div>
      {props.questions.map((q, i) => (
        <PreviousQuestion
          events={props.events}
          key={q.id}
          question={q}
          questionNumber={i + 1}
        />
      ))}
    </div>
  );
};

export const PreviousQuestion: React.FC<PreviousQuestionProps> = (props) => {
  const { question, questionNumber } = props;
  const copy = wrap(() => {
    switch (question.state) {
      case QuestionStateForWaitingPage.ANSWER_NOW:
        return POLCO_LIVE_WAITING_COPY.answerNow;
      case QuestionStateForWaitingPage.CLOSED:
        return POLCO_LIVE_WAITING_COPY.closed;
      case QuestionStateForWaitingPage.OPEN:
        return POLCO_LIVE_WAITING_COPY.open;
    }
  });

  return (
    <div>
      <div className="font-weight-bold row px-3">
        <div aria-atomic={true} aria-live="polite" className="col-8 py-2">
          <span>
            {questionNumber}. {question.title}
          </span>
          {question.state === QuestionStateForWaitingPage.CLOSED && (
            <span className={`${baseClass}-text mod-closed font-weight-normal`}>
              {' '}
              {POLCO_LIVE_WAITING_COPY.closedQuestion}
            </span>
          )}
        </div>
        <div className="col-4 text-right">
          <Btn
            action={() => {
              props.events.goToQuestion(question.id);
            }}
            className="p-0"
            size={'large'}
            type={ButtonTypes.SEAMLESS}
          >
            <span className={`${baseClass}-text mod-cta`}>{copy}</span>
          </Btn>
        </div>
      </div>
    </div>
  );
};
