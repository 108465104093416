import * as React from 'react';
import {
  ZipPrompt,
  ZipSubmitted,
} from 'client/respondent/account/components/zip-prompt';
import {
  ClientRespondentId,
  FeedConverterArgs,
  feedItemConverter,
} from 'client/respondent/core';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import * as RespondentContext from 'client/respondent/core/reducers/context';
import {
  DISMISSED_VERIFICATION_PROMPT,
  NotLoggedInActions,
} from 'client/respondent/core/types';
import { CurrentUser } from 'client/respondent/hooks/use-respondent';
import { VerificationModal } from 'client/respondent/shared/account/containers/verification-modal';
import { UnsubscribeConfirm } from 'client/respondent/shared/components/unsubscribe-confirm-modal';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { useQueryInfo } from 'client/shared/containers/query';
import {
  RespondentFeedVariables,
  RespondentFeed_Item,
  InputVoteType,
} from 'client/shared/graphql-client/graphql-operations.g';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import { useLanguageByQueryParam } from 'client/shared/hooks';
import { ShareModal } from 'client/shared/components/share-modal';

import {
  CreateIdeaCardOnDesktop,
  CreateIdeaCardOnMobile,
  Feed,
  UnfollowConfirm,
} from '../../components';
import { fbShare } from 'client/shared/integrations';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { AuthnFlowSliderModal } from 'client/respondent/shared/account/containers/authn-flow-slider-modal';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { twitterShare } from 'client/shared/integrations/twitter';
import PolcoUserWithApp from 'client/respondent/shared/account/containers/sliders/assets/user-with-app.svg';
import { PRIVACY_URL } from 'client/shared/core/constants';
import { FollowPublisherAccountPrompt } from 'client/respondent/shared/components/follow-publisher-account-prompt';
import { Helmet } from 'react-helmet';
export const copy = {
  title: 'Feed',
  notifyMe: {
    getsNotifiedDescription: (publisherName: string) =>
      `In order to receive a notification when ${publisherName} joins Polco, you’ll need a Polco account.`,
    getsNotified: (publisherName: string) =>
      `Get notified when ${publisherName} joins Polco`,
    informationSlider: {
      title: 'In order to be notified, you must sign up',
      paragraphs: [
        'You’ll be notified when your city joins Polco',
        'No one else can respond on your behalf',
        'You get a tailored feed with local content',
      ],
      privacyLink: 'Privacy',
      privacyContent:
        ' matters. You’ll always remain anonymous unless you tell us otherwise',
      button: 'Enter my details',
    },
  },
  secondaryButtonLabel: 'Account Setup',
};

const notifyMeInformationSliderProps = {
  name: copy.notifyMe.informationSlider.title,
  paragraphs: copy.notifyMe.informationSlider.paragraphs.map((paragraph) => (
    <p className="pl-2">{paragraph}</p>
  )),
  checkmark: true,
  privacy: (isActiveSlide: boolean) => (
    <p className="pl-2">
      <a href={PRIVACY_URL} tabIndex={isActiveSlide ? 0 : -1} target="_blank">
        {copy.notifyMe.informationSlider.privacyLink}
      </a>
      {copy.notifyMe.informationSlider.privacyContent}
    </p>
  ),
  background: PolcoUserWithApp,
  buttons: {
    name: copy.notifyMe.informationSlider.button,
  },
};

export interface PublisherAlert {
  readonly publisher: RespondentFeed_Item['publishingEntity'];
  readonly action: 'FOLLOW' | 'UNFOLLOW';
}
export const FeedPage: React.FC = () => {
  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();

  React.useEffect(() => {
    if (state.lastFeedLocation !== null) {
      dispatch(RespondentActions.setLastFeedLocation(null));
    }
  }, [state.lastFeedLocation, dispatch]);

  return (
    <MainPage>
      {(respondentUser) => {
        return <FeedPageInner respondentUser={respondentUser} />;
      }}
    </MainPage>
  );
};
FeedPage.displayName = 'FeedPage';

const FeedPageInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
}> = (props) => {
  const state = RespondentContext.useRespondentState();
  const dispatch = RespondentContext.useRespondentDispatch();
  const locationFlowActivated = useFlagEnabled(KnownFlag.RESIDENTS_STORE_LOCATION);
  const resp = props.respondentUser?.user?.respondent ?? null;
  const isGuest = !!resp?.guest;
  const respId = resp?.id ?? null;

  const feedVariables: RespondentFeedVariables = resp?.zipCode
    ? {
        respondentId: respId,
        zipCode: resp.zipCode,
      }
    : {
        respondentId: respId,
        zipCode: state.currentLocation?.zip,
        latLng: state.currentLocation?.latLng,
      };
  const skip = !respId && !state.currentLocation;
  const feedResult = useQueryInfo(QueryInfos.respondentFeed, {
    variables: feedVariables,
    skip,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
  const feed = feedResult.data?.feed.items ?? [];

  const [publisherAlert, setPublisherAlert] = React.useState<PublisherAlert | null>(
    null
  );
  const [sharingUrl, setSharingUrl] = React.useState<string | null>(null);
  const [showLoginPrompt, setShowLoginPrompt] = React.useState<boolean>(false);

  const followAction = useMutationInfo(MutationInfos.followPublishingEntity, {
    refetchQueries: [QueryInfos.respondentFeed.refetchInfo(feedVariables)],
  });
  const saveRespZip = useMutationInfo(MutationInfos.updateRespondentProfile);
  const voteAction = useMutationInfo(MutationInfos.voteForQuestion);
  const ideaVoteAction = useMutationInfo(MutationInfos.interactWithIdea);
  const { selectLanguageText } = useLanguageByQueryParam();

  const args: FeedConverterArgs = {
    voteAction,
    respId,
    isGuest,
    setPublisherAlert,
    setShowLoginPrompt,
    setSharingUrl,
    currentLocation: state.currentLocation,
    followAction,
    dispatch,
    selectLanguageText,
    ideaVoteAction,
  };
  const feedItems = feed.map(feedItemConverter(args));

  // default logic is show if the feed has loaded with no items, OR there is a respondent and we don't have a saved zip, OR we don't have a respondent

  const showZipPrompt = React.useMemo(() => {
    return (
      (!feedResult.loading && !feedItems.length) ||
      resp?.guest ||
      !resp ||
      !resp?.zipCode
    );
  }, [resp, feedItems.length, feedResult.loading]);

  const [showZipSaved, setShowZipSaved] = React.useState<boolean>(false);
  const hideZipCode =
    locationFlowActivated && !!resp?.inputtedLat && !!resp?.inputtedLon;

  async function saveZipCode(zipCode: string) {
    if (respId) {
      const res = await saveRespZip.fn({
        refetchQueries: () => [
          QueryInfos.respondentFeed.refetchInfo({
            respondentId: respId,
            zipCode: state.currentLocation?.zip,
            latLng: state.currentLocation?.latLng,
          }),
          QueryInfos.respondentSubscriptions.refetchInfo({
            respondentId: respId,
          }),
          QueryInfos.respondentProfile.refetchInfo({
            respondentId: respId,
          }),
        ],
        variables: {
          respondentId: respId,
          input: {
            zipCode,
          },
        },
      });
      if (res.data) {
        setShowZipSaved(true);
        //wait 5 seconds and set to false
        setTimeout(() => setShowZipSaved(false), 5000);
      }
    }
  }

  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);
  const localStorage = useLocalStorage();
  const localStorageDismissedVerification =
    localStorage?.getItem(DISMISSED_VERIFICATION_PROMPT) === 'true';

  React.useEffect(() => {
    if (
      resp &&
      resp.requiresVerificationPrompt &&
      !localStorageDismissedVerification
    ) {
      setShowVerifyModal(true);
    }
  }, [localStorageDismissedVerification, resp]);

  const isFollowAction =
    state.preLoginAction?.actionType === NotLoggedInActions.FOLLOW;
  const isQuestionVoteAction =
    state.preLoginAction?.actionType === NotLoggedInActions.QUESTION_VOTE;
  const publisherName =
    isFollowAction || isQuestionVoteAction
      ? selectLanguageText(state.preLoginAction.data.publisher.name)
      : '';

  const onAuthenticationSuccess = async (authArgs: {
    readonly respondentId: ClientRespondentId;
  }) => {
    if (isFollowAction) {
      await followAction.fn({
        variables: {
          publishingEntityId: state.preLoginAction.data.publisher.id,
          follow: true,
          respondentId: authArgs.respondentId,
        },
        refetchQueries: [
          QueryInfos.respondentSubscriptions.refetchInfo({
            respondentId: authArgs.respondentId,
          }),
        ],
      });
    } else if (isQuestionVoteAction) {
      await voteAction.fn({
        variables: {
          questionId: state.preLoginAction.data.questionId,
          upvoteComments: [],
          voteInput: {
            type: InputVoteType.VOTE_ACKNOWLEDGE,
          },
          voteMetadata: state.questionMetadata ?? [],
        },
        refetchQueries: [
          QueryInfos.respondentFeed.refetchInfo({
            respondentId: respId,
            zipCode: resp?.zipCode,
            latLng: state.currentLocation?.latLng,
          }),
        ],
      });
    }
    dispatch(RespondentActions.completePreLoginAction());
  };

  const onAuthSliderClose = () => {
    dispatch(RespondentActions.completePreLoginAction());
    setShowLoginPrompt(false);
  };

  const closeVerificationModal = () => {
    if (localStorage) {
      localStorage.setItem(DISMISSED_VERIFICATION_PROMPT, String(true));
    }
    setShowVerifyModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Polco - Home</title>
      </Helmet>
      <h1 className="d-none">{copy.title}</h1>
      <div>
        <UnsubscribeConfirm />
        {showVerifyModal && (
          <VerificationModal
            action={closeVerificationModal}
            isFeed
            onClose={closeVerificationModal}
          />
        )}
        {publisherAlert?.action === 'UNFOLLOW' && (
          <UnfollowConfirm
            events={{
              cancel: () => setPublisherAlert(null),
              unfollow: async () => {
                if (!respId) {
                  setShowLoginPrompt(true);
                  return;
                }
                const result = await followAction.fn({
                  variables: {
                    publishingEntityId: publisherAlert.publisher.id,
                    follow: false,
                    respondentId: respId,
                  },
                });
                if (result.data && !result.errors) {
                  setPublisherAlert(null);
                }
              },
            }}
            isOpen={!!publisherAlert}
            publisherName={selectLanguageText(publisherAlert.publisher.name)}
          />
        )}
        {showLoginPrompt && isFollowAction && (
          <FollowPublisherAccountPrompt
            onAuthenticationSuccess={onAuthenticationSuccess}
            onClose={onAuthSliderClose}
            publisherName={publisherName}
          />
        )}
        {showLoginPrompt && isQuestionVoteAction && (
          <AuthnFlowSliderModal
            informationPrompt={notifyMeInformationSliderProps}
            isOpen={showLoginPrompt}
            onAuthenticationSuccess={onAuthenticationSuccess}
            onClose={onAuthSliderClose}
            registrationPrompt={{
              description: copy.notifyMe.getsNotifiedDescription(publisherName),
              image: <MaterialIcon icon={MaterialIconName.NOTIFICATIONS_ACTIVE} />,
              title: copy.notifyMe.getsNotified(publisherName),
              secondaryButtonLabel: copy.secondaryButtonLabel,
              switchButtons: true,
            }}
          />
        )}
        {hideZipCode ||
          (showZipPrompt && (
            <ZipPrompt
              guest={isGuest}
              location={state.currentLocation}
              saveZipCode={async (zipCode: string) => {
                await saveZipCode(zipCode);
              }}
              setLocation={(location, source) =>
                dispatch(RespondentActions.setCurrentLocation(location, source))
              }
            />
          ))}
        {showZipSaved && <ZipSubmitted />}
        <CreateIdeaCardOnDesktop currentResidentId={respId} guest={isGuest} />
        <ShareModal
          events={{
            facebook: {
              share: () => fbShare(sharingUrl ?? ''),
            },
            twitter: {
              share: () => twitterShare(sharingUrl ?? ''),
            },
            cancel: () => setSharingUrl(null),
          }}
          isOpen={!!sharingUrl}
          url={sharingUrl ?? ''}
        />
        <Feed items={feedItems ?? []} loading={feedResult.loading} />
      </div>
      <CreateIdeaCardOnMobile currentResidentId={respId} guest={isGuest} />
    </>
  );
};

FeedPageInner.displayName = 'FeedPageInner';
