import * as React from 'react';
import './styles.scss';
import {
  ButtonTypes,
  MaterialIconName,
  OptionListMenu,
  OptionListMenuOptionProps,
} from 'client/shared/components/base';
import {
  PrimaryActionWrapper,
  LinkAction,
  BannerAction,
  ActionButton,
} from '../common';
import { RespondentFeedItemActionType } from 'client/shared/graphql-client/graphql-operations.g';

export interface FeedCardHeaderProps {
  readonly authorName: string;
  readonly authorLogo: string;
  readonly authorSubheader?: string;
  readonly secondaryLogo?: string;
  readonly authorAction?: LinkAction;
  readonly topAction?: BannerAction;
  readonly dotActions?: readonly OptionListMenuOptionProps[];
  readonly pill?: string;
}

const baseClass = 'pn-feed-card-header';

const getActionButtonLabel = (
  action: BannerAction | undefined,
  publisherName: string
) => {
  switch (action?.key) {
    case RespondentFeedItemActionType.TOGGLE_FOLLOW:
      return action?.content?.type === 'LABEL' && action?.content?.active
        ? `Unfollow ${publisherName}`
        : `Follow ${publisherName}`;
  }
};

export const FeedCardHeader = (p: FeedCardHeaderProps) => {
  const logo = (
    <>
      <img
        alt={`${p.authorName} Profile`}
        className={`${baseClass}-logo`}
        height="30px"
        src={p.authorLogo}
        width="30px"
      />
      {p.secondaryLogo && (
        <img
          alt="Secondary Logo"
          className={`${baseClass}-logo`}
          height="30px"
          src={p.secondaryLogo}
          width="30px"
        />
      )}
    </>
  );

  const author = (
    <div
      className={`${baseClass}-pub-title font-size-sm font-weight-normal d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start`}
    >
      <div className="text-brand-xd">{p.authorName}</div>
      {p.authorSubheader && (
        <div className={`${baseClass}-pub-subtitle d-flex`}>
          <div className="d-none d-sm-block">|</div>
          <div className="text-brand-xd">{p.authorSubheader}</div>
        </div>
      )}
    </div>
  );

  const hasDotOptions = p.dotActions?.length;

  return (
    <div
      className={`d-flex py-3 pn-feed-card-padding ${
        !hasDotOptions ? 'wide' : ''
      } bg-white align-items-center`}
    >
      {logo}
      {p.authorAction ? (
        <PrimaryActionWrapper
          action={p.authorAction}
          className="text-left text-brand-xd ml-1 flex-grow-1"
        >
          {author}
        </PrimaryActionWrapper>
      ) : (
        <div className="text-left text-brand-xd ml-1 flex-grow-1">{author}</div>
      )}
      <div className="d-flex">
        {p.topAction && !hasDotOptions ? (
          <ActionButton
            act={p.topAction}
            ariaLabel={getActionButtonLabel(p.topAction, p.authorName)}
            btnType={ButtonTypes.SECONDARY}
          />
        ) : null}
        {!!hasDotOptions && (
          <OptionListMenu
            className={`${baseClass}-option-list-container`}
            options={p.dotActions}
            toggle={{
              icon: MaterialIconName.MORE_VERT,
              className: `${baseClass}-action-toggle rounded`,
            }}
          />
        )}
      </div>
    </div>
  );
};
