import { BannerType } from '.';

export const POLCO_LIVE_COPY = {
  viewInfo: 'View poll info',
  comments: 'Comments',
  view: 'View',
  registrationSuccessful: 'Thanks for registering!',
  noComments: 'No Comments',
  submitVoting: {
    title: 'We’re almost ready to submit!',
    description:
      'The poster of this Live Event requires you to have an account in order to respond. Once you join Polco, your response will be submitted.',
  },
  commentOnQuestion: {
    title: 'Join the conversation on Polco',
    description: 'Once you join Polco, you can comment on this poll.',
  },
  alertText: (
    type: Exclude<
      BannerType,
      BannerType.CURRENT_CLOSING_SOON | BannerType.UNANSWERED_CLOSING_SOON
      // exclude because we need string template
    >
  ) => {
    switch (type) {
      case BannerType.VIEW_COMMENTS:
        return 'Make an informed decision - click here to read the most upvoted comments.';
      case BannerType.COMMENT:
        return 'Make your voice heard - click here to add a comment.';
      case BannerType.SHARE:
        return 'Help spread the word - click here to share.';
      case BannerType.NEW_QUESTION:
        return 'New question is available - click here to see it.';
      case BannerType.NEW_QUESTION_WITH_AUTO_ADVANCE:
        return 'New question is available - you will be auto-advanced in 5 seconds.';
      case BannerType.CLOSED:
        return 'This poll is closed';
    }
  },
};
