import * as React from 'react';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { useQueryInfo } from 'client/shared/containers/query';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { useLanguageByQueryParam, useRedirect } from 'client/shared/hooks';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { CurrentUser } from 'client/respondent/hooks';
import { LoaderBars } from 'client/shared/components/loader-bars';
import { QuestionSetType } from 'core';
import {
  ContentOutcomeState,
  QuestionSetStatus,
} from 'client/shared/graphql-client/graphql-operations.g';
import { Outcome } from '../../components/outcome';
import { gqlToSetType } from 'client/shared/core/question-set';
interface OutcomeRouteSlugs {
  readonly pubSlug: string;
  readonly setSlug: string;
}

export type Props = RouteComponentProps<OutcomeRouteSlugs>;

function questionSetSlugFromParamsProps(props: Props): string {
  return props.match.params.setSlug;
}

function pubSlugFromParamsProps(props: Props): string {
  return props.match.params.pubSlug;
}

export const SetOutcomePage: React.FC<Props> = (p) => {
  const redirect = useRedirect();
  const setSlug = questionSetSlugFromParamsProps(p);
  const pubSlug = pubSlugFromParamsProps(p);

  const goToFeed = () =>
    redirect(ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });
  const goToPublishingEntity = () => {
    redirect(
      ClientUrlUtils.respondent.pubProfile.path({
        slug: pubSlug,
      }),
      {
        push: true,
      }
    );
  };
  const exit = () =>
    redirect(ClientUrlUtils.respondent.feed.path(), { push: false });

  const goToOverview = () => {
    redirect(
      ClientUrlUtils.respondent.setOverview.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
      }),
      {
        push: true,
      }
    );
  };

  const goToSurvey = () => {
    redirect(
      ClientUrlUtils.respondent.set.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
        setType: QuestionSetType.SURVEY,
      }),
      {
        push: true,
      }
    );
  };

  return (
    <MainPage hideTopNav={true}>
      {(respondentUser) => {
        return (
          <SetOutcomePageInner
            events={{
              goToFeed,
              goToPublishingEntity,
              exit,
              goToOverview,
              goToSurvey,
            }}
            pubSlug={pubSlug ?? 'by-id'}
            respondentUser={respondentUser} // not actually used, but here for clarity
            setSlug={setSlug}
          />
        );
      }}
    </MainPage>
  );
};

const SetOutcomePageInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
  readonly pubSlug: string;
  readonly setSlug: string;
  readonly events: {
    readonly goToFeed: () => void;
    readonly goToPublishingEntity: () => void;
    readonly exit: () => void;
    readonly goToOverview: () => void;
    readonly goToSurvey: () => void;
  };
}> = (p) => {
  const outcomeQuery = useQueryInfo(QueryInfos.respondentOutcomePage, {
    variables: {
      questionSetSlug: p.setSlug,
      publishingEntitySlug: p.pubSlug,
    },
  });
  const { selectLanguageText } = useLanguageByQueryParam();
  if (outcomeQuery.loading) {
    return <LoaderBars />;
  }
  if (!outcomeQuery.data?.openContentSetBySlug?.__typename) {
    return <Redirect to={'/res/not-found'} />;
  }

  const openContentSetBySlug = outcomeQuery.data.openContentSetBySlug;
  const publishingEntity = openContentSetBySlug.publishingEntity;
  const setTitle = selectLanguageText(openContentSetBySlug.name);
  const setDesc = selectLanguageText(openContentSetBySlug.description) ?? null;
  const totalResponses = openContentSetBySlug.totalRespondents;
  const outcome = openContentSetBySlug.outcome;
  const isShareable = openContentSetBySlug.shareable;
  const isClosedOrArchived =
    outcomeQuery.data?.openContentSetBySlug.schedule.status ===
      QuestionSetStatus.CLOSED ||
    outcomeQuery.data?.openContentSetBySlug.schedule.status ===
      QuestionSetStatus.ARCHIVED;
  const hasReportTab =
    openContentSetBySlug.__typename === 'Survey' &&
    (openContentSetBySlug.reportTabs ?? []).length > 0;

  if (
    !outcome ||
    !isClosedOrArchived ||
    outcome.state !== ContentOutcomeState.PUBLISHED
  ) {
    return <Redirect to={'/res/not-found'} />;
  }

  return (
    <Outcome
      assets={publishingEntity.assets}
      events={{
        exit: p.events.exit,
        goToFeed: p.events.goToFeed,
        goToPublishingEntity: p.events.goToPublishingEntity,
        goToOverview: p.events.goToOverview,
        goToSurvey: p.events.goToSurvey,
      }}
      hasReportTab={hasReportTab}
      outcome={outcome.text}
      pubName={selectLanguageText(publishingEntity.name)}
      responseDate={null}
      setDesc={setDesc}
      setTitle={setTitle}
      setType={gqlToSetType(openContentSetBySlug.__typename)}
      shareable={isShareable}
      totalResponses={totalResponses ?? 0}
    />
  );
};
