import './styles.scss';
import { errorLogger } from 'client/shared/core/error-handler';
import { usePublisherSubscriptionCanPublishData } from 'client/respondent/hooks';
import { RespondentPageLoader } from 'client/respondent/shared/components/page-loader';
import { Option, Selector } from 'client/shared/components/selector';
import * as React from 'react';

export interface PublishingEntitySelectorProps {
  readonly onSelect: (selectedPublishingEntityId?: string) => void;
  readonly respondentId: string;
}

const baseClass = 'pn-residents-input-publishing-entity-selector';

export const PublishingEntitySelector: React.FC<PublishingEntitySelectorProps> = (
  props
) => {
  const { subscriptions, query, hasSubscriptions } = usePublisherSubscriptionCanPublishData(
    props.respondentId,
    { useCache: true }
  );

  if (query.loading) {
    return <RespondentPageLoader />;
  }

  const publishingEntities = subscriptions.map((entity) => {
    return { id: entity.id, label: entity.name };
  });

  if (!hasSubscriptions) {
    errorLogger.log(`Trying to access publishing entity selector without valid subscriptions. RespondentId: ${props.respondentId}.`);
    return null;
  }

  return (
    <div className={baseClass}>
      <Selector
      onSelect={(options: readonly Option[]) => {
        const [firstOption] = options;
        props.onSelect(firstOption?.id);
      }}
      options={publishingEntities}
      selectSingleOptionByDefault
    />
    </div>
  );
};

PublishingEntitySelector.displayName = PublishingEntitySelector.name;


