import * as React from 'react';
import './styles.scss';
import { Form, Col } from 'react-bootstrap';
import { useForm, RegisterOptions } from 'react-hook-form';
import { TextInputProps } from 'client/shared/components/base/text-input';
import {
  Btn,
  ButtonTypes,
  Well,
  WellType,
  SeparatorWithText,
  AppLink,
} from 'client/shared/components/base';
import { handlePressEnter, ClientUrlUtils } from 'client/shared/core/helpers';
import { EMAIL_VALIDATION_REGEX } from 'core';
import {
  RequestResetPasswordEvents,
  RegistrationErrors,
  SocialLoginType,
} from 'client/respondent/core/types';
import { RBRef } from 'client/shared/core/types';
import { SocialSignupButton } from 'client/respondent/shared/account/components/social';
import { googleLogin, fbLogin } from '../../containers/social';
import { ACCESS_COPY } from './copy';
import { useId } from 'react-use-id-hook';
import { CurrentRespondent, useCurrentRespondent } from 'client/respondent/hooks';

export namespace RequestPasswordReset {
  export interface Props {
    readonly events: RequestResetPasswordEvents;
    readonly registrationErrors: RegistrationErrors;
  }

  export interface Fields {
    readonly email: string;
  }

  type ValidationSet = Record<keyof Fields, RegisterOptions>;
  export const validations: ValidationSet = {
    email: {
      required: {
        value: true,
        message: ACCESS_COPY.errorMessages.email.required,
      },
      pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: ACCESS_COPY.errorMessages.email.pattern,
      },
    },
  };
}

export const RequestPasswordResetForm: React.FC<RequestPasswordReset.Props> = (
  props
) => {
  const { register, handleSubmit, errors, clearErrors, formState } =
    useForm<RequestPasswordReset.Fields>({
      mode: 'onChange',
    });
  const [showNotice, setNotice] = React.useState(false);

  function getRespondentUser(currentRespondent: CurrentRespondent) {
    if (CurrentRespondent.RESPONDENT.isA(currentRespondent)) {
      return currentRespondent.user;
    }

    if (CurrentRespondent.GUEST_RESPONDENT.isA(currentRespondent)) {
      return currentRespondent.user;
    }

    return null;
  }
  const loggedIn = getRespondentUser(useCurrentRespondent());

  const submitted = formState.isSubmitted;
  const createRef = (opts: RegisterOptions): RBRef => {
    return register(opts) as RBRef;
  };
  const text = (
    label: string,
    k: keyof RequestPasswordReset.Fields,
    id: string,
    feedbackId: string,
    opts?: {
      readonly inputType?: TextInputProps['inputType'];
      readonly autoComplete?: string;
      readonly placeholder?: string;
      readonly required?: boolean;
    }
  ) => {
    const isInvalid = !!errors[k]?.message;
    return (
      <Form.Group as={Col}>
        <Form.Label className="font-size-sm" htmlFor={id}>
          {label}
        </Form.Label>
        <Form.Control
          aria-errormessage={isInvalid ? feedbackId : undefined}
          aria-invalid={isInvalid}
          aria-label={label}
          autoComplete={opts?.autoComplete}
          className={`rounded accessible-input ${
            !!errors[k]?.message ? 'has-error' : ''
          }`}
          id={id}
          isInvalid={isInvalid}
          name={k}
          placeholder={opts?.placeholder}
          ref={createRef(RequestPasswordReset.validations[k] ?? {})}
          required={opts?.required}
          type={opts?.inputType}
        />
        <Form.Control.Feedback
          className={submitted ? 'd-block' : ''}
          id={feedbackId}
          type="invalid"
        >
          {errors[k]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const submit = async (fields: RequestPasswordReset.Fields) => {
    clearErrors();
    await props.events.requestReset(fields);
    setNotice(true);
  };
  return (
    <Form
      className="pn-registration-request-password-reset p-4 ml-auto mr-auto"
      onKeyUp={handlePressEnter(handleSubmit(submit))}
      onSubmit={handleSubmit(submit)}
    >
      <h2 className="font-weight-bold font-size-lg mb-3">
        {ACCESS_COPY.resetPassword}
      </h2>
      <Form.Row>
        {text(ACCESS_COPY.labels.email, 'email', useId(), useId(), {
          autoComplete: 'email',
          required: true,
        })}
      </Form.Row>
      <Btn
        action={handleSubmit(submit)}
        className="w-100"
        type={ButtonTypes.PRIMARY}
      >
        {ACCESS_COPY.actions.requestPasswordReset}
      </Btn>
      {showNotice && (
        <Well type={WellType.INFO}>{ACCESS_COPY.requestPasswordReset.notice}</Well>
      )}
      {!loggedIn && (
        <div className="font-size-sm mt-4 pt-4 pb-1">
          <SeparatorWithText text={ACCESS_COPY.requestPasswordReset.trySocial} />
          <SocialSignupButton
            action={() => googleLogin(props.events.socialLogin)}
            cta={ACCESS_COPY.actions.googleSignin}
            disabled={props.registrationErrors.GOOGLE !== null}
            loginType={SocialLoginType.GOOGLE}
          />
          <SocialSignupButton
            action={() => fbLogin(props.events.socialLogin)}
            cta={ACCESS_COPY.actions.facebookSignin}
            disabled={props.registrationErrors.FACEBOOK !== null}
            loginType={SocialLoginType.FACEBOOK}
          />
        </div>
      )}
      {!loggedIn && (
        <div className="mt-3">
          <AppLink
            className="font-size-sm text-jungle"
            to={ClientUrlUtils.respondent.login.path()}
          >
            ⏎ Back to Login
          </AppLink>
        </div>
      )}
    </Form>
  );
};
